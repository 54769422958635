import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../../shared/_services";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Spinner from "../../spinner-loader/spinner-loader.js";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Constants } from "../../../shared/_helpers";
import LabTabs from "../coursesandappointments/tab/tab";
import "globalthis/auto";
import "core-js/actual";
import "./view-user.css";

const ViewUser = () => {
  const [profilephoto, setProfilephoto] = useState();
  const [user, setUser] = useState({});
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);

  const style = {
    fontSize: 14,
    fontWeight: "700",
  };
  const fontStyle = {
    fontSize: 14,
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    UserService.getUserDetails(userId, enqueueSnackbar).then((response) => {
      setUser(response);
      setProfilephoto(
        response.picture
          ? `${Constants.BASE_URL}/user/${response.picture}`
          : `${"https://i.ibb.co/wynJtDH/avatar.png"}`
      );
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // SEARCH CODES

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            User Profile
          </Typography>
          <Link to="/user">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

        <TableContainer>
          {loading ? (
            <Spinner />
          ) : (
            <Grid container style={{ margin: "20px" }}>
              <Grid xs={12} md={4} lg={4}>
                <Card style={{ textAlign: "center", padding: "20px" }}>
                  <CardMedia
                    component="img"
                    height="100"
                    image={profilephoto}
                    style={{ objectFit: "contain", padding: "10px" }}
                  />

                  <CardContent style={{ paddingBottom: "0px" }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      height={10}
                      component="div"
                      align="center"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        height: "auto",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {toTitleCase(
                        user?.lastName === undefined || user?.lastName === ""
                          ? user.firstName + ""
                          : user.firstName + " " + user.lastName
                      )}
                    </Typography>

                    {user?.email || "N/A"}
                  </CardContent>
                </Card>
              </Grid>
              <Grid lg={1}></Grid>
              <Grid xs={12} md={7} lg={7}>
                <Card>
                  <CardContent style={{ paddingBottom: "0px" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      height={10}
                      component="div"
                      align="center"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        height: "auto",
                        fontWeight: "bold",
                      }}
                    ></Typography>
                    <Table ria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Phone No.
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user.mobile?.e164Number === undefined ||
                            user.mobile?.e164Number === " "
                              ? "N/A"
                              : user.mobile?.e164Number}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            DOB
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {moment(user.dateOfBirth).format("MM-DD-YYYY")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Status
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                flexWrap: "wrap",
                                "& > :not(style)": {
                                  width: 60,
                                  height: 20,
                                },
                              }}
                            >
                              <div>
                                {user.status === 1 ? (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "green",
                                      padding: "5px 8px",

                                      borderRadius: "3px",
                                    }}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "red",
                                      padding: "3px",
                                      width: "80px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Inactive
                                  </span>
                                )}
                              </div>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Paper>
      {/* Courses and Appointments Table */}
      <LabTabs />
    </Box>
  );
};
export default ViewUser;
