import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { UserService } from "../../../shared/_services";
import { confirm } from "react-confirm-box";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Spinner from "../../spinner-loader/spinner-loader";
import Grid from "@mui/material/Grid";
import UploadButton from "./uploadbutton";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./edit-banner.css";
import { Constants } from "../../../shared/_helpers";
import "globalthis/auto";

const initialValues = {
  name: "",
  picture: "",
};

// const { RangePicker } = DatePicker;
export default function EditBanner() {
  const [value] = useState("1");
  // const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const Navigate = useNavigate();
  const { id } = useParams();

  const actionUrlBanner = `${Constants.BASE_URL}/api/Containers/banner/upload`;

  const { enqueueSnackbar } = useSnackbar();
  // const [fileList, setFileList] = useState([
  //   {
  //     uid: "-1",
  //     name: "",
  //     status: "done",
  //     url: "",
  //     thumbUrl: "",
  //   },
  // ]);
  //Upload Thumbnail
  const uploadThumbnail = async (thumbnail) => {
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);

    const response = await fetch(actionUrlBanner, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }

    return data?.result?.files?.thumbnail[0]?.name; // Assuming your server returns the URL of the uploaded thumbnail
  };
  //validation schema
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to the beginning of the day
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required!")
      .max(20, "Name must not exceed 20 characters!"),
    thumbnail: Yup.mixed().required("Banner is required!"),
    startDate: Yup.date()
      .min(today, "Start date should not be in the past!")
      .required("Start date is required!")
      .test("valid-year", "Invalid year format!", (value) => {
        if (value) {
          const year = new Date(value).getFullYear();
          return /^\d{4}$/.test(year);
        }
        return true;
      }),
    endDate: Yup.date()
      .required("End date is required!")
      .typeError("End date is invalid!")
      .min(Yup.ref("startDate"), "End date must be after start date!")
      .test("valid-year", "Invalid year format!", (value) => {
        if (value) {
          const year = new Date(value).getFullYear();
          return /^\d{4}$/.test(year);
        }
        return true;
      }),
  });

  const banerData = (id) => {
    UserService.getBannerDetails(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        const { name, picture, end_date, start_date } = response;

        initialValues.name = name;

        initialValues.picture = picture;

        const startdate = moment(start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
          "YYYY-MM-DD"
        );
        const enddate = moment(end_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
          "YYYY-MM-DD"
        );

        initialValues.startDate = startdate;

        initialValues.endDate = enddate;

        setThumbnailUrl(
          response.picture
            ? `${Constants.BASE_URL}/banner/${response.picture}`
            : `${"https://i.ibb.co/wynJtDH/avatar.png"}`
        );
        // setFileList([
        //   {
        //     uid: response.id,
        //     thumbUrl: response.picture
        //       ? `${Constants.BASE_URL}/banner/${response.picture}`
        //       : `${"https://i.ibb.co/wynJtDH/avatar.png"}`,
        //     status: "done",
        //     url: "",
        //   },
        // ]);
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
  };

  useEffect(() => {
    // get individula baner data by passing user id
    banerData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  // Banner submit
  const onUpdateSubmit = async (values) => {
    if (isUploading) {
      let variant = "warning";
      enqueueSnackbar("File is uploading. Please wait!", { variant });
      // options = false;
    }

    const result = await confirm("Do you want to update this Banner?", options);
    if (result) {
      let data = {
        name: values.name,
        picture: values.thumbnailUrl,
        startDate: values.startDate,
        endDate: values.endDate,
      };

      // setIsOpenBtn(true);
      UserService.updateBanner(id, data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Banner details updated successfully!", { variant });
          // setIsOpenBtn(false);
          Navigate("/banner");
        }
      });
    }
  };

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Edit Banner
          </Typography>
          <Link to="/banner">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          {loading ? (
            <Spinner />
          ) : (
            <TabPanel value="1">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 830,
                  flexGrow: 1,
                }}
              >
                <Grid
                  sx={{
                    maxWidth: 830,
                    flexGrow: 1,
                  }}
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onUpdateSubmit}
                  >
                    {({ errors, values, touched }) => (
                      <Form>
                        <Box>
                          <Field name="picture">
                            {({ field, form }) => (
                              <>
                                <Grid
                                  container
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "200px",
                                    border: "1px solid gray",
                                    width: "76%",

                                    borderRadius: "4px",
                                  }}
                                >
                                  <Grid
                                    item
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "80%",
                                        justifyContent: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      <img
                                        src={thumbnailUrl}
                                        width="100"
                                        alt="avatar.png"
                                        style={{
                                          borderRadius: "8px",
                                          height: "100px",
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <UploadButton
                                      onChange={(event) => {
                                        // Immediately upload the thumbnail and set the URL
                                        const thumbnail =
                                          event.currentTarget.files[0];
                                        setIsUploading(true);
                                        form.setFieldValue(
                                          "thumbnail",
                                          thumbnail
                                        );

                                        uploadThumbnail(thumbnail)
                                          .then((thumbnailUrl) => {
                                            form.setFieldValue(
                                              "thumbnailUrl",
                                              thumbnailUrl
                                            );
                                            form.setFieldError("thumbnail", "");

                                            setThumbnailUrl(
                                              Constants.BASE_URL +
                                                "/banner/" +
                                                thumbnailUrl
                                            );
                                            setIsUploading(false);
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error uploading thumbnail:",
                                              error
                                            );
                                          });
                                      }}
                                    >
                                      Upload Banner
                                    </UploadButton>
                                  </Grid>
                                </Grid>
                                {form.touched.thumbnail &&
                                form.errors.thumbnail ? (
                                  <div
                                    className="error"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {form.errors.thumbnail}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& > :not(style)": {},
                            width: "80%",
                            // margin: "0 auto",
                          }}
                        >
                          <Field name="name">
                            {({ field }) => (
                              <TextField
                                label="Name"
                                fullWidth
                                size="small"
                                display="flex"
                                {...(field ?? "")}
                                style={{ margin: "20px 35px 20px 0" }}
                                error={
                                  errors.name && touched.name ? true : false
                                }
                                helperText={
                                  errors.name && touched.name
                                    ? `${errors.name}`
                                    : ""
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& > :not(style)": {},
                            width: "80%",
                            // margin: "0 auto",
                          }}
                          style={{ alignSelf: "center" }}
                        >
                          <Field name="startDate">
                            {({ field }) => (
                              <TextField
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  min: moment().format("YYYY-MM-DD"),
                                }}
                                label="Start date"
                                variant="outlined"
                                {...(field ?? "")}
                                size="small"
                                style={{
                                  margin: "20px 20px 20px 0",
                                  width: "50%",
                                }}
                                error={
                                  errors.startDate && touched.startDate
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.startDate && touched.startDate
                                    ? `${errors.startDate}`
                                    : ""
                                }
                              />
                            )}
                          </Field>
                          <Field name="endDate">
                            {({ field }) => (
                              <TextField
                                type="date"
                                label="End date"
                                variant="outlined"
                                inputProps={{
                                  min: moment().format("YYYY-MM-DD"),
                                }}
                                {...(field ?? "")}
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                style={{
                                  margin: "20px 35px 20px 0",
                                  width: "50%",
                                }}
                                error={
                                  errors.endDate && touched.endDate
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.endDate && touched.endDate
                                    ? `${errors.endDate}`
                                    : ""
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& > :not(style)": {},
                          }}
                          style={{ alignSelf: "center" }}
                        ></Box>
                        <Button
                          style={{
                            width: 100,
                            marginLeft: "25px",
                            marginTop: "25px",
                            marginBottom: "25px",
                          }}
                          variant="contained"
                          size="medium"
                          type="submit"
                          className="submit-btn-text"
                        >
                          Submit
                        </Button>
                        <Link to="/banner">
                          <Button
                            style={{
                              width: 100,
                              marginLeft: "25px",
                              marginTop: "25px",
                              marginBottom: "25px",
                            }}
                            variant="outlined"
                            size="medium"
                            type="submit"
                            className="back-btn-text"
                          >
                            cancel
                          </Button>
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Box>
            </TabPanel>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
}
