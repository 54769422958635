import { AuthHeader, Constants } from "../_helpers";
import { AuthenticationService } from ".";

import axios from "axios";

const getAll = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/get-all`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
        start: data.start,
        end: data.end,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getUserDetails = async (userId, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user?.id;

  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/users/${userId}?access_token=${token}`,
    });
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const updateUser = async (userId, data, enqueueSnackbar) => {
  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/users/${userId}`,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profilePic: data.profilePic,
        updatedBy: "admin",
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const blockUser = async (userId, page, rowsPerPage, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/block`,
      {
        userId: userId,
        page: page,
        limit: rowsPerPage,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
  }
};

const unBlockUser = async (userId, page, rowsPerPage, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/unblock`,
      {
        userId: userId,
        page: page,
        limit: rowsPerPage,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getUserCount = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/count?access_token=${token}`,
      {
        userType: "user",
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const addUser = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users`,
      {
        fullName: data.fullName,
        email: data.email,
        mobile: data.mobile,
        companyName: data.companyName,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};
const deleteUser = async (userId, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios({
      method: "delete",
      url: Constants.BASE_URL + `/api/users/${userId}?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//program api
const getAllPrograms = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/programs/get-all`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
      },
      { headers: AuthHeader() }
    );

    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const addVideo = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/programs`,
      {
        title: data.title,
        thumbnail: data.thumbnail,
        video: data.video,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const deleteProgram = async (userId) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios({
      method: "delete",
      url: Constants.BASE_URL + `/api/programs/${userId}?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    // const errorResp = HandleErrorResponse(error);
    // let variant = "error";
    if (error !== "Unauthorized") return error;
  }
};

//therapist api
const getAllTherapist = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/get-all-therapists`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
        start: data.start,
        end: data.end,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getTherapistCount = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/users/count?access_token=${token}`,
      {
        userType: "therapist",
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const updateTherapist = async (userId, data, enqueueSnackbar) => {
  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/users/${userId}`,

      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobile: data.mobile,
        picture: data.picture,
        location: data.location,
        consultation_fee: data.consultation_fee,
        experience: data.experience,
        bio: data.bio,
        // license_type: data.license_type,
        // license_number: data.license_number,
        // RegistrationDate: data.RegistrationDate,
        // language: data.language,
        // services: data.services,
        // education: data.education,
        // professional_membership: data.professional_membership,
        // certification_and_degree: data.certification_and_degree,
        updatedBy: "admin",
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    // const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized")
      enqueueSnackbar("Error while updating therapists detail.", { variant });
    else enqueueSnackbar("Your session has been expired.", { variant });
  }
};

const getTherapistDetails = async (userId, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user?.id;
  let data = { userId: userId, userType: "therapist" };
  try {
    const response = await axios({
      method: "post",
      url: Constants.BASE_URL + `/api/users/get-by-id?access_token=${token}`,
      data,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const deleteTherapist = async (id) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  let data = { userId: id };
  try {
    const response = await axios({
      method: "post",
      url: Constants.BASE_URL + `/api/users/delete?access_token=${token}`,
      data,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    // const errorResp = HandleErrorResponse(error);
    // let variant = "error";
    if (error !== "Unauthorized") return error;
  }
};

//courses api
const getAllCourses = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/courses/get-all`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
        start: data.start,
        end: data.end,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getCoursesCount = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.get(
      Constants.BASE_URL + `/api/courses/count?access_token=${token}`
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const updateCourse = async (id, data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/courses/${id}?access_token=${token}`,
      {
        title: data.title,
        picture: data.picture,
        description: data.description,
        topics: data.topics,
        languageId: data.languageId,
        price: data.price,
        validity: data.validity,
        media: data.media,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getCourseDetails = async (id, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  let data = { courseId: id };
  try {
    const response = await axios.post(
      `${Constants.BASE_URL}/api/courses/get-by-id?access_token=${token}`,
      data
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const deleteCourse = async (id) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "delete",
      url: Constants.BASE_URL + `/api/courses/${id}?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    // const errorResp = HandleErrorResponse(error);
    // let variant = "error";
    if (error !== "Unauthorized") return error;
  }
};

const addCourse = async (data, enqueueSnackbar) => {
  console.log(data);
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/courses?access_token=${token}`,
      {
        title: data.title,
        picture: data.picture,
        description: data.description,
        topics: data.topics,
        languageId: data.languageId,
        price: data.price,
        validity: data.validity,
        media: data.media,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//banners api
const getAllBanners = async (data, enqueueSnackbar) => {
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/banners/get-all`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
        start: data.start,
        end: data.end,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getBannerDetails = async (id, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/banners/${id}?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};
const updateBanner = async (id, data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/banners/${id}?access_token=${token}`,

      {
        name: data.name,
        picture: data.picture,
        start_date: data.startDate,
        end_date: data.endDate,
        updatedBy: "admin",
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const deleteBanner = async (id) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "delete",
      url: Constants.BASE_URL + `/api/banners/${id}?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    // const errorResp = HandleErrorResponse(error);
    // let variant = "error";
    if (error !== "Unauthorized") return error;
  }
};

const addBanner = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/banners?access_token=${token}`,
      {
        name: data.name,
        picture: data.thumbnail,
        start_date: data.startDate,
        end_date: data.endDate,
      },

      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//about us
const updateAbout = async (id, data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/static-contents/${id}?access_token=${token}`,
      {
        description: data.description,
      },

      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getAboutUs = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "get",
      url: `${Constants.BASE_URL}/api/static-contents?access_token=${token}&filter[where][title]=about-us`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//Topics
const getTopics = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/topics?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//get getLanguages
const getLanguages = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/languages?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

//get-All services
const getServices = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/services?access_token=${token}`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};
//get-All appointments

const getAllAppointments = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/appointments/get-all?access_token=${token}`,
      {
        page: data.page,
        limit: data.limit,
        searchQuery: data.search,
        startDate: "",
        endDate: "",
        userId: data.userId,
        userType: "therapist",
        appointmentStatus: data.appointmentStatus,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getUserAppointmentCount = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  const therapistId = user.userId;
  console.log(therapistId);
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/appointments/count?access_token=${token}`,
      {
        appointmentStatus: "upcoming",
        userType: "user",
        therapistId: therapistId,
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getTherapistAppointmentCount = async (enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  const therapistId = user.userId;
  console.log(therapistId);
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/appointments/count?access_token=${token}`,
      {
        appointmentStatus: "upcoming",
        userType: "therapist",
        therapistId: therapistId,
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const cancelAppointment = async ({ eventId, userId }) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL + `/api/appointments/cancel?access_token=${token}`,
      data: {
        appointmentId: eventId,
        userId,
      },
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);

    if (error !== "Unauthorized") return error;
  }
};

const joinAppointment = async (eventId) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL +
        `/api/appointments/get-video-token?access_token=${token}`,
      data: {
        appointmentId: eventId,
      },
      headers: {
        "Cross-Origin-Embedder-Policy": "require-corp",
        "Cross-Origin-Opener-Policy": "same-origin",
      },
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);

    if (error !== "Unauthorized") return error;
  }
};
const rescheduleAppointment = async (values) => {
  // const { id: eventId } = values;
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    // const response = await axios({
    //   method: "patch",
    //   url:
    //     Constants.BASE_URL +
    //     `/api/appointments/${eventId}?access_token=${token}`,
    //   data: rest,
    // });
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL +
        `/api/appointments/reschedule?access_token=${token}`,
      data: values,
    });
    return response.data;
  } catch (err) {
    const error = handleError(err);

    if (error !== "Unauthorized") return error;
  }
};

// Get all enrolled courses

const getCourses = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  const { userId, searchQuery } = data;
  try {
    const response = await axios.post(
      Constants.BASE_URL +
        `/api/course_enrolments/get-all?access_token=${token}`,
      {
        userId,
        searchQuery,
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getSlots = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/appointments/get-slots?access_token=${token}`,
      {
        date: data.date,
        duration: data.duration,
        therapistId: data.therapistId,
      },
      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized")
      enqueueSnackbar(errorResp, {
        variant,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    return error;
  }
};

//Availability API
const addAvailability = async (id, data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  const userId = user.userId;
  console.log(userId);
  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/availabilities?access_token=${token}`,
      {
        ...data,
        therapistId: userId,
        id: id,
      },

      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getAvailability = async (id, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  const therapistId = user.id;
  try {
    const response = await axios.get(
      Constants.BASE_URL + `/api/availabilities?access_token=${token}`,
      {
        where: {
          therapistId: therapistId,
        },
      }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};
//privacy policy

const updatePrivacyPolicy = async (id, data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;

  try {
    const response = await axios.patch(
      Constants.BASE_URL + `/api/static-contents/${id}?access_token=${token}`,
      {
        description: data.description,
      },

      { headers: AuthHeader() }
    );
    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getAlltransactions = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/transactions/get-all?access_token=${token}`,
      data
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};
// Get all events
const getAllEvents = async (data, enqueueSnackbar) => {
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user.id;
  data = { ...data, creatorId: user.userId };
  try {
    const response = await axios.post(
      Constants.BASE_URL + `/api/events/get-all?access_token=${token}`,
      data
    );
    return response.data;
  } catch (err) {
    const error = handleError(err, data);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const getPrivacyPolicy = async (enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL +
        `/api/static-contents?filter[where][title]=privacy-policy`,
    });

    return response.data;
  } catch (err) {
    const error = handleError(err);
    const errorResp = HandleErrorResponse(error);
    let variant = "error";
    if (error !== "Unauthorized") enqueueSnackbar(errorResp, { variant });
    return error;
  }
};

const handleError = (err, data) => {
  if (err.response) {
    if (err.response.data === "Unauthorized") {
      data.handleUnauthorized();
    }
    return err.response.data;
  } else if (err.request) {
    return { error: "Network error" };
  }
  return err;
};

export function HandleErrorResponse(error) {
  if (
    error.error.statusCode === 401 &&
    (error.error.message === "Authorization Required" ||
      error.error.message === "could not find a valid user" ||
      error.error.message === "could not find accessToken")
  ) {
    localStorage.removeItem("currentUser");
    window.location.reload(true);
    return "Your session expired";
  } else if (
    error.error.statusCode === 401 &&
    error.error.message === "login failed"
  ) {
    return "Please enter correct email address or password.";
  } else if (error.error.statusCode === 400 || error.error.statusCode === 404) {
    return error.error.message ? error.error.message : "Something went wrong!";
  } else if (error.error.statusCode >= 500 && error.error.statusCode <= 505) {
    return "Something went wrong!";
  } else if (
    error.error.statusCode === 422 &&
    error?.error?.details?.codes.email[0] === "uniqueness"
  ) {
    return "Email is already registered, please try with a different one!";
  } else {
    AuthenticationService.logout();
    window.location.reload(true);
  }
  return error;
}

export const UserService = {
  getAll,
  getUserDetails,
  getAllEvents,
  getUserCount,
  blockUser,
  unBlockUser,
  deleteUser,
  addUser,
  updateUser,
  getAllPrograms,
  addVideo,
  deleteProgram,
  getAllTherapist,
  deleteTherapist,
  updateTherapist,
  getAllCourses,
  updateCourse,
  getCourseDetails,
  deleteCourse,
  addCourse,
  getAllBanners,
  getBannerDetails,
  deleteBanner,
  updateBanner,
  addBanner,
  updateAbout,
  getAboutUs,
  getTopics,
  getLanguages,
  getAllAppointments,
  cancelAppointment,
  joinAppointment,
  addAvailability,
  getPrivacyPolicy,
  updatePrivacyPolicy,
  getSlots,
  rescheduleAppointment,
  getTherapistDetails,
  getServices,
  getTherapistCount,
  getCoursesCount,
  getUserAppointmentCount,
  getTherapistAppointmentCount,
  getAvailability,
  getCourses,
  getAlltransactions,
};
