import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../../shared/_services";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import TransactionTableHead from "../table-head/transaction-table-head";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (orderBy === "user") {
    a = a.firstName.toLowerCase();
    b = b.firstName.toLowerCase();
    return a.localeCompare(b) * 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function TransactionTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [users] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextButton] = useState(false);

  const [isSearchShown, setIsSearchShown] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  let getAllTransactionData = (search) => {
    let data = {
      page: page + 1,
      limit: 10,
      searchQuery: "",
      startDate: "",
      endDate: "",
    };
    if (search) data = { ...data, searchQuery: search };
    UserService.getAlltransactions(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setTransactions(response.transactions[0].data);
        if (response.transactions[0].metadata.length > 0) {
          setTotal(response.transactions[0].metadata[0].total);
        } else {
          setTotal(0);
        }
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went worng", { variant });
      }
    });
  };
  // useEffect for getting all the transactions
  useEffect(() => {
    getAllTransactionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   fetchData("paginationChange");
  // }, [page, rowsPerPage]);

  // const fetchData = (param) => {
  //   let searchData;
  //   if (param === "cancel") searchData = "";
  //   else searchData = search;
  //   let data = {
  //     page: page + 1,
  //     limit: rowsPerPage,
  //     search: searchData,
  //     previousId: previousId,
  //     nextId: nextId,
  //     startDate: dateRange.from ? dateRange.from : "",
  //     endDate: dateRange.to ? dateRange.to : "",
  //   };
  //   UserService.getAllInvoices(data, enqueueSnackbar).then((response) => {
  //     if (!response.error) {
  //       if (response.invoices[0].data.length > 0) {
  //         setTimeout(() => {
  //         }, 1000);
  //       } else {
  //       }
  //       setUsers(response.invoices[0].data);
  //       if (response.invoices[0]?.metadata.length > 0) {
  //         setTotal(response.invoices[0]?.metadata[0]?.total);
  //       } else {
  //         setTotal(0);
  //       }

  //     } else if (response.error.statusCode === 400) {
  //       let variant = "error";
  //       enqueueSnackbar("Something went worng", { variant });
  //     }
  //   });
  //   if (param !== "paginationChange") {
  //     setPage(0);
  //   }
  // };

  // SEARCH CODES
  const handleSearch = (event) => {
    getAllTransactionData(search);
    event.preventDefault();
  };

  let handleCancelSearch = (event) => {
    setSearch("");
    getAllTransactionData("");
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    // fetchData("cancel");

    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    let getAttribute =
      event.target.getAttribute("data-testid") ||
      event.target.parentElement.getAttribute("data-testid");
    if (getAttribute) {
      if (getAttribute === "KeyboardArrowRightIcon") {
        setPage(newPage);
      } else {
        if (page !== 0) {
          setPage(page - 1);
          getAllTransactionData();
        }
      }
      getAllTransactionData();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (username) => selected.indexOf(username) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Transactions
          </Typography>

          <Typography sx={{ flex: "1 1 5%" }} component="div">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
                float: "right",
                display: "flex",
              }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  width: 250,
                  alignItems: "center",
                }}
              >
                <InputBase
                  value={search}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                  {search.length > 0 && (
                    <IconButton onClick={handleCancelSearch}>
                      <CloseIcon color="primary" />
                    </IconButton>
                  )}
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />

        <TableContainer>
          {/* {loading ? (
            <Spinner />
          ) : ( */}

          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TransactionTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
              key={users._id}
            />
            <TableBody>
              {/* {users && */}
              {stableSort(transactions, getComparator(order, orderBy)).map(
                (transaction, index) => {
                  const isItemSelected = isSelected(transaction.index);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ paddingLeft: "25px" }}
                      >
                        {transaction?.transactionId}
                      </TableCell>
                      <TableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ paddingLeft: "25px" }}
                      >
                        {`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", paddingLeft: "15px" }}>
                          {transaction?.amount}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          {transaction?.type}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          {moment(transaction?.createdAt).format("L")}
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        <div style={{ display: "flex" }}>
                          {moment(transaction?.createdAt).format('LT')}
                        </div>
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
          {/* )} */}
        </TableContainer>
        {nextButton ? (
          ""
        ) : (
          <TablePagination
            className="p"
            component="div"
            rowsPerPageOptions={[2]}
            count={total}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per page"
            page={total <= 0 ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}
