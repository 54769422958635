import React from "react";
import {
  Toolbar,
  Typography,
  Paper,
  Divider,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const AddBankDetails = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required!"),
  });
  const initialValues = {
    name: "",
    city: "",
    branch: "",
    ifscCode: "",
    accountHolderName: "",
    accountType: "",
    accountNumber: "",
    confirmAccountNumber: "",
  };
  return (
    <Box>
      <Paper>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Add Bank Details
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors, values, touched }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
                style={{ alignSelf: "center" }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Bank Name"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="City Name"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Branch Name"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="IFSC Code"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Account Holder Name"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Account Type"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Account Number"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Conform Account Number"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Button
                style={{
                  width: 100,
                  marginLeft: "25px",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
                variant="contained"
                size="medium"
                type="submit"
                className="submit-btn-text"
              >
                Submit
              </Button>
              <Link to="/account-setting">
                <Button
                  style={{
                    width: 100,
                    marginLeft: "25px",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                  variant="outlined"
                  size="medium"
                  type="submit"
                  className="back-btn-text"
                >
                  cancel
                </Button>
              </Link>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default AddBankDetails;
