import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../../shared/_services";
import { confirm } from "react-confirm-box";
import { Formik, Form, Field } from "formik";
// import { isValidNumber } from "libphonenumber-js";
import { message, Upload } from "antd";
import { useSnackbar } from "notistack";
// import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Spinner from "../../spinner-loader/spinner-loader";

import Grid from "@mui/material/Grid";

import ImgCrop from "antd-img-crop";
import { Constants } from "../../../shared/_helpers";

import "react-phone-input-2/lib/style.css";

import "globalthis/auto";
import "./edit-therapist.css";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  profilePic: "",
  experience: "",
  location: "",
  // consultation_fee: "",
  bio: "",
  // license_type: "",
  // license_number: "",
  // RegistrationDate: "",
  // language: [],
  // provide_services: [],
  // education: [],
  // professional_membership: "",
  // certification_and_degree: [],
  // lastActive: "",
};

const EditTherapist = () => {
  const [value] = useState("1");
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(true);
  // const [state, setState] = useState(false);
  // const [valueState, setValueState] = useState(false);
  const Navigate = useNavigate();
  let { id } = useParams();
  // const [allLanguages, setAllLanguages] = useState([]);
  // const [services, setServices] = useState([]);
  const actionUrlUser = `${Constants.BASE_URL}/api/Containers/user/upload`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("First Name is required!")
      .max(20, "First Name must be at most 50 characters"),
    lastName: Yup.string()
      .trim()
      .required("Last Name is required!")
      .max(20, "Last Name must be at most 50 characters"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    experience: Yup.number()
      .required("Experience is required!")
      .min(0, "Experience must be a positive number"),
    location: Yup.string().trim().required("Location is required!"),
    mobile: Yup.string()
      .matches(/^\+\d{1,3}\d{3,14}$/, "Invalid phone number!")
      .required("Phone number is required!"),
    bio: Yup.string()
      .trim()
      .required("Bio is required!")
      .max(500, "Bio must be at most 500 characters"),
    // consultation_fee: Yup.number()
    //   .required("Consultation Fee is required!")
    //   .min(0, "Consultation Fee must be a positive number"),
  });

  useEffect(() => {
    // get individula user data by passing user id

    UserService.getTherapistDetails(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        const {
          firstName,
          lastName,
          mobile,
          picture,
          email,
          experience,
          location,
          bio,
        } = response.user[0];

        initialValues.firstName = firstName;
        initialValues.lastName = lastName;

        initialValues.mobile = mobile?.e164Number || "";
        initialValues.email = email;
        // Cases for the location
        if (location.city && location.country) {
          initialValues.location = `${location.country}, ${location.city}`;
        } else if (location.city && !location.country) {
          initialValues.location = ` , ${location.city}`;
        } else if (!location.city && location.country) {
          initialValues.location = `${location.country}, `;
        } else {
          initialValues.location = ` `;
        }

        initialValues.bio = bio;
        // initialValues.consultation_fee = consultation_fee;
        initialValues.experience = experience;
        initialValues.profilePic = picture;
        setFileList([
          {
            uid: response.id,
            thumbUrl: picture
              ? `${Constants.BASE_URL}/user/${picture}`
              : `${Constants.BASE_URL}/user/${"default.png"}`,
            status: "done",
            url: "",
          },
        ]);
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  // user data update button
  const onUpdateSubmit = async (values) => {
    setIsSubmitting(true);
    console.log(values);
    const result = await confirm(
      "Do you want to update therapist profile?",
      options
    );

    if (result) {
      const {
        firstName,
        lastName,
        mobile,
        email,
        location,
        // consultation_fee,
        experience,
        bio,
        // license_type,
        // license_number,
        // picture,
        // profilePic,
        // languageArray,
        // services,
        // education,
        // professional_membership,
        // certifications,
        // newCertificationAndDegree,
        // // registration_date,
        // // lastActive,
      } = values;

      // const updatedServices = services?.map((service) => ({
      //   _id: service._id,
      //   name: service.name,
      //   status: service.status,
      // }));

      // const formattedEducation = education.split(",")?.map((degree) => ({
      //   degree: degree,
      // }));

      // const formattedProfessionalMembership = professional_membership
      //   .split(",")
      //   ?.map((membership) => ({
      //     membership: membership,
      //   }));

      // const newImagePaths = await uploadImagesAndGetPaths(certifications);

      // const updatedCertifications = certifications?.map((cert) => ({
      //   certificate: cert.certificate,
      // }));
      // form.setFieldValue("certifications", newImagePaths);
      const data = {
        firstName,
        lastName,
        mobile: { e164Number: mobile },
        email,
        location,
        // consultation_fee,
        experience,
        bio,
        // license_type,
        // license_number,
        // language: languageArray,
        // services: updatedServices,
        // education: formattedEducation,
        // professional_membership: formattedProfessionalMembership,
        // certifications: updatedCertifications,
        picture: profilePic === "" ? initialValues.profilePic : profilePic,
      };

      console.log(data);
      // return;

      UserService.updateTherapist(id, data, enqueueSnackbar).then(
        (response) => {
          if (!response.error) {
            let variant = "success";
            enqueueSnackbar("Therapist details updated successfully.", {
              variant,
            });
            Navigate("/therapist");
          }
        }
      );
    }
    setIsSubmitting(false); // Disable the submit button
  };

  // user profile photo change
  const onUserPhotoChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].response) {
      setProfilePic(newFileList[0].response.result.files.photo[0].name);
    }
  };

  //upload file data restrictions
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Edit Therapist
          </Typography>
          <Link to="/therapist">
            <Button className="back-btn-text" variant="outlined">
              Back
            </Button>
          </Link>
        </Toolbar>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          {loading ? (
            <Spinner />
          ) : (
            <TabPanel value="1">
              <Paper
                sx={{
                  p: 2,
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 830,
                  flexGrow: 1,
                }}
                style={{ height: 140 }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={2}></Grid>
                  <Grid item md={4} xs={12} style={{ textAlign: "center" }}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                      ></Upload>
                    </ImgCrop>
                  </Grid>
                  <Grid item md={4} xs={12} style={{ textAlign: "center" }}>
                    <ImgCrop showGrid rotationSlider cropShape={"round"}>
                      <Upload
                        action={actionUrlUser}
                        name={"photo"}
                        maxCount={1}
                        showUploadList={false}
                        onChange={onUserPhotoChange}
                        beforeUpload={beforeUpload}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          className="change-password-bth submit-btn-text"
                        >
                          Upload
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Paper>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onUpdateSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Grid
                    sx={{
                      margin: "auto",
                      maxWidth: 830,
                      flexGrow: 1,
                    }}
                    style={{ height: "auto" }}
                  >
                    <Form
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        "& > :not(style)": {},
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="firstName">
                          {({ field }) => (
                            <TextField
                              label="First Name"
                              fullWidth
                              size="small"
                              display="flex"
                              {...(field ?? "")}
                              style={{
                                marginTop: "20px",
                                marginRight: "10px",
                              }}
                              error={
                                errors.firstName && touched.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.firstName && touched.firstName
                                  ? `${errors.firstName}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                        <Field name="lastName">
                          {({ field }) => (
                            <TextField
                              label="Last Name"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              error={
                                errors.lastName && touched.lastName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.lastName && touched.lastName
                                  ? `${errors.lastName}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="mobile">
                          {({ field }) => (
                            <TextField
                              label="Mobile"
                              size="small"
                              fullWidth
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px" }}
                              error={
                                errors.mobile && touched.mobile ? true : false
                              }
                              helperText={
                                errors.mobile && touched.mobile
                                  ? `${errors.mobile}`
                                  : ""
                              }
                            />
                          )}
                        </Field>

                        <Field name="email">
                          {({ field }) => (
                            <TextField
                              label="Email"
                              size="small"
                              disabled={true}
                              fullWidth
                              type="email"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? `${errors.email}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="experience">
                          {({ field }) => (
                            <TextField
                              label="Experience(years)"
                              fullWidth
                              type="text"
                              size="small"
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px" }}
                              error={
                                errors.experience && touched.experience
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.experience && touched.experience
                                  ? `${errors.experience}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                        <Field name="location">
                          {({ field }) => (
                            <TextField
                              label="Location"
                              disabled="true"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              error={
                                errors.location && touched.location
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.location && touched.location
                                  ? `${errors.location}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>

                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="license_type">
                          {({ field }) => (
                            <TextField
                              label="License Type"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px" }}
                              error={
                                errors.license_type && touched.license_type
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.license_type && touched.license_type
                                  ? `${errors.license_type}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                        <Field name="license_number">
                          {({ field }) => (
                            <TextField
                              label="License Number"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              error={
                                errors.license_number && touched.license_number
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.license_number && touched.license_number
                                  ? `${errors.license_number}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        {/* <Field name="language">
                          {({ field, form }) => (
                            <FormControl
                              sx={{
                                marginTop: "20px",

                                width: "100%",
                              }}
                              size="small"
                              error={
                                form.errors.language && form.touched.language
                              }
                            >
                              <InputLabel id="demo-select-small-label">
                                Language
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // value={field.value || []}
                                multiple
                                {...field}
                                onChange={(event) => {
                                  form.setFieldValue(
                                    "language",
                                    event.target.value
                                  );
                                  const selectedLanguages =
                                    event.target.value.map((languageId) => ({
                                      languageId: languageId,
                                    }));
                                  form.setFieldValue(
                                    "languageArray",
                                    selectedLanguages
                                  );
                                }}
                                // value={field.value || []}
                                value={
                                  Array.isArray(field.value)
                                    ? field.value.map(
                                        (language) => language?._id
                                      )
                                    : []
                                }
                              >
                                {allLanguages &&
                                  allLanguages.map((language) => (
                                    <MenuItem
                                      key={language._id}
                                      value={language._id}
                                    >
                                      {language.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {form.errors.language &&
                                form.touched.language && (
                                  <FormHelperText>
                                    {form.errors.language}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          )}
                        </Field> */}
                        {/* <Field name="professional_membership">
                          {({ field }) => (
                            <TextField
                              label="Professional Membership"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "15px", marginLeft: "20px" }}
                              error={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? `${errors.professional_membership}`
                                  : ""
                              }
                            />
                          )}
                        </Field> */}

                        <Field name="bio">
                          {({ field }) => (
                            <TextField
                              label="Bio"
                              fullWidth
                              size="small"
                              multiline
                              rows={3}
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px" }}
                              error={errors.bio && touched.bio ? true : false}
                              helperText={
                                errors.bio && touched.bio ? `${errors.bio}` : ""
                              }
                            />
                          )}
                        </Field>

                        {/* <Field name="consultation_fee">
                          {({ field }) => (
                            <TextField
                              label="Consultation Fee($)"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                              }}
                              error={
                                errors.consultation_fee &&
                                  touched.consultation_fee
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.consultation_fee &&
                                  touched.consultation_fee
                                  ? `${errors.consultation_fee}`
                                  : ""
                              }
                            />
                          )}
                        </Field> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        {/* <Field name="provide_services">
                          {({ field, form }) => (
                            <FormControl
                              sx={{
                                marginTop: "20px",

                                width: "100%",
                              }}
                              size="small"
                              error={
                                form.errors.provide_services &&
                                form.touched.provide_services
                              }
                            >
                              <InputLabel id="demo-select-small-label">
                                Services
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                multiple
                                {...field}
                                onChange={(event) => {
                                  form.setFieldValue(
                                    "service",
                                    event.target.value
                                  );
                                  const selectedLanguages =
                                    event.target.value.map((serviceId) => ({
                                      serviceId: serviceId,
                                    }));
                                  form.setFieldValue(
                                    "serviceArray",
                                    selectedLanguages
                                  );
                                }}
                                value={
                                  Array.isArray(field.value)
                                    ? field.value.map(
                                        (serviceId) => serviceId?._id
                                      )
                                    : []
                                }
                              >
                                <MenuItem value="">
                                  <em>Select</em>
                                </MenuItem>
                                {services &&
                                  services.map((service) => (
                                    <MenuItem
                                      key={service._id}
                                      value={service._id}
                                    >
                                      {service.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText error>
                                <ErrorMessage name="provide_services" />
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Field> */}

                        {/* <Field name="professional_membership">
                          {({ field }) => (
                            <TextField
                              label="Professional Membership"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "15px", marginLeft: "20px" }}
                              error={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? `${errors.professional_membership}`
                                  : ""
                              }
                            />
                          )}
                        </Field> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        {/* <Field name="professional_membership">
                          {({ field }) => (
                            <TextField
                              label="Professional Membership"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "0px", marginRight: "0px" }}
                              error={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.professional_membership &&
                                touched.professional_membership
                                  ? `${errors.professional_membership}`
                                  : ""
                              }
                            />
                          )}
                        </Field> */}
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="certification_and_degree">
                          {({ field }) => (
                            <TextField
                              label="Certification And Degree"
                              fullWidth
                              size="small"
                              type="text"
                              display="flex"
                              {...field}
                              style={{
                                marginTop: "20px",
                              }}
                              error={
                                errors.certification_and_degree &&
                                touched.certification_and_degree
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.certification_and_degree &&
                                touched.certification_and_degree
                                  ? `${errors.certification_and_degree}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box> */}
                      {/* <Grid item xs={12}>
                        <Box>
                          <h3 style={{ margin: "20px 0" }}>
                            Education Details
                          </h3>
                          <Field name="education">
                            {({ field }) => (
                              <FieldArray name="education">
                                {({ push, remove, form }) => (
                                  <div>
                                    {values.education.map(
                                      (education, index) => (
                                        <div key={index}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              margin: "20px 0",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Field
                                              name={`education[${index}].degree`}
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  label="Degree"
                                                  size="small"
                                                  type="text"
                                                  {...field}
                                                />
                                              )}
                                            </Field>
                                            <Field
                                              name={`education[${index}].college`}
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  label="College"
                                                  fullWidth
                                                  size="small"
                                                  type="text"
                                                  style={{ margin: "0 20px" }}
                                                  {...field}
                                                />
                                              )}
                                            </Field>
                                            <Field
                                              name={`education[${index}].startYear`}
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  label="Start Year"
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  style={{
                                                    margin: "0 20px 0 0",
                                                  }}
                                                  {...field}
                                                />
                                              )}
                                            </Field>
                                            <Field
                                              name={`education[${index}].endYear`}
                                            >
                                              {({ field }) => (
                                                <TextField
                                                  label="End Year"
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  {...field}
                                                />
                                              )}
                                            </Field>
                                          </Box>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            )}
                          </Field>
                        </Box>
                      </Grid> */}
                      {/* <Grid item xs={12}>
                        <Box>
                          <h3 style={{ margin: "20px 0" }}>
                            Certifications Details
                          </h3>
                          <Field name="education">
                            {({ field }) => (
                              <FieldArray name="certification_and_degree">
                                {({ form, push, remove }) => (
                                  <>
                                    {form.values.certification_and_degree.map(
                                      (image, index) => (
                                        <div
                                          key={index}
                                          style={{ display: "flex" }}
                                        >
                                          <img
                                            width={100}
                                            src={`${Constants.BASE_URL}/certificate/${image.certificate}`}
                                            alt={`Certificate ${index + 1}`}
                                          />
                                          <IconButton
                                            onClick={() => remove(index)}
                                            aria-label="delete"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </Field>
                          <Field
                            name="newCertificationAndDegree"
                            type="file"
                            multiple
                          >
                            {({ field, form }) => (
                              <input
                                type="file"
                                multiple
                                onChange={(event) => {
                                  const files = Array.from(event.target.files);
                                  const fileNames = files.map(
                                    (file) => file.name
                                  );
                                  form.setFieldValue(
                                    "newCertificationAndDegree",
                                    files
                                  );
                                  form.setFieldValue(
                                    "newCertificationAndDegreeNames",
                                    fileNames
                                  );
                                  // form.setFieldValue(
                                  //   "certifications",
                                  //   newImagePaths
                                  // );
                                }}
                              />
                            )}
                          </Field>
                        </Box>
                      </Grid> */}
                      <Button
                        style={{
                          width: 100,
                          marginTop: "25px",
                          marginBottom: "25px",
                        }}
                        variant="contained"
                        size="medium"
                        type="submit"
                        disabled={isSubmitting}
                        className="submit-btn-text"
                      >
                        Update
                      </Button>
                    </Form>
                  </Grid>
                )}
              </Formik>
            </TabPanel>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
};
export default EditTherapist;
