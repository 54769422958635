import React from "react";

import menuItem from "../../routes/router";

import { Tooltip } from "@mui/material";

import { NavLink } from "react-router-dom";

import "./sidebar.css";

const Sidebar = ({ isOpen }) => {
  return (
    <div className="sidebarandnavbar">
      <div className="main-content">
        <div className="sidebar" style={{ width: isOpen ? "200px" : "55px" }}>
          {menuItem.map((item, index) => {
            return (
              <NavLink to={item.path} as="Link" key={index} className="link">
                {isOpen === false ? (
                  <Tooltip
                    title={item.name}
                    placement="bottom"
                    className="tool"
                  >
                    <div className="icon" key={index}>
                      {item.icon}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="icon" key={index}>
                    {item.icon}
                  </div>
                )}

                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  {item.name}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
