import React, { useEffect } from "react";
import "./meeting.css";

import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import { useParams, useNavigate } from 'react-router-dom';
// import { useSnackbar } from "notistack";

export default function Meeting() {

  let { data } = useParams();
  const navigate = useNavigate()
  // const { enqueueSnackbar } = useSnackbar();

  
  const sessionJoined = (() => {
    console.log('session joined')
  })
  
  const sessionClosed = (() => {
    console.log('session closed')
    navigate("/appointment")
  })

  const joinMeeting = () => {
    const currentUser = localStorage.getItem("currentUser");
    const user = JSON.parse(currentUser);
    var config = {
      videoSDKJWT: data?.video_jwt_token,
      sessionName: data.session_name,
      userName: `${user.user.firstName} ${user.user.lastName}`,
      sessionPasscode: '',
      features: ['video', 'audio', 'share', 'users', 'settings']
    }
    // let previewContainer = document.getElementById('previewContainer')
    // uitoolkit.openPreview(previewContainer)

    var sessionContainer = document.getElementById('sessionContainer')
    uitoolkit.joinSession(sessionContainer, config)
    

    //event listeners
    uitoolkit.onSessionJoined(sessionJoined)
    uitoolkit.onSessionClosed(sessionClosed)
  } 

  useEffect( () => {
    data = JSON.parse(data)
   
    // if(!data || !data.video_jwt_token) {
    //   enqueueSnackbar("Unable to join appointment", {
    //     variant: "error",
    //   });
    //   navigate("/appointment")
    // }

    joinMeeting()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <><div id='sessionContainer'></div>
  {/* <div id='previewContainer'></div> */}
  </>;
}
