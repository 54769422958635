import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Toolbar, Paper, Typography, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import Spinner from "../../spinner-loader/spinner-loader";
import TabContext from "@mui/lab/TabContext";
import { Link } from "react-router-dom";
import { Constants } from "../../../shared/_helpers";
import { UserService } from "../../../shared/_services";

const ViewCourse = () => {
  const [value] = useState("1");
  const [loading, setLoading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [course, setCourse] = useState();
  const [topics, setTopics] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  //get specific course
  useEffect(() => {
    UserService.getCourseDetails(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        let { picture, topics } = response?.course[0];
        topics = topics.map((topic) => topic.name).join(", ");
        setCourse(response?.course[0]);
        setTopics(topics);
        setThumbnailUrl(`${Constants.BASE_URL}/course/` + picture);
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videos = course?.media?.filter((media) => media.type === "video");
  const videosLength = videos?.length;
  const articles = course?.media?.filter((media) => media.type === "article");
  const articleLength = articles?.length;
  const readings = course?.media?.filter((media) => media.type === "reading");
  const readingsLength = readings?.length;

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2, background: "none", boxShadow: "none" }}>
        <Toolbar sx={{ background: "#fff" }}>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            View Course
          </Typography>
          <Link to="/course">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <TabContext value={value}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Box sx={{ background: "#fff", padding: "20px" }}>
                <Grid container style={{ marginLeft: "20px" }}>
                  <Grid
                    xs={12}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <h3>{course?.title}</h3>
                  </Grid>

                  <Grid item xs={4}>
                    <div
                      style={{
                        width: "100%",
                        height: "250px",
                        borderRadius: "8px",
                        border: "none!important",
                        marginRight: "20px",
                      }}
                    >
                      {thumbnailUrl && (
                        <img
                          style={{
                            width: "90%",
                            height: "100%",
                            borderRadius: "8px",
                            border: "none!important",
                            objectFit: "cover",
                          }}
                          src={thumbnailUrl}
                          alt="img"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid item xs={6}>
                        <h4>Topics Covered</h4>
                        <span>{topics}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <h4>Language</h4>
                        <span>
                          {course?.language?.name
                            ? course?.language?.name
                            : "N/A"}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid item xs={12}>
                        <h4>Description</h4>
                        <p>{course?.description}</p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        <h4>Price ($)</h4>
                        <p>{course?.price}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <h4>Validity (No. of Days)</h4>
                        <p>{course?.validity}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <h4>No. of Users Enrolled</h4>
                        <p>
                          {course?.course_enrolment
                            ? course.course_enrolment
                            : "N/A"}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Toolbar>
                <Typography
                  sx={{ flex: "1 1 20%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                  className="header-text"
                >
                  Material
                </Typography>
              </Toolbar>
              <Box sx={{ background: "#fff", padding: "30px 0 0 0" }}>
                <Grid item xs={12}>
                  <Grid container style={{ marginLeft: "20px" }}>
                    <Grid item xs={3}>
                      <h4>Duration</h4>
                      N/A
                    </Grid>
                    <Grid item xs={3}>
                      <h4>No.of Videos</h4>
                      {videosLength?.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      <h4>No.of Reading Material</h4>
                      {readingsLength?.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      <h4>No. of Articles</h4>
                      <p>
                        {articleLength?.toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                        })}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Box>
                <Grid item xs={12} style={{ marginLeft: "20px" }}>
                  <h3 style={{ color: "#333333" }}>Videos</h3>
                  <Grid container>
                    {videos?.map((video, index) => (
                      <Grid item xs={4}>
                        <div
                          key={index}
                          style={{ padding: "20px 20px 20px 0" }}
                        >
                          <video
                            style={{ width: "100%" }}
                            src={`${Constants.BASE_URL}/program/${video.content}`}
                            controls
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Box>
                <Grid item xs={12} style={{ marginLeft: "20px" }}>
                  <h3 style={{ color: "#333333" }}>Article Link</h3>
                  <Grid container>
                    <Grid item xs={12}>
                      {articles?.map((article, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <a
                            href={article.content}
                            target="_blank"
                            rel="noreferrer"
                            // rel="noopener noreferrer"
                          >
                            {article.content}
                          </a>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Box>
                <Grid item xs={12} style={{ marginLeft: "20px" }}>
                  <h3 style={{ color: "#333333" }}>Reading Material</h3>
                  <Grid container>
                    <Grid item xs={12}>
                      {readings?.map((reading, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <h4 style={{ marginBottom: "10px" }}>
                            {reading.title}
                          </h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: reading.content,
                            }}
                          />
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Box>
              </Box>
            </>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
};

export default ViewCourse;
