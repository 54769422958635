import {
  FaUser,
  FaStethoscope,
  FaChalkboardTeacher,
  FaCalendarAlt,
} from "react-icons/fa";
// import { FcAbout } from "react-icons/fc";
import { BsHouseDoorFill, BsFillHeartPulseFill } from "react-icons/bs";
import { IoSettingsSharp } from "react-icons/io5";
import { MdEventAvailable, MdPrivacyTip } from "react-icons/md";
import { CgDisplayFlex } from "react-icons/cg";

const menuItem = [
  {
    path: "/",
    name: "Dashboard",
    icon: <BsHouseDoorFill />,
  },
  {
    path: "/user",
    name: "Users",
    icon: <FaUser />,
  },
  {
    path: "/therapist",
    name: "Therapists",
    icon: <FaStethoscope />,
  },
  {
    path: "/course",
    name: "Courses",
    icon: <FaChalkboardTeacher />,
  },
  // {
  //   path: "/other",
  //   name: "Other",
  //   icon: <FaChalkboardTeacher />,
  // },
  {
    path: "/health-and-wellness",
    name: "Health & Wellness",
    icon: <BsFillHeartPulseFill />,
  },
  {
    path: "/banner",
    name: "Banners",
    icon: <CgDisplayFlex />,
  },
  {
    path: "/availability",
    name: "Availability",
    icon: <MdEventAvailable />,
  },
  {
    path: "/appointment",
    name: "Appointments",
    icon: <FaCalendarAlt />,
  },
  // {
  //   path: "/event",
  //   name: "Events",
  //   icon: <MdEvent/>,
  // },
  {
    path: "/about-us",
    name: "About Us",
    icon: <FaChalkboardTeacher />,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    icon: <MdPrivacyTip />,
  },
  {
    path: "/transaction",
    name: "Transactions",
    icon: <IoSettingsSharp />,
  },
];
export default menuItem;
