import React, { useState } from "react";
import axios from "axios";
import { UserService } from "../../../shared/_services";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { confirm } from "react-confirm-box";
import { Formik, Form, Field } from "formik";
// import axios from "axios";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";

import { Grid } from "@mui/material";

import UploadButton from "./uploadbutton";
import UploadVideoButton from "./video-upload-btn";

import "globalthis/auto";
import "core-js/actual";
import { Constants } from "../../../shared/_helpers";
import "./add-video.css";

const currentUser = localStorage.getItem("currentUser");
const user = JSON.parse(currentUser);
const token = user?.id;
const actionUrlProgram = `${Constants.BASE_URL}/api/Containers/program/upload?access_token=${token}`;

const initialValues = {
  title: "",
  thumbnail: null,
  video: null,
};

const AddVideo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);

  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  // Submit program
  const onSubmit = async (values, { setSubmitting }) => {
    if (isUploading) {
      let variant = "warning";
      enqueueSnackbar("File is uploading. Please wait!", { variant });

      // options = false;
    }

    const result = await confirm(
      "Do you want to add new Health & Wellness?",
      options
    );

    if (result) {
      let data = {
        title: values.title,
        thumbnail: values.thumbnailUrl,
        video: values.videoUrl,
      };

      UserService.addVideo(data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Health and wellness added successfully!", {
            variant,
          });
          Navigate("/health-and-wellness");
          setSubmitting(false);
        }
      });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required!"),
    thumbnail: Yup.mixed().required("Thumbnail is required!"),
    video: Yup.mixed().required("Video is required!"),
  });

  const uploadThumbnail = async (thumbnail) => {
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);

    const response = await fetch(actionUrlProgram, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }

    return data?.result?.files?.thumbnail[0]?.name; // Assuming your server returns the URL of the uploaded thumbnail
  };

  const uploadVideo = async (video) => {
    try {
      const formData = new FormData();
      formData.append("video", video);

      const response = axios.post(actionUrlProgram, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentage);
          setIsUploading(true);
        },
      });

      const result = await response;
      setIsUploading(false);
      return result?.data?.result?.files?.video[0]?.name; // Assuming your server returns the URL of the uploaded video
    } catch (error) {
      console.error("Error while uploading video", error);
      let variant = "error";
      enqueueSnackbar("Error while uploading video", { variant });
      // Display error message or handle the error as needed
      // You can set an error state and display it in your component
      // setError("Error uploading video. Please try again.");
      throw error; // Rethrow the error if needed for further handling
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Add New
          </Typography>
        </Toolbar>
        {/* upload thumbnail */}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, touched, isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  "& > :not(style)": {},
                  width: "100%",
                  margin: "0 auto",
                }}
                style={{ alignSelf: "center" }}
              >
                <Grid container>
                  <Paper
                    sx={{
                      margin: "auto",
                      maxWidth: "44%",
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                    style={{ height: 180 }}
                  >
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        item
                        md={12}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            width: 180,
                            height: 90,
                            borderRadius: "8px",
                            border: "none!important",
                            marginBottom: "10px",
                            backgroundColor: "lightgray",
                            visibility: thumbnailUrl ? "visible" : "hidden",
                          }}
                        >
                          {thumbnailUrl && (
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "8px",
                                border: "none!important",
                                objectFit: "cover",
                              }}
                              src={thumbnailUrl}
                              alt=""
                            />
                          )}
                        </div>

                        <Field name="thumbnail">
                          {({ field, form }) => (
                            <>
                              <UploadButton
                                onChange={(event) => {
                                  // Immediately upload the thumbnail and set the URL
                                  const thumbnail =
                                    event.currentTarget.files[0];
                                  form.setFieldValue("thumbnail", thumbnail);

                                  uploadThumbnail(thumbnail)
                                    .then((thumbnailUrl) => {
                                      form.setFieldValue(
                                        "thumbnailUrl",
                                        thumbnailUrl
                                      );

                                      form.setFieldError("thumbnail", "");

                                      setThumbnailUrl(
                                        Constants.BASE_URL +
                                          "/program/" +
                                          thumbnailUrl
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Error uploading thumbnail:",
                                        error
                                      );
                                    });
                                }}
                              >
                                Upload Thumbnail
                              </UploadButton>
                              {form.touched.thumbnail &&
                              form.errors.thumbnail ? (
                                <div className="error">
                                  {form.errors.thumbnail}
                                </div>
                              ) : null}
                            </>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Paper
                    sx={{
                      margin: "auto",
                      maxWidth: "44%",
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                    style={{ height: 180 }}
                  >
                    <Grid
                      container
                      style={{
                        width: "80%",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "right",
                        }}
                      >
                        <Field name="video">
                          {({ field, form }) => (
                            <>
                              <div
                                style={{
                                  height: "100px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {progress > 0 && progress < 100 && (
                                  <div> {progress}% Uploaded</div>
                                )}
                                {videoUrl !== "" && (
                                  <a
                                    href={videoUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div
                                      style={{
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <video
                                        width="180"
                                        height="90"
                                        style={{
                                          display: "block",
                                        }}
                                      >
                                        <source
                                          src={videoUrl}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  </a>
                                )}
                              </div>
                              <UploadVideoButton
                                onChange={(event) => {
                                  setProgress(0);
                                  setVideoUrl("");

                                  // Immediately upload the thumbnail and set the URL
                                  const video = event.currentTarget.files[0];
                                  form.setFieldTouched("video", true, false);
                                  form.setFieldValue("video", video);

                                  uploadVideo(video)
                                    .then((videoUrl) => {
                                      form.setFieldValue("videoUrl", videoUrl);
                                      form.setFieldError("video", "");

                                      setProgress(100);
                                      setVideoUrl(
                                        Constants.BASE_URL +
                                          "/program/" +
                                          videoUrl
                                      );
                                    })
                                    .catch((error) => {
                                      // const inputElement = event.currentTarget;
                                      // inputElement.value = null;

                                      form.setFieldValue("video", null);

                                      console.error(
                                        "Error uploading video:",
                                        error
                                      );
                                    });
                                }}
                                onBlur={() => form.setFieldTouched("video")}
                              >
                                Upload Video
                              </UploadVideoButton>

                              <div>
                                {form.touched.video && form.errors.video ? (
                                  <div className="error">
                                    {form.errors.video}
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                }}
                style={{ alignSelf: "center" }}
              >
                <Field name="title" style={{ width: "94%" }}>
                  {({ field }) => (
                    <TextField
                      label="Title"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "30px" }}
                      error={errors.title && touched.title ? true : false}
                      helperText={
                        errors.title && touched.title ? `${errors.title}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Button
                style={{
                  width: 100,
                  marginLeft: "25px",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
                variant="contained"
                size="medium"
                type="submit"
                className="submit-btn-text"
                disabled={isSubmitting}
              >
                Submit
              </Button>
              <Link to="/health-and-wellness">
                <Button
                  style={{
                    width: 100,
                    marginLeft: "25px",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                  variant="outlined"
                  size="medium"
                  type="submit"
                  className="back-btn-text"
                >
                  cancel
                </Button>
              </Link>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};
export default AddVideo;
