import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { SnackbarProvider } from "notistack";
import UserContext from "./context/userContext";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import "globalthis/auto";
import Spinner from "./components/spinner-loader/spinner-loader";
// const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const user = localStorage.getItem("currentUser");

root.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <SnackbarProvider maxSnack={1}>
        <UserContext.Provider value={user}>
          <App />
        </UserContext.Provider>
      </SnackbarProvider>
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// var Header = require('postman-collection').Header;
// pm.request.headers.add(new Header("authorization: " + pm.variables.get("access_token")))