import React from "react";
import { visuallyHidden } from "@mui/utils";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const HealthAndWellnessTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "thumbnail",
      numeric: false,
      disablePadding: false,
      label: "Thumbnail",
      headerKey: "thumbnail",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
      headerKey: "title",
    },

    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      headerKey: "action",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={headCell.id === "action" ? "hide-sort-icon" : ""}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold",
              paddingLeft: "15px",
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.headerKey}
              direction={orderBy === headCell.headerKey ? order : "asc"}
              onClick={createSortHandler(headCell.headerKey)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

HealthAndWellnessTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default HealthAndWellnessTableHead;
