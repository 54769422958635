import * as React from "react";
import * as Yup from "yup";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field } from "formik";
import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { AuthenticationService } from "../../shared/_services/authentication.service";
import { useSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loginwcilogo from "../../assets/images/loginwcilogo.png";
import "./login.css";

const theme = createTheme();

const ForgotPassword = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  // const [isOpenBtn, setIsOpenBtn] = useState(false);
  const navigate = useNavigate();
  const auth = localStorage.getItem("currentUser");

  useEffect(() => {
    auth && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleAuthentication = async ({ email }) => {
    try {
      const user = await AuthenticationService.requestForgotPassword(email);

      if (user?.error?.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar(user?.error?.message, { variant });
      } else if (!user?.error) {
        let variant = "success";
        enqueueSnackbar(
          user.message
            ? user.message
            : "An email with verification link sent to your registered email address, please follow the instructions in order to reset your password!",
          { variant }
        );
        // setIsOpenBtn(false);
      }
    } catch (error) {
      let variant = "error";
      enqueueSnackbar(error && error.message, { variant });
      // setIsOpenBtn(true);
    }
  };

  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
  });
  const handleSubmit = (values, { resetForm }) => {
    handleAuthentication(values);
    resetForm();
    // setIsOpenBtn(true);
  };

  return (
    <div className="login-page">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
          >
            <img
              src={Loginwcilogo}
              alt="logo"
              className="login-logo"
              style={{ width: "30%", marginBottom: "20px" }}
            />

            <Typography
              component="h1"
              variant="h5"
              style={{ fontSize: "25px" }}
            >
              Forgot Password?
            </Typography>
            <Typography component="p" variant="p" style={{ fontSize: "13px" }}>
              You can reset your password here
            </Typography>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              method="post"
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values }) => (
                <Form noValidate>
                  <Box sx={{ mt: 1 }}>
                    <Field
                      name="email"
                      getOptionLabel={(option) => option.title}
                    >
                      {({ values, field }) => (
                        <TextField
                          sx={{
                            input: { color: "#000" },
                            fieldset: { borderColor: "#000" },
                            "& label": {
                              color: "#000",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="email"
                          {...field}
                          id="email"
                          label="Email Address"
                          name="email"
                          size="small"
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email
                              ? `${errors.email}`
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </Box>

                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!values.email}
                    className="submit-btn-text"
                  >
                    Reset Password
                  </Button>

                  <Grid container>
                    <Grid item xs style={{ textAlign: "end" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{ mb: 2 }}
                        style={{ background: "rgb(68, 183, 82)" }}
                      >
                        <NavLink
                          to="/login"
                          variant="body2"
                          style={{ color: "#fff" }}
                        >
                          Back To Login
                        </NavLink>
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};
export default ForgotPassword;
