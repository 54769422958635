import * as React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { UserService } from "../../shared/_services";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Spinner from "../spinner-loader/spinner-loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import BannerTableHead from "./table-head/banner-table-head.js";
import { Constants } from "../../shared/_helpers";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "globalthis/auto";
import "core-js/actual";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const rowStyle = {
  paddingLeft: "20px",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (orderBy === "banner") {
    a = a.title.toLowerCase();
    b = b.title.toLowerCase();
    return a.localeCompare(b) * 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const initialValue = {
  from: "",
  to: "",
};

const BannerTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [banners, setBanners] = useState([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteTost, setDeleteTost] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [currentUID, setCurrentUID] = useState("");
  const [dateRange] = useState(initialValue);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl2);

  const handleDropDownClick = (event, userId) => {
    setCurrentUID(userId);
    return setAnchorEl2(event.currentTarget);
  };

  const handleDropDownClose = () => {
    setAnchorEl2(null);
  };
  const style = {
    color: "#000",
    cursor: "pointer",
  };

  const titleStyle = {
    marginTop: "6px",
    color: "#000",
  };

  useEffect(() => {
    fetchData("paginationChange");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const deleteToast = () => {
    setDeleteTost(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteTost(false);
  };

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const fetchData = (param) => {
    let searchData;
    if (param === "cancel") searchData = "";
    else searchData = search;
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      search: searchData,
      start: dateRange.from,
      end: dateRange.to,
    };
    UserService.getAllBanners(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setBanners(response.banners[0].data);

        if (response.banners[0].metadata.length > 0) {
          setTotal(response.banners[0].metadata[0].total);
        } else {
          setTotal(0);
        }
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  //delete banner
  const onDeleteBanner = async (id, enqueueSnackbar) => {
    const result = await confirm("Do you want to delete this banner?", options);
    if (result) {
      UserService.deleteBanner(id, enqueueSnackbar).then((response) => {
        console.log("delete", response);
        deleteToast(true);
        fetchData("paginationChange");
      });
    }
  };
  // SEARCH CODES
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("cancel");
    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = banners.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setIsSearchShown(false);
    } else {
      setIsSearchShown(true);
    }
    setSearch(e.target.value);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Banners
          </Typography>

          <Typography sx={{ flex: "1 1 5%" }} component="div">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
                float: "right",
                display: "flex",
              }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  width: 250,
                  alignItems: "center",
                }}
              >
                <InputBase
                  value={search}
                  onInput={onInputChange}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
          <Typography component="div" style={{ marginLeft: "20px" }}>
            <Stack sx={{ flex: "1 1 30%" }} spacing={2} direction="row">
              <Link to="/banner/add-banner">
                <Button variant="contained" className="submit-btn-text">
                  ADD
                </Button>
              </Link>
            </Stack>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <TableContainer>
          {loading ? (
            <Spinner />
          ) : (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <BannerTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={banners.length}
                key={banners._id}
              />

              {banners.length === 0 ? (
                <TableBody>
                  <TableRow style={{ textAlign: "center" }}>
                    <TableCell colSpan={5}>
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        Data not found
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                stableSort(banners, getComparator(order, orderBy)).map(
                  (banner, index) => {
                    const isItemSelected = isSelected(banner._id);

                    return (
                      <TableBody>
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={banner._id}
                          selected={isItemSelected}
                        >
                          <TableCell style={{ rowStyle }}>
                            <img
                              src={`${Constants.BASE_URL}/banner/${banner.picture}`}
                              alt="banner"
                              style={{
                                width: "20%",
                                height: "50px",
                                borderRadius: "10px",
                              }}
                            />
                            {/* {{banner.picture}} */}
                          </TableCell>
                          <TableCell>
                            {banner?.name === "" || banner?.name === "undefined"
                              ? "N/A"
                              : toTitleCase(banner?.name)}
                          </TableCell>
                          <TableCell>
                            {}
                            {banner?.start_date === "" ||
                            banner?.start_date === "undefined"
                              ? "N/A"
                              : moment(banner.start_date).format("MM-DD-YYYY")}
                          </TableCell>
                          <TableCell>
                            {banner?.end_date === "" ||
                            banner?.end_date === "undefined"
                              ? "N/A"
                              : moment(banner.end_date).format("MM-DD-YYYY")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ verticalAlign: "middle" }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleDropDownClick(event, banner._id)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={banner._id === currentUID && anchorEl2}
                              open={banner._id === currentUID && open}
                              onClose={handleDropDownClose}
                            >
                              <Link as={Link} to={`/banner/${banner._id}/edit`}>
                                <MenuItem
                                  onClick={handleDropDownClose}
                                  disableRipple
                                >
                                  <EditIcon style={style} />
                                  <h5 style={titleStyle}>Edit</h5>
                                </MenuItem>
                              </Link>
                              <Divider sx={{ my: 0.5 }} />
                              <div onClick={(e) => onDeleteBanner(banner._id)}>
                                <MenuItem
                                  onClick={handleDropDownClose}
                                  disableRipple
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                  <h5 style={{ marginTop: "8px" }}>Delete</h5>
                                </MenuItem>
                              </div>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  }
                )
              )}
            </Table>
          )}
        </TableContainer>
        <TablePagination
          className="p"
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar open={deleteTost} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Banner deleted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default BannerTable;
