import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthenticationService } from "../../shared/_services";
import { UserService } from "../../shared/_services";
import { confirm } from "react-confirm-box";
import { Formik, Form, Field } from "formik";
import { message, Upload } from "antd";
import { useSnackbar } from "notistack";

import * as Yup from "yup";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Spinner from "../../components/spinner-loader/spinner-loader";
import Grid from "@mui/material/Grid";
import ImgCrop from "antd-img-crop";
import { Constants } from "../../shared/_helpers";
import "globalthis/auto";
import "./edit-profile.css";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  profilePic: "",
};

export default function EditProfile({ handleEditProfile }) {
  const [value] = useState("1");
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();
  const [userId] = useState(
    AuthenticationService.currentUser.source._value.userId
  );
  const actionUrlUser = `${Constants.BASE_URL}/api/Containers/user/upload`;
  const [isOpenBtn, setIsOpenBtn] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "",
      status: "done",
      url: "",
      thumbUrl: "",
    },
  ]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(20, "Name must not exceed 20 characters!")
      .required("First Name is required!"),

    lastName: Yup.string()
      .trim()
      .max(20, "Name must not exceed 20 characters!")
      .required("Last Name is required!"),
  });

  useEffect(() => {
    // get individula user data by passing user id

    UserService.getUserDetails(userId, enqueueSnackbar).then((response) => {
      if (!response.error) {
        const { firstName, lastName, profilePic, email } = response;
        initialValues.firstName = firstName;
        initialValues.lastName = lastName;
        initialValues.email = email;
        initialValues.profilePic = profilePic;
        setFileList([
          {
            uid: response.id,
            thumbUrl: response.profilePic
              ? `${Constants.BASE_URL}/user/${response.profilePic}`
              : `${"https://i.ibb.co/wynJtDH/avatar.png"}`,
            status: "done",
            url: "",
          },
        ]);
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  // user data update button
  const onUpdateSubmit = async (values) => {
    const result = await confirm(
      "Do you want to update your profile?",

      options
    );
    if (result) {
      let data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        profilePic: profilePic === "" ? initialValues.profilePic : profilePic,
      };

      setIsOpenBtn(true);
      UserService.updateUser(userId, data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Admin details updated successfully!", { variant });
          setIsOpenBtn(false);

          handleEditProfile(response);
          Navigate("/");
          // window.location.reload();
        }
      });
    }
  };

  // user profile photo change
  const onUserPhotoChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].response) {
      setProfilePic(newFileList[0].response.result.files.photo[0].name);
    }
  };

  //upload file data restrictions
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            View Profile
          </Typography>
          <Link to="/">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          {loading ? (
            <Spinner />
          ) : (
            <TabPanel value="1">
              <Paper
                sx={{
                  p: 2,
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 830,
                  flexGrow: 1,
                }}
                style={{ height: 140 }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={2}></Grid>
                  <Grid item md={4} xs={12} style={{ textAlign: "center" }}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        showUploadList={{
                          showRemoveIcon: false,
                          showPreviewIcon: true,
                        }}
                      ></Upload>
                    </ImgCrop>
                  </Grid>
                  <Grid item md={4} xs={12} style={{ textAlign: "center" }}>
                    <ImgCrop showGrid rotationSlider cropShape={"round"}>
                      <Upload
                        action={actionUrlUser}
                        name={"photo"}
                        maxCount={1}
                        showUploadList={false}
                        onChange={onUserPhotoChange}
                        beforeUpload={beforeUpload}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          className="change-password-bth submit-btn-text"
                        >
                          Upload
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Paper>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onUpdateSubmit}
              >
                {({ errors, touched }) => (
                  <Grid
                    sx={{
                      margin: "auto",
                      maxWidth: 830,
                      flexGrow: 1,
                    }}
                    style={{ height: 220 }}
                  >
                    <Form
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        "& > :not(style)": {},
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="firstName">
                          {({ field }) => (
                            <TextField
                              label="First Name"
                              size="small"
                              fullWidth
                              display="flex"
                              {...(field ?? "")}
                              style={{ marginTop: "20px", marginRight: "10px" }}
                              error={
                                errors.firstName && touched.firstName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.firstName && touched.firstName
                                  ? `${errors.firstName}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                        <Field name="lastName">
                          {({ field }) => (
                            <TextField
                              label="last Name"
                              size="small"
                              fullWidth
                              type="text"
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px", marginLeft: "10px" }}
                              error={
                                errors.lastName && touched.lastName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.lastName && touched.lastName
                                  ? `${errors.lastName}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "& > :not(style)": {},
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="email">
                          {({ field }) => (
                            <TextField
                              label="Email"
                              size="small"
                              fullWidth
                              disabled={true}
                              type="email"
                              display="flex"
                              {...field}
                              style={{ marginTop: "20px" }}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? `${errors.email}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>

                      <Button
                        style={{
                          width: 100,
                          marginTop: "25px",
                          marginBottom: "25px",
                        }}
                        variant="contained"
                        size="medium"
                        type="submit"
                        disabled={isOpenBtn}
                        className="submit-btn-text form-submit-btn"
                      >
                        Update
                      </Button>
                    </Form>
                  </Grid>
                )}
              </Formik>
            </TabPanel>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
}
