import React, { useState, useEffect, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "../../shared/_services";
import { AuthenticationService } from "../../shared/_services";
import { FaBars } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { Constants } from "../../shared/_helpers";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Badge,
} from "@mui/material";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import logo from "../../assets/images/admin-logo.png";
import UserContext from "../../context/userContext";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const Navbar = ({ toggleSidebar, isOpen }) => {
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  // const [email, setEmail] = useState();
  // const [fileList, setFileList] = useState([{ thumbUrl: "" }]);
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useContext(UserContext);

  const userId = currentUser?.userId;
  const navigate = useNavigate();
  const thumbUrl = currentUser?.user?.profilePic
    ? `${Constants.BASE_URL}/user/${currentUser?.user?.profilePic}`
    : `${"https://i.ibb.co/wynJtDH/avatar.png"}`;

  const toggle = () => {
    toggleSidebar(isOpen);
  };

  useEffect(() => {
    // get individula user data by passing user id
    UserService.getUserDetails(userId, enqueueSnackbar).then((response) => {
      // setFirstName(response.firstName);
      // setLastName(response.lastName);
      // setEmail(response.email);
      // setFileList({
      //   thumbUrl: response.profilePic
      //     ? `${Constants.BASE_URL}/user/${response.profilePic}`
      //     : `${"https://i.ibb.co/wynJtDH/avatar.png"}`,
      // });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div className="top-section">
          <img alt="Logo" className="logo" src={logo} />

          <div className="bars" style={{ marginLeft: "55px" }}>
            <FaBars onClick={toggle} className="bar" />
          </div>
        </div>
        <Tooltip title="Notifications">
          <Badge
            color="success"
            overlap="circular"
            variant="dot"
            className="notification-icon"
            onClick={() => navigate("/notifications")}
          >
            <NotificationsNoneOutlinedIcon fontSize="large" color="action" />
          </Badge>
        </Tooltip>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 40, height: 40 }} src={thumbUrl} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem style={{ backgroundColor: "#e3eaf1" }}>
          <ListItemIcon>
            <Avatar src={thumbUrl} fontSize="small" />
          </ListItemIcon>
          <div className="ml-2">
            <h4 className="mb-0">
              {currentUser?.user?.firstName + " " + currentUser?.user?.lastName}
            </h4>
            {/* <p className=" mb-0">{email}</p> */}
          </div>
        </MenuItem>
        <Link to="/edit-profile">
          <MenuItem>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <h4 style={{ marginTop: "10px", color: "#000" }}> Profile </h4>
          </MenuItem>
        </Link>
        <Link to="/change-password">
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <h4 style={{ marginTop: "10px", color: "#000" }}>
              Change Password
            </h4>
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem
          component={Link}
          to="/login"
          onClick={() => {
            AuthenticationService.logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <h4 style={{ marginTop: "10px" }}> Logout </h4>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Navbar;
