import React, { useState } from "react";
import Navbar from "../navigation/navigation";
import Sidebar from "../sidebar/sidebar";
import Main from "../main/main";

const UserLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = (isOpen) => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} />
      <div className="wrapper">
        <Sidebar isOpen={isOpen} />
        <Main>{children}</Main>
      </div>
    </>
  );
};

export default UserLayout;
