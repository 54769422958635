import { Formik, Form, FieldArray, Field } from "formik";
import {
  Button,
  Typography,
  Box,
  Paper,
  Toolbar,
  IconButton,
} from "@mui/material";

import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { UserService } from "../../shared/_services";
import dayjs from "dayjs";
import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiDeleteBinLine } from "react-icons/ri";
import { confirm } from "react-confirm-box";
import "./availability.css";

const Availability = () => {
  const [expanded, setExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [offDates, setOffDates] = useState([]);
  const [id, setId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const initialValues = {
    days: [
      {
        weekday: 0,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 1,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 2,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 3,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 4,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 5,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
      {
        weekday: 6,
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
        is_closed: false,
      },
    ],
    breaks: [
      {
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0,
      },
    ],
    off_dates: [],
    timezone: "",
    therapistId: "",
  };

  const validationSchema = Yup.object().shape({
    days: Yup.array().of(
      Yup.object().shape({
        weekday: Yup.number().required("Weekday is required"),
        startHour: Yup.number()
          .typeError("Value must be a number")
          .required("Value is required"),
        startMinute: Yup.number()
          .typeError("Start minute must be a number")
          .required("Start minute is required"),
        endHour: Yup.number()
          .typeError("Value must be a number")
          .required("Value is required")
          .test(
            "greaterThanNumber1",
            "From value must be greater than To value",
            function (value) {
              const startHour = this.resolve(Yup.ref("startHour"));
              return value >= startHour;
            }
          ),
        endMinute: Yup.number()
          .typeError("End minute must be a number")
          .required("End minute is required"),
      })
    ),
    breaks: Yup.array().of(
      Yup.object().shape({
        startHour: Yup.number().typeError("Value must be a number"),
        startMinute: Yup.number().typeError("Value must be a number"),
        endHour: Yup.number().typeError("Value must be a number"),
        endMinute: Yup.number().typeError("Value must be a number"),
      })
    ),
    off_dates: Yup.array().of(
      Yup.object().shape({
        date: Yup.date()
          .required("Date is required!")
          .test("valid-date", "Invalid date format!", function (value) {
            const currentDate = new Date();
            const selectedDate = new Date(value);

            // Check if the selected date is in the past
            if (selectedDate < currentDate.setHours(0, 0, 0, 0)) {
              return this.createError({
                message: "Past dates are not allowed!",
                path: this.path,
              });
            }

            // Check if the selected date has a valid format (day, month, year)
            const isValidDate =
              selectedDate.getDate() === value.getDate() &&
              selectedDate.getMonth() === value.getMonth() &&
              selectedDate.getFullYear() === value.getFullYear();

            if (!isValidDate) {
              return this.createError({
                message: "Invalid date format! Please enter a valid date.",
                path: this.path,
              });
            }

            return true;
          }),
      })
    ),
  });

  const weekLabel = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [daysArr, setDays] = useState(initialValues.days);
  const [breakArr, setBreak] = useState(initialValues.breaks);

  useEffect(() => {
    UserService.getAvailability(enqueueSnackbar).then((response) => {
      let offDates = [];

      const { breaks, days, id, off_dates, therapistId } = response[0];
      setId(id);
      initialValues.days = days;
      initialValues.breaks = breaks;
      setBreak(breaks);

      initialValues.off_dates = off_dates;
      initialValues.therapistId = therapistId;
      off_dates.forEach((item) => {
        offDates.push(transformedDate(item.date));
      });
      setOffDates(offDates);
      setDays(initialValues.days);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedDate = (date) => {
    date = date.split("T")[0];
    date = date.split("-");
    return new DateObject({ year: date[0], month: date[1], day: date[2] });
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(false);
    const result = await confirm(
      "Do you want to update availability?",
      options
    );
    if (result) {
      UserService.addAvailability(id, values, enqueueSnackbar).then(
        (response) => {
          if (!response.error) {
            let variant = "success";
            enqueueSnackbar("Availability details updated successfully!", {
              variant,
            });
            setIsSubmitting(false);
          }
        }
      );
    } else {
      setIsSubmitting(false); // Set isSubmitting to false if the user clicks on "No"
    }
  };

  let handleAddBreakHours = (arr, val) => {
    if (val?.breaks.length === 0) {
      arr.push({
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0,
      });
    } else {
      let checkHours = val?.breaks.filter((data) => {
        return !data.startHour || !data.endHour ? true : null;
        // if () {
        //   return true;
        // }
      });
      if (checkHours.length > 0) {
        let variant = "error";
        enqueueSnackbar("Please update the previous break hours first!", {
          variant,
        });
      } else {
        arr.push({
          startHour: 0,
          startMinute: 0,
          endHour: 0,
          endMinute: 0,
        });
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Availability
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <Box>
          <Typography
            variant="p"
            id="tableTitle"
            component="div"
            className="header-text"
            margin="20px 0 0 20px"
            fontSize="18px!important"
          >
            Business Hours
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ dirty, values, setFieldValue, errors, touched }) => (
            <Form style={{ width: "90%", margin: "20px" }}>
              <Box sx={{ margin: "20px 20px 0px 0px" }} className="breaks">
                <FieldArray name="days">
                  {({ push, remove, field, form }) => (
                    <>
                      <div>
                        {values.days &&
                          values.days.length > 0 &&
                          values.days.map((day, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid #b97018 ",
                                    borderRadius: "4px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Accordion
                                    expanded={expanded === `panel${index}`}
                                    onChange={handleChange(`panel${index}`)}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <Typography
                                        sx={{
                                          width: "33%",
                                          flexShrink: 0,
                                        }}
                                      >
                                        {weekLabel[index]}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box
                                        sx={{
                                          width: "60%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["TimePicker"]}
                                            >
                                              <TimePicker
                                                label="From"
                                                disabled={
                                                  values.days[index].is_closed
                                                }
                                                ampm={false}
                                                inputVariant="outlined"
                                                className="small-timepicker time_picker"
                                                id={`daystimePickerfrom${index}`}
                                                name={`daysArr[${index}].startHour`}
                                                defaultValue={dayjs(
                                                  `2022-04-17T${daysArr[index].startHour}:${daysArr[index].startMinute}`
                                                )}
                                                value={dayjs(
                                                  `2022-04-17T${daysArr[index].startHour}:${daysArr[index].startMinute}`
                                                )}
                                                onChange={(newValue) => {
                                                  if (newValue) {
                                                    let dt = new Date(newValue);
                                                    let hr = dt.getHours();
                                                    let mn = dt.getMinutes();

                                                    setFieldValue(
                                                      `days[${index}].startHour`,
                                                      hr
                                                    );
                                                    setFieldValue(
                                                      `days[${index}].startMinute`,
                                                      mn
                                                    );
                                                  } else {
                                                    form.setFieldError(
                                                      `days[${index}].startHour`,
                                                      "Required"
                                                    );

                                                    form.setFieldError(
                                                      `days[${index}].startMinute`,
                                                      "Required"
                                                    );
                                                  }
                                                }}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>

                                          {form.touched?.days?.[index]
                                            ?.startHour &&
                                          form.errors?.days?.[index]
                                            ?.startHour ? (
                                            <div
                                              className="error"
                                              style={{ textAlign: "left" }}
                                            >
                                              {
                                                form.errors?.days?.[index]
                                                  ?.startHour
                                              }
                                            </div>
                                          ) : null}
                                        </div>

                                        <div>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={["TimePicker"]}
                                            >
                                              <TimePicker
                                                label="To"
                                                disabled={
                                                  values.days[index].is_closed
                                                }
                                                className="time_picker"
                                                ampm={false}
                                                id={`daystimePickerto${index}`}
                                                name={`daysArr[${index}].endHour`}
                                                defaultValue={dayjs(
                                                  `2022-04-17T${daysArr[index].endHour}:${daysArr[index].endMinute}`
                                                )}
                                                value={dayjs(
                                                  `2022-04-17T${daysArr[index].endHour}:${daysArr[index].endMinute}`
                                                )}
                                                onChange={(newValue) => {
                                                  if (newValue) {
                                                    let dt = new Date(newValue);
                                                    let hr = dt.getHours();
                                                    let mn = dt.getMinutes();

                                                    setFieldValue(
                                                      `days[${index}].endHour`,
                                                      hr
                                                    );

                                                    setFieldValue(
                                                      `days[${index}].endMinute`,
                                                      mn
                                                    );
                                                  } else {
                                                    form.setFieldError(
                                                      `days[${index}].endHour`,
                                                      "Required"
                                                    );

                                                    form.setFieldError(
                                                      `days[${index}].endMinute`,
                                                      "Required"
                                                    );
                                                  }
                                                }}
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>

                                          {form.touched?.days?.[index]
                                            ?.endHour &&
                                          form.errors?.days?.[index]
                                            ?.endHour ? (
                                            <div
                                              className="error"
                                              style={{ textAlign: "left" }}
                                            >
                                              {
                                                form.errors?.days?.[index]
                                                  ?.endHour
                                              }
                                            </div>
                                          ) : null}
                                        </div>
                                      </Box>
                                      <Box style={{ marginTop: "20px" }}>
                                        <label>
                                          <Field
                                            id={`checkbox${index}`}
                                            className="custom-checkbox"
                                            type="checkbox"
                                            name={`days[${index}].is_closed`}
                                            checked={
                                              values.days[index].is_closed
                                            }
                                            onChange={(e) => {
                                              const { checked } = e.target;

                                              setFieldValue(
                                                `days[${index}].is_closed`,
                                                checked
                                              );
                                              if (checked) {
                                                setFieldValue(
                                                  `days[${index}].startHour`,
                                                  0
                                                );
                                                setFieldValue(
                                                  `days[${index}].startMinute`,
                                                  0
                                                );
                                                setFieldValue(
                                                  `days[${index}].endHour`,
                                                  0
                                                );

                                                setFieldValue(
                                                  `days[${index}].endMinute`,
                                                  0
                                                );

                                                const updatedDays = [
                                                  ...daysArr,
                                                ];
                                                updatedDays[index].startHour =
                                                  null;
                                                updatedDays[index].startMinute =
                                                  null;
                                                updatedDays[index].endHour =
                                                  null;
                                                updatedDays[index].endMinute =
                                                  null;
                                                setDays(updatedDays);
                                              } else {
                                                const updatedDays = [
                                                  ...daysArr,
                                                ];

                                                updatedDays[index].startHour =
                                                  initialValues.days[
                                                    index
                                                  ].startHour;
                                                updatedDays[index].startMinute =
                                                  initialValues.days[
                                                    index
                                                  ].startMinute;
                                                updatedDays[index].endHour =
                                                  initialValues.days[
                                                    index
                                                  ].endHour;
                                                updatedDays[index].endMinute =
                                                  initialValues.days[
                                                    index
                                                  ].endMinute;
                                                setDays(updatedDays);

                                                setFieldValue(
                                                  `days[${index}].startHour`,
                                                  daysArr[index].startHour
                                                );
                                                setFieldValue(
                                                  `days[${index}].startMinute`,
                                                  daysArr[index].startMinute
                                                );
                                                setFieldValue(
                                                  `days[${index}].endHour`,
                                                  daysArr[index].endHour
                                                );

                                                setFieldValue(
                                                  `days[${index}].endMinute`,
                                                  daysArr[index].endMinute
                                                );
                                              }
                                            }}
                                          />
                                          &nbsp;Closed
                                        </label>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                </FieldArray>
              </Box>
              <Box>
                <Box>
                  <Typography
                    variant="p"
                    id="tableTitle"
                    component="div"
                    className="header-text"
                    margin="20px 0 10px 0px"
                    fontSize="18px!important"
                  >
                    Break Hours
                  </Typography>
                </Box>

                <FieldArray
                  name="breaks"
                  render={(arrayHelpers, form) => (
                    <div>
                      {values.breaks &&
                        values.breaks.length > 0 &&
                        values.breaks.map((day, index) => {
                          return (
                            <div key={index}>
                              <Box
                                sx={{
                                  width: "60%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "20px",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "90%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="breaks"
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["TimePicker"]}>
                                      <TimePicker
                                        className="time_picker"
                                        label="From"
                                        ampm={false}
                                        inputVariant="outlined"
                                        size="small"
                                        defaultValue={dayjs(
                                          `2022-04-17T${breakArr[index]?.startHour}:${breakArr[index]?.startMinute}`
                                        )}
                                        value={dayjs(
                                          `2022-04-17T${breakArr[index]?.startHour}:${breakArr[index]?.startMinute}`
                                        )}
                                        id={`breaktimePickerfrom${index}`}
                                        onChange={(newValue) => {
                                          if (newValue) {
                                            let dt = new Date(newValue);
                                            let hr = dt.getHours();
                                            let mn = dt.getMinutes();

                                            setFieldValue(
                                              `breaks[${index}].startHour`,
                                              hr
                                            );

                                            setFieldValue(
                                              `breaks[${index}].starMinute`,
                                              mn
                                            );
                                          }
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>

                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["TimePicker"]}>
                                      <TimePicker
                                        className="time_picker"
                                        label="To"
                                        ampm={false}
                                        defaultValue={dayjs(
                                          `2022-04-17T${breakArr[index]?.endHour}:${breakArr[index]?.endMinute}`
                                        )}
                                        value={dayjs(
                                          `2022-04-17T${breakArr[index]?.endHour}:${breakArr[index]?.endMinute}`
                                        )}
                                        id={`breakstimePickerto${index}`}
                                        onChange={(newValue) => {
                                          if (newValue) {
                                            let dt = new Date(newValue);
                                            let hr = dt.getHours();
                                            let mn = dt.getMinutes();

                                            setFieldValue(
                                              `breaks[${index}].endHour`,
                                              hr
                                            );

                                            setFieldValue(
                                              `breaks[${index}].endMinute`,
                                              mn
                                            );
                                          }
                                        }}
                                        style={{ border: "1px solid #b97018 " }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Box>
                                <Box>
                                  {/* Add a button to remove the break */}
                                  {index > 0 && (
                                    <IconButton
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "20px",
                                        color: "#b97018",
                                        fontSize: "20px",
                                        height: " 40px",
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                            </div>
                          );
                        })}

                      {/* Add a button to add a new break */}
                      {
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "54%",
                          }}
                          className="breaks"
                        >
                          Add More Break Hours
                          <IconButton
                            onClick={() =>
                              handleAddBreakHours(arrayHelpers, values)
                            }
                            sx={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              border: "2px solid rgb(185, 112, 24)",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      }
                    </div>
                  )}
                />
              </Box>
              <Box>
                <Box>
                  <Typography
                    variant="p"
                    id="tableTitle"
                    component="div"
                    className="header-text"
                    margin="20px 20px 0 0px"
                    fontSize="18px!important"
                  >
                    Off Days
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Field name="off_dates">
                    {({ field, form }) => (
                      <>
                        <MultiDatePicker
                          minDate={new Date()}
                          name="off_dates"
                          style={{
                            width: "98.5%",
                            padding: "25px 15px",
                            border: "1px solid  #b97018",
                            margin: "20px 20px 0 0",
                          }}
                          multiple
                          format="YYYY-MM-DD"
                          placeholder="Select dates"
                          value={offDates}
                          onChange={(dates, index) => {
                            if (dates) {
                              const datesArr = [];
                              dates?.forEach((item, index) => {
                                const date = new DateObject(item);
                                datesArr[index] = {
                                  date: date.format("YYYY-MM-DD"),
                                };
                              });

                              setFieldValue("off_dates", datesArr);
                            }
                          }}
                        />
                        {errors.off_dates && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors.off_dates?.[0]?.date}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </Box>

                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit-btn-text"
                    // disabled={isSubmitting}
                    disabled={!dirty || isSubmitting}
                    style={{
                      width: 100,
                      marginTop: "25px",
                      marginBottom: "25px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Availability;
