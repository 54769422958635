import Button from "@mui/material/Button";

function UploadVideoButton(props) {
  const { index = 0, onChange, children } = props;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="submit-btn-text"
        onClick={() => document.getElementById(`video-input-${index}`).click()}
      >
        {children}
      </Button>
      <input
        id={`video-input-${index}`}
        type="file"
        accept="video/mov, video/mp4"
        style={{ display: "none" }}
        onChange={onChange}
      />
    </>
  );
}

export default UploadVideoButton;
