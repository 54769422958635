import Button from "@mui/material/Button";

function UploadButton(props) {
  const { onChange, children } = props;

  const handleButtonClick = () => {
    // Click the hidden file input
    document.getElementById("file-input").click();
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        {children}
      </Button>
      <input
        id="file-input"
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={onChange}
      />
    </>
  );
}

export default UploadButton;
