import React from "react";
import { visuallyHidden } from "@mui/utils";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const AppointmentUserTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "user_pic",
      numeric: false,
      disablePadding: false,
      label: "Picture",
      headerKey: "userPic",
    },
    {
      id: "user_name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      headerKey: "userName",
    },
    {
      id: "appointment_duration",
      numeric: false,
      disablePadding: true,
      label: "Duration (Min)",
      headerKey: "appointmentDuration",
    },
    {
      id: "appointment_date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      headerKey: "appointmentDate",
    },
    {
      id: "appointment_time",
      numeric: false,
      disablePadding: false,
      label: "Time",
      headerKey: "appointmentDate",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headerKey: "past",
    },
    // {
    //   id: "upcoming",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Upcoming",
    //   headerKey: "upcoming",
    // },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold",
              paddingLeft: "15px",
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.headerKey}
              direction={orderBy === headCell.headerKey ? order : "asc"}
              onClick={createSortHandler(headCell.headerKey)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AppointmentUserTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default AppointmentUserTableHead;
