import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { confirm } from "react-confirm-box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import { UserService } from "../../shared/_services";
// import { Constants } from "../../shared/_helpers";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Formik, Form, Field } from "formik";
import "./appointment.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner-loader/spinner-loader";

const localizer = momentLocalizer(moment);
const initialValues = {
  from: "",
  to: "",
};
// const style = {
//   fontSize: 14,
//   fontWeight: "700",
// };
// const fontStyle = {
//   fontSize: 14,
// };
// function toTitleCase(str) {
//   return str.replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// }
const options = {
  labels: {
    confirmable: "Yes",
    cancellable: "No",
  },
};
const Appointment = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  // const [profilephoto, setProfilephoto] = useState();
  const [slots, setSlots] = useState([]);
  const [search] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [rowsPerPage] = useState(10);
  // const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [disableJoinBtn, setDisableJoinBtn] = useState(true);
  const [dateRange] = useState(initialValues);
  const [loading, setLoading] = useState(true);
  // const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [canceledEventIds, setCanceledEventIds] = useState([]);
  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);

  const userId = user.userId;

  const handleSelectSlot = ({ start, end }) => {
    const newEvent = {
      start,
      time: "",
      title: "",
      queries: "",
      duration: "",
      is_cancelled: "",
    };
    setSelectedEvent(newEvent);
    setIsModalOpen(true);
  };

  const handleSelectEvent = (event) => {
    setDisableJoinBtn(false);
    const selected = formattedEvents.find((item) => item.id === event.id);

    if (selected) {
      if (selectedEvent && selectedEvent.id === selected.id) {
        // If the event is already selected, close the modal
        setSelectedEvent(null);
        setIsModalOpen(false);
        setDisableJoinBtn(true);
      } else {
        setIsModalOpen(true);

        const dateStr = selected.date.split("T")[0] + "T" + selected.time;

        let startTime = moment(dateStr, "YYYY-MM-DDTHH:mm").format("x");
        let endTime = moment(dateStr, "YYYY-MM-DDTHH:mm")
          .add(selected.duration, "minutes")
          .format("x");
        //enabling join btn by meeting time
        const currentTime = new Date().getTime();
        if (currentTime < endTime && currentTime > startTime) {
          setDisableJoinBtn(false);
        }

        selected.startTime = startTime;
        selected.currentTime = currentTime;
        selected.endTime = endTime;

        setSelectedEvent(selected);

        const dateString = event.date;

        let data = {
          date: dateString,
          duration: event.duration,
          therapistId: userId,
        };

        UserService.getSlots(data, enqueueSnackbar).then((response) => {
          setLoading();

          if (!response.error) {
            setSlots(response?.slots);
            setLoading(false);
          }
        });
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setDisableJoinBtn(true);
  };
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  const handleFormSubmit = async (values) => {
    const result = await confirm(
      "Do you want to reschedule this appointment?",
      options
    );

    // You can access the form values from the 'values' object
    if (result) {
      const data = {
        date: values.start,
        time: values.time,
        duration: values.duration,
        query: values.query,
        therapistId: values.therapistId,
        appointmentId: values.id,
      };
      UserService.rescheduleAppointment(data, enqueueSnackbar).then(
        (response) => {
          if (!response.error) {
            //console.log("event", eventId);
            //setRescheduledEventIds([...rescheduledEventIds, eventId]);
            // setSelectedEvent(null);

            // setEvents([...appointments, values]);

            setSelectedEvent(null);
            // setIsModalOpen(false);
            enqueueSnackbar("Appointment rescheduled successfully", {
              variant: "success",
            });
          } else {
            // Handle cancellation error
            enqueueSnackbar("Failed to rescheduled appointment", {
              variant: "error",
            });
          }
        }
      );
    }
  };
  // const handleReschedule = async (eventId) => {
  //   // const result = await confirm(
  //   //   "Do you want to reschedule this appointment?",
  //   //   options
  //   // );
  //   // console.log(
  //   //   "🚀 ~ file: appointment.js:212 ~ handleReschedule ~ result:",
  //   //   result
  //   // );
  //   console.log(
  //     "🚀 ~ file: appointment.js:207 ~ handleReschedule ~ eventId, values:",
  //     eventId,
  //     selectedEvent
  //   );
  // };
  //cancel appointment
  const handleCancelAppointment = async (eventId, userId) => {
    // Make API call to cancel appointment using eventId
    // Update the events state after successful cancellation
    const result = await confirm(
      "Do you want to cancel this appointment?",
      options
    );
    if (result) {
      UserService.cancelAppointment({ eventId, userId }, enqueueSnackbar).then(
        (response) => {
          if (!response.error) {
            // Appointment cancellation successful
            // Update the events state by removing the canceled event
            setCanceledEventIds([...canceledEventIds, eventId]);
            setSelectedEvent(null);
            setIsModalOpen(false);
            setDisableJoinBtn(true);
            enqueueSnackbar("Appointment canceled successfully", {
              variant: "success",
            });
          } else {
            // Handle cancellation error
            enqueueSnackbar("Failed to cancel appointment", {
              variant: "error",
            });
          }
        }
      );
    }
  };

  //fetch data
  const fetchData = async (param) => {
    let searchData;
    if (param === "cancel") searchData = "";
    else searchData = search;
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      search: searchData,
      start: dateRange.from,
      end: dateRange.to,
      userId: userId,
    };

    UserService.getAllAppointments(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setAppointments(response.appointments[0].data);
        // setProfilephoto(
        //   response.profilePic
        //     ? `${Constants.BASE_URL}/user/${response.profilePic}`
        //     : `${"https://i.ibb.co/wynJtDH/avatar.png"}`
        // );
        if (response.appointments[0].metadata.length > 0) {
          // setTotal(response.appointments[0].metadata[0].total);
        } else {
          // setTotal(0);
        }
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong!", { variant });
      }
    });
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  //join call
  const handleJoinClick = (eventId) => {
    setLoading(true);
    UserService.joinAppointment(eventId, enqueueSnackbar).then((response) => {
      setLoading(false);
      if (!response.error && response.token && response.token.video_jwt_token) {
        // Video Token generated successfully
        // Redirect to video call screen
        setLoading(false);
        let data = JSON.stringify(response.token);
        navigate(`/meeting/${data}`);
      } else {
        // Handle cancellation error
        enqueueSnackbar("Failed to join appointment", {
          variant: "error",
        });
      }
    });
  };

  const formatAppointmentEvents = async () => {
    const appointmentEvents = appointments?.map((appointment, index) => {
      const start = new Date(appointment?.date);
      const end = new Date(
        start.getTime() + (appointment?.duration || 0) * 60000
      );

      return {
        start: start,
        end,
        date: appointment?.date,
        time: appointment?.time,
        title:
          appointment?.user?.firstName + " " + appointment?.user?.lastName ||
          "", // Use 'title' instead of 'name'
        id: appointment?._id,
        queries: appointment?.query || "", // Use 'appointment.query' for queries
        duration: appointment?.duration || "", // Use 'appointment.duration' for duration
        is_cancelled: appointment?.is_cancelled || "",
        therapistId: appointment?.therapist?._id,
        userId: appointment?.user?._id,
      };
    });
    setFormattedEvents(appointmentEvents); // Set the transformed events
  };

  //on appointment date changes
  const onAppointmentDateChange = (form, value, setFieldValue) => {
    let data = {
      date: moment(value.$d).format("YYYY-MM-DDTHH:mm:ss"),
      duration: selectedEvent.duration,
      therapistId: selectedEvent.therapistId,
    };

    UserService.getSlots(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setSlots(response?.slots);
        setFieldValue("time", response?.slots[0]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchData("paginationChange");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    formatAppointmentEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  const eventPropGetter = (event) => {
    if (event.is_cancelled) {
      return {
        style: {
          textDecoration: "line-through",
        },
      };
    }
    return {};
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ background: "#fff" }}>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
            style={{ padding: "20px" }}
          >
            Appointment
          </Typography>
          <Divider orientation="horizontal" />

          <Box className="App" sx={{ padding: "10px 20px 20px 20px" }}>
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={formattedEvents}
              style={{ height: "100vh" }}
              selectable={true}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectEvent}
              eventPropGetter={eventPropGetter}
            />
          </Box>
          {selectedEvent !== null && (
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="appointment-details-modal"
              aria-describedby="appointment-details-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
                onClick={handleContainerClick} // Handle click event on the modal container
              >
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Typography variant="h6" component="div" align="center">
                  Appointment Details
                </Typography>

                <Box sx={{ mt: 2 }}>
                  {/* <TableContainer>
              <Grid container>
                <Grid xs={12}>
                  <Card style={{ textAlign: "center" }}>
                    <CardContent style={{ paddingBottom: "0px" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        height={10}
                        component="div"
                        align="center"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          height: "auto",
                          fontWeight: "bold",
                        }}
                      ></Typography>
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={profilephoto}
                        style={{
                          objectFit: "contain",
                          padding: "10px",
                          margin: "auto",
                        }}
                      />
                      <Table ria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" style={style}>
                              Name
                            </TableCell>
                            <TableCell align="left" style={fontStyle}>
                              {appointments?.title === undefined ||
                              appointments?.title === " "
                                ? "N/A"
                                : appointments?.title}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" style={style}>
                              Duration
                            </TableCell>
                            <TableCell align="left" style={fontStyle}>
                              {appointments?.duration === undefined ||
                              appointments?.duration === " "
                                ? "N/A"
                                : appointments.duration}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" style={style}>
                              Date and Time
                            </TableCell>
                            <TableCell>
                              {moment(appointments?.date).format(
                                "MM-DD-YYYY HH:mm"
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" style={style}>
                              Queries and Concerns
                            </TableCell>
                            <TableCell>
                              {appointments?.query === undefined ||
                              appointments?.query === " "
                                ? "N/A"
                                : appointments?.query}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Button
                        style={{
                          width: 150,
                          marginLeft: "25px",
                          marginTop: "25px",
                          marginBottom: "25px",
                        }}
                        variant="contained"
                        size="medium"
                        type="submit"
                        className="submit-btn-text"
                      >
                        Reschedule
                      </Button>
                      <Button
                        style={{
                          width: 100,
                          marginLeft: "25px",
                          marginTop: "25px",
                          marginBottom: "25px",
                          color: "#fff",
                        }}
                        variant="outlined"
                        size="medium"
                        className="submit-btn-text"
                        onClick={() =>
                          handleCancelAppointment(selectedEvent.id)
                        }
                      >
                        Cancel
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TableContainer> */}
                  <Formik
                    initialValues={selectedEvent}
                    onSubmit={handleFormSubmit}
                  >
                    <Form>
                      <Box
                        sx={{
                          alignItems: "center",
                          "& > :not(style)": {},
                          width: "100%",
                        }}
                        style={{ alignSelf: "center" }}
                      >
                        <Field name="title">
                          {({ field }) => (
                            <TextField
                              type="text"
                              label="Name"
                              variant="outlined"
                              {...field}
                              disabled={true}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{
                                margin: "20px 0",
                                width: "100%",
                              }}
                            />
                          )}
                        </Field>
                        <Field name="duration">
                          {({ field }) => (
                            <TextField
                              type="text"
                              label="Duration(in minutes)"
                              variant="outlined"
                              {...field}
                              size="small"
                              disabled={true}
                              InputLabelProps={{ shrink: true }}
                              style={{
                                width: "100%",
                                marginBottom: "20px",
                              }}
                            />
                          )}
                        </Field>

                        <Field name="time">
                          {({ field, form }) => (
                            <FormControl
                              sx={{
                                marginBottom: "20px",
                                marginRight: "20px",
                                width: "100%",
                              }}
                              size="small"
                              error={form.errors.time && form.touched.time}
                            >
                              <InputLabel id="demo-select-small-label">
                                Slots
                              </InputLabel>
                              <Select
                                disabled={
                                  selectedEvent.currentTime >
                                    selectedEvent.startTime ||
                                  selectedEvent.is_cancelled === true
                                }
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                //value={field.value}
                                value={selectedEvent.time}
                                label="time"
                                {...field}
                                onChange={(event) => {
                                  form.setFieldValue(
                                    "time",
                                    event.target.value
                                  );
                                }}
                              >
                                {slots &&
                                  slots.map((slot, index) => {
                                    return (
                                      <MenuItem key={index} value={slot}>
                                        {slot}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              {form.errors.time && form.touched.time && (
                                <FormHelperText>
                                  {form.errors.time}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </Field>

                        <Field name="start">
                          {({ field, form }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disabled={
                                  selectedEvent.currentTime >
                                    selectedEvent.startTime ||
                                  selectedEvent.is_cancelled === true
                                }
                                {...field}
                                value={dayjs(field.value)}
                                label="Select Date"
                                onChange={(value) => {
                                  onAppointmentDateChange(
                                    field.name,
                                    value,
                                    form.setFieldValue
                                  );
                                  form.setFieldValue(
                                    field.name,
                                    moment(value.$d).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )
                                  );
                                }}
                                renderInput={(props) => (
                                  <TextField {...props} variant="outlined" />
                                )}
                                style={{
                                  margin: "20px 0",
                                  width: "100%",
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>

                        <Field name="queries">
                          {({ field }) => (
                            <TextField
                              multiline
                              rows={3}
                              type="text"
                              disabled={true}
                              label="Queries & Concerns"
                              variant="outlined"
                              {...field}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{
                                margin: "20px 0",
                                width: "100%",
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <Box style={{ alignSelf: "center" }}>
                        <Button
                          disabled={
                            selectedEvent.currentTime >
                              selectedEvent.startTime ||
                            selectedEvent.is_cancelled === true
                          }
                          style={{
                            width: 150,
                            marginLeft: "26px",
                            marginTop: "25px",
                            marginBottom: "25px",
                          }}
                          variant="contained"
                          size="medium"
                          type="submit"
                          className="submit-btn-text"
                        >
                          Reschedule
                        </Button>
                        <Button
                          disabled={
                            selectedEvent.currentTime >
                              selectedEvent.startTime ||
                            selectedEvent.is_cancelled === true
                          }
                          style={{
                            width: 100,
                            marginLeft: "13px",
                            marginRight: "13px",
                            marginTop: "25px",
                            marginBottom: "25px",
                            color: "#fff",
                          }}
                          variant="outlined"
                          size="medium"
                          className="submit-btn-text"
                          onClick={() =>
                            handleCancelAppointment(
                              selectedEvent.id,
                              selectedEvent.userId
                            )
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{
                            width: 100,
                            marginRight: "26px",
                            marginTop: "25px",
                            marginBottom: "25px",
                            color: "#fff",
                          }}
                          variant="outlined"
                          size="medium"
                          disabled={
                            disableJoinBtn || selectedEvent.is_cancelled == true
                          }
                          className="submit-btn-text"
                          onClick={() => handleJoinClick(selectedEvent.id)}
                        >
                          Join
                        </Button>
                      </Box>
                    </Form>
                  </Formik>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>
      )}
    </>
  );
};

export default Appointment;
