import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Courses from "../courses/courses";
import Appointment from "../appointments/appointment";
import "globalthis/auto";
import "./tab.css";
//test
const LabTabs = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            id="user_tab"
          >
            <Tab label="Course" value="1" style={{ fontWeight: 700 }} />
            <Tab label="Appointment" value="2" style={{ fontWeight: 700 }} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Courses />
        </TabPanel>
        <TabPanel value="2">
          <Appointment />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default LabTabs;
