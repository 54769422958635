import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import { Constants } from "../../shared/_helpers";
import { useSnackbar } from "notistack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  function handleClick() {
    setLoading(true);
    if (notifications.length < 10) {
      enqueueSnackbar("All Notifications Are Already Visible.", {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      setLoading(false);
    } else {
      if (notifications.length + 10 > allNotifications) {
        setNotifications(allNotifications.slice(0, allNotifications.length));
        setLoading(false);
      } else {
        setNotifications(allNotifications.slice(0, notifications.length + 10));
        setLoading(false);
      }
    }
  }
  let getNotifications = () => {
    let currentUser = localStorage.getItem("currentUser");
    currentUser = JSON.parse(currentUser);
    const token = currentUser?.id;
    let data = {
      userId: currentUser.userId,
    };
    axios
      .post(
        `${Constants.BASE_URL}/api/notifications/get-all?access_token=${token}`,
        data
      )
      .then((res) => {
        setAllNotifications(res.data.notifications[0].data);
        let totalData = res.data.notifications[0].data;
        if (totalData.length > 10) {
          setNotifications(totalData.slice(0, 10));
        } else {
          setNotifications(totalData);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error.message, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      });
  };
  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Capitalize = (str) => {
    return str.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : " " + d.toUpperCase()
    );
  };
  return (
    <>
      {notifications.length > 0 &&
        notifications.map((notification) => (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              padding: "1%",
              borderRadius: 2,
            }}
            style={{ marginBottom: 5 }}
          >
            <ListItem alignItems="flex-start" style={{ fontSize: "large" }}>
              <ListItemText
                primary={`${Capitalize(notification.type)}- ${Capitalize(
                  notification.title
                )}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body1"
                      color="text.primary"
                      style={{ fontSize: "large" }}
                    >
                      Description
                    </Typography>
                    {` — ${Capitalize(notification.body)} …`}
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "2%",
        }}
      >
        <div>
          <LoadingButton
            size="big"
            onClick={handleClick}
            loading={loading}
            variant="contained"
          >
            <span>Load More</span>
          </LoadingButton>
        </div>
        <div
          style={{
            backgroundColor: "#2962ff",
            height: "40px",
            lineHeight: "40px",
            width: "10%",
            borderRadius: 5,
            textAlign: "center",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              fontSize: "large",
              color: "white",
            }}
          >
            1 - {notifications.length}
          </span>
        </div>
      </div>
    </>
  );
}

export default Notification;
