import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field } from "formik";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { AuthenticationService } from "../../shared/_services/authentication.service";
import { useSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loginwcilogo from "../../assets/images/loginwcilogo.png";
import "./login.css";
import axios from "axios";
import { Constants } from "../../shared/_helpers";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Spinner from "../spinner-loader/spinner-loader";

const theme = createTheme();
const ResetPassword = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [viewCard, setViewCard] = useState(false);
  const [viewSpinner, setViewSpinner] = useState(true);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const auth = localStorage.getItem("currentUser");

  useEffect(() => {
    auth && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // validating user accesstoken
  useEffect(() => {
    const token = searchParams.get("access_token");
    let data = {
      accessToken: token,
    };
    axios
      .post(`${Constants.BASE_URL}/api/users/validate-token`, data)
      .then((res) => {
        if (res.data.status) {
          setViewCard(false);
          setViewSpinner(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error.statusCode) {
          setViewCard(true);
          setViewSpinner(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePassword = async (e) => {
    if (e.newPassword === e.confirmPassword) {
      setIsOpenBtn(true);
      const token = searchParams.get("access_token");
      AuthenticationService.resetPassword(e.newPassword, token).then((user) => {
        if (user.error) {
          let variant = "error";
          enqueueSnackbar(user.error.message, { variant });
          setIsOpenBtn(false);
        } else {
          let variant = "success";
          enqueueSnackbar("Password changed successfully!", { variant });
          setIsOpenBtn(false);
          navigate("/login");
        }
      });
    } else {
      let variant = "error";
      enqueueSnackbar("New password and Confirm password are not same!", {
        variant,
      });
      setIsOpenBtn(false);
    }
  };

  const validationSchema = Yup.object().shape({
    /* eslint-disable-next-line */
    newPassword: Yup.string()
      .required("New password is required!")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
    /* eslint-disable-next-line */
    confirmPassword: Yup.string()
      .required("Confirm password is required!")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
  });
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values) => {
    onChangePassword(values);
  };

  let handleClick = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      {viewSpinner && <Spinner />}
      {viewCard ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <Card
            sx={{
              display: "flex",
              width: "25%",
              padding: "10px",
              backgroundColor: "whitesmoke",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  Invalid Link
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Please try to resend reset link again!
                </Typography>
                <Button onClick={handleClick}>Click Here</Button>
              </CardContent>
            </Box>
          </Card>
        </div>
      ) : (
        <div className="login-page">
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow:
                    "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
                }}
              >
                <img
                  src={Loginwcilogo}
                  alt="Psychentheo Consult logo"
                  className="login-logo"
                  style={{ width: "50%", marginBottom: "20px" }}
                />

                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>

                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  method="post"
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Box sx={{ mt: 1 }}>
                        <Field name="newPassword">
                          {({ field }) => (
                            <TextField
                              sx={{
                                input: { color: "#000" },
                                fieldset: { borderColor: "#000" },
                                "& label": {
                                  color: "#000",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              fullWidth
                              type="password"
                              {...field}
                              id="newPassword"
                              label=" New Password"
                              name="newPassword"
                              size="small"
                              error={
                                errors.newPassword && touched.newPassword
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.newPassword && touched.newPassword
                                  ? `${errors.newPassword}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                        <Field name="confirmPassword">
                          {({ field }) => (
                            <TextField
                              sx={{
                                input: { color: "#000" },
                                fieldset: { borderColor: "#000" },
                                "& label": {
                                  color: "#000",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              fullWidth
                              type="password"
                              {...field}
                              id="confirmPassword"
                              label=" Confirm Password"
                              name="confirmPassword"
                              size="small"
                              error={
                                errors.confirmPassword &&
                                touched.confirmPassword
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.confirmPassword &&
                                touched.confirmPassword
                                  ? `${errors.confirmPassword}`
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isOpenBtn}
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        className="submit-btn-text"
                      >
                        Reset password
                      </Button>
                      <Grid container>
                        <Grid item xs style={{ textAlign: "end" }}>
                          <NavLink to="/login" variant="body2">
                            Back To Login
                          </NavLink>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      )}
    </>
  );
};
export default ResetPassword;
