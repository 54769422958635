import React, { useContext } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field } from "formik";

import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { AuthenticationService } from "../../shared/_services/authentication.service";
import { useSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Loginwcilogo from "../../assets/images/loginwcilogo.png";

import "./login.css";
import UserContext from "../../context/userContext";

const theme = createTheme();

const Login = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  // const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAuthentication = async ({ email, password, event }) => {
    setIsOpenBtn(true);

    try {
      const user = await AuthenticationService.login(email, password);

      if (user?.error?.statusCode === 401) {
        let variant = "error";
        enqueueSnackbar(user?.error?.message, { variant });
      } else {
        // setIsSubmitted(true);
        setIsOpenBtn(false);
      }
    } catch (error) {
      let variant = "error";
      enqueueSnackbar(
        "Your entered password or email did not get matched, please try again!",
        { variant }
      );
      setIsOpenBtn(false);
    }
  };

  const initialValues = {
    password: "",
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    password: Yup.string().required("Password is required!"),
  });
  const handleSubmit = (values, event) => {
    handleAuthentication(values);
  };

  const renderForm = (
    <div className="login-page">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow:
                "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            }}
          >
            <img
              src={Loginwcilogo}
              alt="logo"
              className="login-logo"
              style={{ width: "30%", marginBottom: "20px" }}
            />

            <Typography component="h1" variant="h5">
              Login
            </Typography>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              method="post"
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form noValidate>
                  <Box sx={{ mt: 1 }}>
                    <Field
                      name="email"
                      getOptionLabel={(option) => option.title}
                    >
                      {({ field }) => (
                        <TextField
                          sx={{
                            input: { color: "#000" },
                            fieldset: { borderColor: "#000" },
                            "& label": {
                              color: "#000",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="email"
                          {...field}
                          id="email"
                          label="Email Address"
                          name="email"
                          size="small"
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email
                              ? `${errors.email}`
                              : ""
                          }
                        />
                      )}
                    </Field>
                    <Field name="password">
                      {({ field }) => (
                        <TextField
                          sx={{
                            input: { color: "#000" },
                            fieldset: { borderColor: "#000" },
                            "& label": {
                              color: "#000",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          fullWidth
                          type="password"
                          {...field}
                          id="password"
                          label="Password"
                          name="password"
                          size="small"
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password && touched.password
                              ? `${errors.password}`
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </Box>

                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isOpenBtn}
                    className="submit-btn-text "
                  >
                    login
                  </Button>

                  <Grid container>
                    <Grid item xs style={{ textAlign: "end" }}>
                      <NavLink to="/forgot-password" variant="body2">
                        Forgot password?
                      </NavLink>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
  return <>{user ? navigate("/") : renderForm}</>;
};

export default Login;
