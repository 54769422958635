import IconButton from "@mui/material/IconButton";
import { AiOutlineCamera } from "react-icons/ai";

function UploadButton(props) {
  const { onChange } = props;

  return (
    <>
      <IconButton
        component="label"
        htmlFor="file-input"
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          color: "#fff",
          background: "#C07C26",
          height: "30px",
          width: "30px",
        }}
      >
        <AiOutlineCamera />
      </IconButton>

      <input
        id="file-input"
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={onChange}
      />
    </>
  );
}

export default UploadButton;
