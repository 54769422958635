import { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../context/userContext";

function PrivateRoute({ children }) {
  const user = useContext(UserContext);
  // console.log(user);

  return user !== null ? <>{children}</> : <Navigate to="/login" replace />;
}

export default PrivateRoute;
