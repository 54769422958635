import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Toolbar,
  Paper,
  Typography,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { RiDeleteBinLine } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import Spinner from "../../spinner-loader/spinner-loader";
import { Formik, Form, Field, FieldArray } from "formik";
import TabContext from "@mui/lab/TabContext";
import { Link } from "react-router-dom";
import { Constants } from "../../../shared/_helpers";
import UploadButton from "./uploadbutton";
import UploadVideoButton from "./video-upload-btn";
import { UserService } from "../../../shared/_services";

import { confirm } from "react-confirm-box";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const currentUser = localStorage.getItem("currentUser");
const user = JSON.parse(currentUser);
const token = user?.id;
const actionUrlProgram = `${Constants.BASE_URL}/api/Containers/program/upload?access_token=${token}`;

const EditCourse = () => {
  const [value] = useState("1");
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoUrlArr, setVideoUrlArr] = useState([]);
  const [progressArr, setProgressArr] = useState([]);
  const [media, setMedia] = useState([]);
  const [maxSizeError, setMaxSizeError] = useState("");

  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [topics, setTopics] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const Navigate = useNavigate();
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const initialValues = {
    courseTitle: "",
    topics: [],
    thumbnail: null,
    price: "",
    description: "",
    language: "",
    validity: "",
    media: [{ title: "", type: "", content: "" }],
  };

  //get specific course
  useEffect(() => {
    UserService.getCourseDetails(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        const { title, picture, description, topics, price, validity, media } =
          response.course[0];
        const topicIds = topics.map((item) => item._id);

        initialValues.courseTitle = title;
        initialValues.thumbnail = picture;
        initialValues.price = price;
        initialValues.topics = topicIds;
        initialValues.description = description;
        initialValues.language = response.course[0].language._id;
        initialValues.validity = validity;
        initialValues.media = media;
        setMedia(media);
        setThumbnailUrl(`${Constants.BASE_URL}/course/` + picture);
        media.forEach((item, index) => {
          if (item.type === "video") {
            videoUrlArr[index] =
              `${Constants.BASE_URL}/program/` + item.content;
            setVideoUrlArr(videoUrlArr);
          }
        });

        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get language
  useEffect(() => {
    UserService.getLanguages(enqueueSnackbar).then((response) => {
      setLoading();
      if (!response.error) {
        setLanguages(response);

        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //get topics
  useEffect(() => {
    UserService.getTopics(enqueueSnackbar).then((response) => {
      setLoading();

      if (!response.error) {
        setTopics(response);

        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Upload Thumbnail
  const uploadThumbnail = async (thumbnail) => {
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);

    const response = await fetch(actionUrlProgram, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }
    return data?.result?.files?.thumbnail[0]?.name;
  };

  //Upload Video
  const uploadVideo = async (video, index) => {
    try {
      const formData = new FormData();
      formData.append("video", video);

      const response = axios.post(actionUrlProgram, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progressArr[index] = percentage;
          setProgressArr(progressArr);
          setIsUploading(true);
        },
      });

      const result = await response;
      setIsUploading(false);
      return result?.data?.result?.files?.video[0]?.name; // Assuming your server returns the URL of the uploaded video
    } catch (error) {
      console.error("Error while uploading video", error);
      let variant = "error";
      enqueueSnackbar("Error while uploading video", { variant });

      throw error; // Rethrow the error if needed for further handling
    }
  };

  //edit course
  const onSubmit = async (values) => {
    if (isUploading) {
      let variant = "success";
      enqueueSnackbar("File is uploading. Please wait!", { variant });

      // options = false;
    }

    const result = await confirm("Do you want to update this Course?", options);

    if (result) {
      let data = {
        title: values.courseTitle,
        picture: values.thumbnailUrl,
        description: values.description,
        topics: values.topicsArray,
        languageId: values.language,
        price: values.price,
        validity: values.validity,
        media: values.media,
      };
      UserService.updateCourse(id, data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Course updated successfully!", { variant });
          Navigate("/course");
          // setIsSubmitting(false);
        }
      });
    }
  };
  const numRegExp = /^(0|[1-9]\d*)$/;
  const validationSchema = Yup.object().shape({
    courseTitle: Yup.string()
      .required("Title is required!")
      .max(50, "Max length is 50!"),
    thumbnail: Yup.string().required("Picture is required!"),
    description: Yup.string()
      .required("Description is required!")
      .max(250, "Max length is 250!"),
    topics: Yup.array()
      .of(Yup.string().required("Topic ID is required!"))
      .min(1, "Select at least one topic!"),
    language: Yup.string().required("Language is required!"),
    price: Yup.number()
      .required("Price is required!")
      .positive("Price must be a positive number!"),
    validity: Yup.string()
      .required("Validity is required!")
      .matches(numRegExp, "Validity is not valid!"),

    media: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Title is required!"),
        type: Yup.string().required("Type is required!"),
        content: Yup.string()
          .when("type", {
            is: "reading",
            then: () => Yup.string().required("Content is required!"),
          })
          .when("type", {
            is: "article",
            then: () =>
              Yup.string()
                .url("Invalid URL format!")
                .required("Content is required for website!"),
          }),
      })
    ),
  });

  // const renderForm = (selectedOption, index, errors, touched, values) => {
  //   switch (selectedOption) {
  //     case "video":
  //       return (
  //         <>
  //           <Paper
  //             sx={{
  //               margin: "auto",
  //               maxWidth: "100%",
  //               flexGrow: 1,
  //               display: "flex",
  //               alignmedia: "center",
  //             }}
  //             style={{
  //               height: 180,
  //               background: "#f2f2f2",
  //               border: "1px dashed",
  //             }}
  //           >
  //             <Grid
  //               container
  //               style={{
  //                 width: "100%",
  //                 alignItems: "center",
  //                 justifyContent: "center",
  //                 margin: "auto",
  //               }}
  //             >
  //               <Grid
  //                 item
  //                 md={12}
  //                 sm={12}
  //                 xs={12}
  //                 sx={{
  //                   display: "flex",
  //                   flexDirection: "column",
  //                   alignItems: "center",
  //                   justifyContent: "right",
  //                 }}
  //               >
  //                 <Field name={`media[${index}].content`}>
  //                   {({ field, form }) => (
  //                     <>
  //                       <div
  //                         style={{
  //                           height: "100px",
  //                           display: "flex",
  //                           alignItems: "center",
  //                         }}
  //                       >
  //                         {progressArr[index] > 0 &&
  //                           progressArr[index] < 100 && (
  //                             <div> {progressArr[index]}% Uploaded</div>
  //                           )}
  //                         {videoUrlArr[index] === "" &&
  //                           progressArr[index] === 0 && <h3>Upload Video</h3>}
  //                         {videoUrlArr[index] != "" && (
  //                           <a href={videoUrlArr[index]} target="_blank">
  //                             <div
  //                               style={{
  //                                 borderRadius: "8px",
  //                                 overflow: "hidden",
  //                                 marginBottom: "10px",
  //                               }}
  //                             >
  //                               <video
  //                                 width="180"
  //                                 height="90"
  //                                 style={{
  //                                   display: "block",
  //                                 }}
  //                               >
  //                                 <source
  //                                   src={videoUrlArr[index]}
  //                                   type="video/mp4"
  //                                 />
  //                               </video>
  //                             </div>
  //                           </a>
  //                         )}
  //                       </div>
  //                       <UploadVideoButton
  //                         index={index}
  //                         onChange={(event) => {
  //                           progressArr[index] = 0;
  //                           setProgressArr(progressArr);

  //                           videoUrlArr[index] = "";
  //                           setVideoUrlArr(videoUrlArr);

  //                           // Immediately upload the thumbnail and set the URL
  //                           const video = event.currentTarget.files[0];
  //                           form.setFieldTouched(
  //                             `media[${index}].content`,
  //                             true,
  //                             false
  //                           );
  //                           form.setFieldValue(
  //                             `media[${index}].content`,
  //                             video
  //                           );

  //                           uploadVideo(video, index)
  //                             .then((videoUrl) => {
  //                               form.setFieldError(
  //                                 `media[${index}].content`,
  //                                 ""
  //                               );
  //                               form.setFieldValue(
  //                                 `media[${index}].content`,
  //                                 videoUrl
  //                               );

  //                               progressArr[index] = 100;
  //                               setProgressArr(progressArr);

  //                               videoUrlArr[index] =
  //                                 Constants.BASE_URL + "/program/" + videoUrl;
  //                               setVideoUrlArr(videoUrlArr);
  //                             })
  //                             .catch((error) => {
  //                               // const inputElement = event.currentTarget;
  //                               // inputElement.value = null;

  //                               form.setFieldValue(
  //                                 `media[${index}].content`,
  //                                 null
  //                               );

  //                               console.error("Error uploading video:", error);
  //                             });
  //                         }}
  //                         onBlur={() => form.setFieldTouched("video")}
  //                       >
  //                         Browse
  //                       </UploadVideoButton>

  //                       <div>
  //                         {form.touched.media?.[index]?.content &&
  //                         form.errors.media?.[index]?.content ? (
  //                           <div className="error">
  //                             {form.errors.media?.[index]?.content}
  //                           </div>
  //                         ) : null}
  //                       </div>
  //                     </>
  //                   )}
  //                 </Field>
  //               </Grid>
  //             </Grid>
  //           </Paper>
  //         </>
  //       );
  //     case "article":
  //       return (
  //         <>
  //           <Box
  //             sx={{
  //               background: "#fff",
  //               padding: "0 20px 20px",
  //               borderRadius: "8px",
  //             }}
  //           >
  //             <Field name={`media[${index}].content`}>
  //               {({ field, form }) => (
  //                 <TextField
  //                   label="Link"
  //                   fullWidth
  //                   size="small"
  //                   display="flex"
  //                   //value={field.value}
  //                   {...field}
  //                   onChange={(event) => {
  //                     form.setFieldValue(
  //                       `media[${index}].content`,
  //                       event.target.value
  //                     );
  //                   }}
  //                   style={{
  //                     marginTop: "20px",
  //                   }}
  //                   error={
  //                     form.errors.media?.[index]?.content &&
  //                     form.touched.media?.[index]?.content
  //                   }
  //                   helperText={
  //                     form.errors.media?.[index]?.content &&
  //                     form.touched.media?.[index]?.content
  //                       ? `${form.errors.media[index].content}`
  //                       : ""
  //                   }
  //                 />
  //               )}
  //             </Field>
  //           </Box>
  //         </>
  //       );
  //     case "reading":
  //       return (
  //         <>
  //           <Field name={`media[${index}].content`}>
  //             {({ field, form }) => (
  //               <>
  //                 <Editor
  //                   apiKey={"3gkjod234u67pmp7il2g8z0n5gm85by629sgiyl2gk11q9o5"}
  //                   initialValue={values.media[index].content}
  //                   init={{
  //                     height: 200,
  //                     width: "100%",
  //                     borderRadius: "8px",
  //                     menubar: false,
  //                     branding: false,
  //                     plugins: [
  //                       `advlist autolink lists link image charmap print preview anchor`,
  //                       "searchreplace visualblocks code fullscreen",
  //                     ],

  //                     toolbar: `
  //                       formatselect |
  //                       bold italic underline strikethrough |
  //                       bullist numlist |
  //                       link image |
  //                       alignleft aligncenter alignright alignjustify |
  //                       fontsizeselect
  //                     `,
  //                   }}
  //                   onEditorChange={(content) => {
  //                     if (content.trim() == "") {
  //                       // setIsSubmitting(true);
  //                     } else {
  //                       // setIsSubmitting(false);
  //                     }
  //                     form.setFieldValue(`media[${index}].content`, content);

  //                     // setIsSubmitting(true);
  //                   }}
  //                   onBlur={() => form.setFieldTouched("description")}
  //                 />
  //                 {form.touched.media?.[index]?.content &&
  //                 form.errors.media?.[index]?.content ? (
  //                   <div className="error red" style={{ textAlign: "left" }}>
  //                     {form.errors.media?.[index]?.content}
  //                   </div>
  //                 ) : null}
  //               </>
  //             )}
  //           </Field>
  //         </>
  //       );
  //   }
  // };

  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2, background: "none", boxShadow: "none" }}>
        <Toolbar sx={{ background: "#fff" }}>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Edit Course
          </Typography>
          <Link to="/course">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <TabContext value={value}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, values, setFieldValue, touched, isSubmitting }) => (
                  <Grid
                    sx={{
                      margin: "auto",
                      width: "100%",
                      flexGrow: 1,
                    }}
                    style={{ height: "auto" }}
                  >
                    <Form
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        "& > :not(style)": {},
                      }}
                    >
                      <Box sx={{ background: "#fff", padding: "20px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  width: "100%",
                                  height: "160px",
                                  borderRadius: "8px",
                                  border: "none!important",
                                  marginTop: "20px",
                                  backgroundColor: "lightgray",
                                }}
                              >
                                {thumbnailUrl && (
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "8px",
                                      border: "none!important",
                                      objectFit: "cover",
                                    }}
                                    src={thumbnailUrl}
                                    alt=""
                                  />
                                )}
                              </div>

                              <Field name="thumbnail">
                                {({ field, form }) => (
                                  <>
                                    <div style={{ position: "relative" }}>
                                      <UploadButton
                                        onChange={(event) => {
                                          // Immediately upload the thumbnail and set the URL
                                          const thumbnail =
                                            event.currentTarget.files[0];
                                          form.setFieldValue(
                                            "thumbnail",
                                            thumbnail
                                          );

                                          uploadThumbnail(thumbnail)
                                            .then((thumbnailUrl) => {
                                              form.setFieldValue(
                                                "thumbnailUrl",
                                                thumbnailUrl
                                              );
                                              form.setFieldError(
                                                "thumbnail",
                                                ""
                                              );

                                              setThumbnailUrl(
                                                Constants.BASE_URL +
                                                  "/course/" +
                                                  thumbnailUrl
                                              );
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error uploading thumbnail:",
                                                error
                                              );
                                            });
                                        }}
                                      >
                                        Upload Thumbnail
                                      </UploadButton>
                                      {form.touched.thumbnail &&
                                      form.errors.thumbnail ? (
                                        <div
                                          className="error"
                                          style={{
                                            textAlign: "left",
                                            position: "absolute",
                                            top: "100%",
                                          }}
                                        >
                                          {form.errors.thumbnail}
                                        </div>
                                      ) : null}
                                    </div>
                                  </>
                                )}
                              </Field>
                            </div>
                          </Grid>
                          <Grid item xs={8}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <Field name="courseTitle">
                                {({ field }) => (
                                  <TextField
                                    label="Title"
                                    fullWidth
                                    size="small"
                                    rows={1}
                                    multiline
                                    display="flex"
                                    {...field}
                                    style={{ marginTop: "20px" }}
                                    error={
                                      errors.courseTitle && touched.courseTitle
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.courseTitle && touched.courseTitle
                                        ? `${errors.courseTitle}`
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <Field name="validity">
                                {({ field }) => (
                                  <TextField
                                    label="Validity (No. of Days)"
                                    fullWidth
                                    size="small"
                                    type="text"
                                    display="flex"
                                    {...field}
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "20px",
                                    }}
                                    error={
                                      errors.validity && touched.validity
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.validity && touched.validity
                                        ? `${errors.validity}`
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                              <Field name="price">
                                {({ field }) => (
                                  <TextField
                                    label="Price"
                                    fullWidth
                                    size="small"
                                    type="text"
                                    display="flex"
                                    {...field}
                                    style={{ marginTop: "20px" }}
                                    error={
                                      errors.price && touched.price
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.price && touched.price
                                        ? `${errors.price}`
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <Field name="language">
                                {({ field, form }) => (
                                  <FormControl
                                    sx={{
                                      marginTop: "20px",
                                      marginRight: "20px",
                                      width: "50%",
                                    }}
                                    size="small"
                                    error={
                                      form.errors.language &&
                                      form.touched.language
                                    }
                                  >
                                    <InputLabel id="demo-select-small-label">
                                      Language
                                    </InputLabel>
                                    <Select
                                      labelId="demo-select-small-label"
                                      id="demo-select-small"
                                      value={field.value}
                                      label="Language"
                                      {...field}
                                      onChange={(event) => {
                                        form.setFieldValue(
                                          "language",
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value="">
                                        <em>Select</em>
                                      </MenuItem>
                                      {languages &&
                                        languages.map((languages) => {
                                          return (
                                            <MenuItem
                                              key={languages.id}
                                              value={languages.id}
                                            >
                                              {languages.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    {form.errors.language &&
                                      form.touched.language && (
                                        <FormHelperText>
                                          {form.errors.language}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                )}
                              </Field>

                              <Field name="topics">
                                {({ field, form }) => (
                                  <FormControl
                                    sx={{
                                      marginTop: "20px",

                                      width: "50%",
                                    }}
                                    size="small"
                                    error={
                                      form.errors.topics && form.touched.topics
                                    }
                                  >
                                    <InputLabel id="demo-select-small-label">
                                      Topics
                                    </InputLabel>
                                    <Select
                                      labelId="demo1-select-small-label"
                                      id="demo1-select-small"
                                      value={field.value || []}
                                      label="Topics"
                                      {...field}
                                      multiple
                                      onChange={(event) => {
                                        form.setFieldValue(
                                          "topics",
                                          event.target.value
                                        );
                                        const selectedTopics =
                                          event.target.value.map((topicId) => ({
                                            topicId: topicId,
                                          }));
                                        form.setFieldValue(
                                          "topicsArray",
                                          selectedTopics
                                        );
                                      }}
                                    >
                                      {topics &&
                                        topics.map((topic) => {
                                          return (
                                            <MenuItem
                                              key={topic.id}
                                              value={topic.id}
                                            >
                                              {topic.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    {form.errors.topics &&
                                      form.touched.topics && (
                                        <FormHelperText>
                                          {form.errors.topics}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "& > :not(style)": {},
                          }}
                          style={{ alignSelf: "center", marginTop: "20px" }}
                        >
                          <Field name="description" as="textarea">
                            {({ field }) => (
                              <TextField
                                label="Description"
                                fullWidth
                                size="small"
                                {...field}
                                multiline
                                rows={3}
                                style={{ marginTop: "20px" }}
                                error={
                                  errors.description && touched.description
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.description && touched.description
                                    ? `${errors.description}`
                                    : ""
                                }
                              />
                            )}
                          </Field>
                        </Box>
                      </Box>

                      <Toolbar
                        style={{
                          padding: 0,
                          marginTop: "0px",
                          marginLeft: "20px",
                        }}
                      >
                        <Typography
                          sx={{ flex: "1 1 20%" }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                          className="header-text"
                        >
                          Course Material
                        </Typography>
                        {/* <Button
                          variant="contained"
                          className="submit-btn-text"
                          onClick={pushMedia}
                        >
                          Add New Material &nbsp;
                          <HiPlus />
                        </Button> */}
                      </Toolbar>
                      <Grid item xs={12}>
                        <Box>
                          <Field name="media">
                            {({ field }) => (
                              <FieldArray name="media">
                                {({ push, remove, field, form }) => (
                                  <div>
                                    {values.media.map((_, index) => (
                                      <div key={index}>
                                        <div
                                          key={index}
                                          style={{
                                            display: "flex",
                                            background: "#fff",
                                            padding: "0px 20px 20px",
                                          }}
                                        >
                                          <Field name={`media[${index}].title`}>
                                            {({ field, form }) => (
                                              <TextField
                                                label="Title"
                                                fullWidth
                                                size="small"
                                                type="text"
                                                display="flex"
                                                {...field}
                                                onChange={(event) => {
                                                  form.setFieldValue(
                                                    `media[${index}].title`,
                                                    event.target.value
                                                  );
                                                }}
                                                style={{
                                                  marginTop: "20px",
                                                  marginRight: "20px",
                                                }}
                                                error={
                                                  form.errors?.media?.[index]
                                                    ?.title &&
                                                  form.touched?.media?.[index]
                                                    ?.title
                                                }
                                                helperText={
                                                  form.errors?.media?.[index]
                                                    ?.title &&
                                                  form.touched?.media?.[index]
                                                    ?.title
                                                    ? form.errors?.media?.[
                                                        index
                                                      ]?.title
                                                    : ""
                                                }
                                              />
                                            )}
                                          </Field>
                                          <Field name={`media[${index}].type`}>
                                            {({ field, form }) => (
                                              <FormControl
                                                sx={{
                                                  marginTop: "20px",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  width: "100%",
                                                }}
                                                size="small"
                                              >
                                                <InputLabel
                                                  id={`media-${index}-label`}
                                                >
                                                  Media
                                                </InputLabel>
                                                <Select
                                                  labelId={`media-${index}-label`}
                                                  value={
                                                    values.media[index].type ||
                                                    ""
                                                  }
                                                  onChange={(event) => {
                                                    const selectedMediaType =
                                                      event.target.value;
                                                    setFieldValue(
                                                      `media[${index}].type`,
                                                      selectedMediaType
                                                    );

                                                    setFieldValue(
                                                      `media[${index}].content`,
                                                      ""
                                                    );

                                                    if (
                                                      selectedMediaType ===
                                                      "video"
                                                    ) {
                                                      videoUrlArr[index] = "";
                                                      setVideoUrlArr(
                                                        videoUrlArr
                                                      );
                                                    }
                                                  }}
                                                  error={
                                                    form.errors?.media?.[index]
                                                      ?.type &&
                                                    form.touched?.media?.[index]
                                                      ?.type
                                                  }
                                                  label="Media"
                                                >
                                                  <MenuItem value="video">
                                                    Video
                                                  </MenuItem>
                                                  <MenuItem value="article">
                                                    Article
                                                  </MenuItem>
                                                  <MenuItem value="reading">
                                                    Reading Material
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            )}
                                          </Field>
                                          {index > 0 && (
                                            <IconButton
                                              type="button"
                                              onClick={() => remove(index)}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: "20px",
                                                width: "50px",
                                                color: "red",
                                                fontSize: "20px",
                                                height: " 40px",
                                              }}
                                            >
                                              <RiDeleteBinLine />
                                            </IconButton>
                                          )}
                                        </div>
                                        <div
                                          style={{
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              width: "100%",
                                              marginBottom: "20px",
                                            }}
                                          >
                                            {values.media[index].type ===
                                              "video" && (
                                              <>
                                                <Paper
                                                  sx={{
                                                    margin: "auto",
                                                    maxWidth: "100%",
                                                    flexGrow: 1,
                                                    display: "flex",
                                                    alignmedia: "center",
                                                  }}
                                                  style={{
                                                    height: 180,
                                                    background: "#f2f2f2",
                                                    border: "1px dashed",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    style={{
                                                      width: "100%",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      margin: "auto",
                                                    }}
                                                  >
                                                    <Grid
                                                      item
                                                      md={12}
                                                      sm={12}
                                                      xs={12}
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "right",
                                                      }}
                                                    >
                                                      <Field
                                                        name={`media[${index}].content`}
                                                      >
                                                        {({ field, form }) => (
                                                          <>
                                                            <div
                                                              style={{
                                                                height: "100px",
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                              }}
                                                            >
                                                              {progressArr[
                                                                index
                                                              ] > 0 &&
                                                                progressArr[
                                                                  index
                                                                ] < 100 && (
                                                                  <div>
                                                                    {
                                                                      progressArr[
                                                                        index
                                                                      ]
                                                                    }
                                                                    % Uploaded
                                                                  </div>
                                                                )}
                                                              {videoUrlArr[
                                                                index
                                                              ] === "" &&
                                                                progressArr[
                                                                  index
                                                                ] === 0 && (
                                                                  <h3>
                                                                    Upload Video
                                                                  </h3>
                                                                )}
                                                              {videoUrlArr[
                                                                index
                                                              ] !== "" && (
                                                                <a
                                                                  href={
                                                                    videoUrlArr[
                                                                      index
                                                                    ]
                                                                  }
                                                                  target="_blank"
                                                                  rel="noreferrer"
                                                                >
                                                                  <div
                                                                    style={{
                                                                      borderRadius:
                                                                        "8px",
                                                                      overflow:
                                                                        "hidden",
                                                                      marginBottom:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    <video
                                                                      width="180"
                                                                      height="90"
                                                                      style={{
                                                                        display:
                                                                          "block",
                                                                      }}
                                                                    >
                                                                      <source
                                                                        src={
                                                                          videoUrlArr[
                                                                            index
                                                                          ]
                                                                        }
                                                                        type="video/mp4"
                                                                      />
                                                                    </video>
                                                                  </div>
                                                                </a>
                                                              )}
                                                            </div>
                                                            <UploadVideoButton
                                                              index={index}
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                progressArr[
                                                                  index
                                                                ] = 0;
                                                                setProgressArr(
                                                                  progressArr
                                                                );

                                                                videoUrlArr[
                                                                  index
                                                                ] = "";
                                                                setVideoUrlArr(
                                                                  videoUrlArr
                                                                );

                                                                // Immediately upload the thumbnail and set the URL
                                                                const video =
                                                                  event
                                                                    .currentTarget
                                                                    .files[0];
                                                                form.setFieldTouched(
                                                                  `media[${index}].content`,
                                                                  true,
                                                                  false
                                                                );
                                                                form.setFieldValue(
                                                                  `media[${index}].content`,
                                                                  video
                                                                );

                                                                uploadVideo(
                                                                  video,
                                                                  index
                                                                )
                                                                  .then(
                                                                    (
                                                                      videoUrl
                                                                    ) => {
                                                                      form.setFieldError(
                                                                        `media[${index}].content`,
                                                                        ""
                                                                      );
                                                                      form.setFieldValue(
                                                                        `media[${index}].content`,
                                                                        videoUrl
                                                                      );

                                                                      progressArr[
                                                                        index
                                                                      ] = 100;
                                                                      setProgressArr(
                                                                        progressArr
                                                                      );

                                                                      videoUrlArr[
                                                                        index
                                                                      ] =
                                                                        Constants.BASE_URL +
                                                                        "/program/" +
                                                                        videoUrl;
                                                                      setVideoUrlArr(
                                                                        videoUrlArr
                                                                      );
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (error) => {
                                                                      // const inputElement = event.currentTarget;
                                                                      // inputElement.value = null;
                                                                      setMaxSizeError(
                                                                        "Upload file less than 10MB"
                                                                      );
                                                                      setTimeout(
                                                                        () => {
                                                                          setMaxSizeError(
                                                                            ""
                                                                          );
                                                                        },
                                                                        2000
                                                                      );
                                                                      form.setFieldValue(
                                                                        `media[${index}].content`,
                                                                        ""
                                                                      );

                                                                      console.error(
                                                                        "Error uploading video:",
                                                                        error
                                                                      );
                                                                    }
                                                                  );
                                                              }}
                                                              onBlur={() =>
                                                                form.setFieldTouched(
                                                                  "video"
                                                                )
                                                              }
                                                            >
                                                              Browse
                                                            </UploadVideoButton>

                                                            <div>
                                                              {form.touched
                                                                .media?.[index]
                                                                ?.content &&
                                                              form.errors
                                                                .media?.[index]
                                                                ?.content ? (
                                                                <div className="error">
                                                                  {
                                                                    form.errors
                                                                      .media?.[
                                                                      index
                                                                    ]?.content
                                                                  }
                                                                </div>
                                                              ) : null}
                                                              {maxSizeError ? (
                                                                <div className="error">
                                                                  {maxSizeError}
                                                                </div>
                                                              ) : null}
                                                            </div>
                                                          </>
                                                        )}
                                                      </Field>
                                                    </Grid>
                                                  </Grid>
                                                </Paper>
                                              </>
                                            )}
                                            {values.media[index].type ===
                                              "article" && (
                                              <>
                                                <Box
                                                  sx={{
                                                    background: "#fff",
                                                    padding: "0 20px 20px",
                                                    borderRadius: "8px",
                                                  }}
                                                >
                                                  <Field
                                                    name={`media[${index}].content`}
                                                  >
                                                    {({ field, form }) => (
                                                      <TextField
                                                        label="Link"
                                                        fullWidth
                                                        size="small"
                                                        display="flex"
                                                        //value={field.value}
                                                        {...field}
                                                        onChange={(event) => {
                                                          form.setFieldValue(
                                                            `media[${index}].content`,
                                                            event.target.value
                                                          );
                                                        }}
                                                        style={{
                                                          marginTop: "20px",
                                                        }}
                                                        error={
                                                          form.errors.media?.[
                                                            index
                                                          ]?.content &&
                                                          form.touched.media?.[
                                                            index
                                                          ]?.content
                                                        }
                                                        helperText={
                                                          form.errors.media?.[
                                                            index
                                                          ]?.content &&
                                                          form.touched.media?.[
                                                            index
                                                          ]?.content
                                                            ? `${form.errors.media[index].content}`
                                                            : ""
                                                        }
                                                      />
                                                    )}
                                                  </Field>
                                                </Box>
                                              </>
                                            )}
                                            {values.media[index].type ===
                                              "reading" && (
                                              <>
                                                <Field
                                                  name={`media[${index}].content`}
                                                >
                                                  {({ field, form }) => (
                                                    <>
                                                      <CKEditor
                                                        id="editor"
                                                        editor={ClassicEditor}
                                                        data={
                                                          media[index]?.content
                                                        }
                                                        onReady={(editor) => {
                                                          // You can store the "editor" and use when it is needed.
                                                          // console.log("Editor is ready to use!", editor);
                                                        }}
                                                        onChange={(
                                                          event,
                                                          editor
                                                        ) => {
                                                          form.setFieldValue(
                                                            `media[${index}].content`,
                                                            editor.getData()
                                                          );
                                                        }}
                                                      />

                                                      {/* <Editor
                                                        apiKey={
                                                          "3gkjod234u67pmp7il2g8z0n5gm85by629sgiyl2gk11q9o5"
                                                        }
                                                        initialValue={
                                                          media[index].content
                                                        }
                                                        init={{
                                                          height: 200,
                                                          width: "100%",
                                                          borderRadius: "8px",

                                                          menubar: false,
                                                          branding: false,
                                                          plugins: [
                                                            `advlist autolink lists link charmap print preview anchor`,
                                                            "searchreplace visualblocks code fullscreen",
                                                          ],

                                                          toolbar: `
                                                            formatselect |
                                                            bold italic underline strikethrough |
                                                            bullist numlist |
                                                            link image |
                                                            alignleft aligncenter alignright alignjustify |
                                                            fontsizeselect
                                                          `,
                                                        }}
                                                        onEditorChange={(
                                                          content
                                                        ) => {
                                                          // if (
                                                          //   content.trim() == ""
                                                          // ) {
                                                          //   setIsSubmitting(
                                                          //     true
                                                          //   );
                                                          // } else {
                                                          //   setIsSubmitting(
                                                          //     false
                                                          //   );
                                                          // }
                                                          form.setFieldValue(
                                                            `media[${index}].content`,
                                                            content
                                                          );
                                                          // setIsSubmitting(true);
                                                        }}
                                                        onBlur={() =>
                                                          form.setFieldTouched(
                                                            "description"
                                                          )
                                                        }
                                                      /> */}
                                                      {form.touched.media?.[
                                                        index
                                                      ]?.content &&
                                                      form.errors.media?.[index]
                                                        ?.content ? (
                                                        <div
                                                          className="error red"
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {
                                                            form.errors.media?.[
                                                              index
                                                            ]?.content
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Field>
                                              </>
                                            )}
                                          </Box>
                                        </div>
                                      </div>
                                    ))}
                                    {/* <Button
                                      variant="contained"
                                      className="submit-btn-text"
                                      onClick={() => {
                                        push({
                                          title: "",
                                          type: "",
                                          content: "",
                                        });
                                      }}
                                    >
                                      Add New Material &nbsp;
                                      <HiPlus />
                                    </Button> */}
                                  </div>
                                )}
                              </FieldArray>
                            )}
                          </Field>
                        </Box>
                      </Grid>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link to="/course">
                          <Button
                            style={{
                              width: 100,

                              marginTop: "25px",
                              marginBottom: "25px",
                            }}
                            variant="outlined"
                            size="medium"
                            type="submit"
                            className="back-btn-text"
                          >
                            cancel
                          </Button>
                        </Link>
                        {/* <Link to="/course"> */}
                        <Button
                          style={{
                            width: 200,
                            marginTop: "25px",
                            marginBottom: "25px",
                          }}
                          variant="contained"
                          size="medium"
                          type="submit"
                          disabled={isSubmitting}
                          className="submit-btn-text"
                        >
                          Update
                        </Button>
                        {/* </Link> */}
                      </Box>
                    </Form>
                  </Grid>
                )}
              </Formik>
            </>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
};

export default EditCourse;
