import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Toolbar,
  Paper,
  Typography,
  Grid,
  TextField,  
  
} from "@mui/material";
import { useSnackbar } from "notistack";
// import Spinner from "../../spinner-loader/spinner-loader";
import Spinner from "../spinner-loader/spinner-loader";
import { Formik, Form, Field } from "formik";
import TabContext from "@mui/lab/TabContext";
import { Link } from "react-router-dom";
import { Constants } from "../../shared/_helpers";
// import { Constants } from "../../../shared/_helpers";
import UploadButton from "../course/add-course/uploadbutton";
// import UploadVideoButton from "./video-upload-btn";
// import { Editor } from "@tinymce/tinymce-react";
// import { UserService } from "../../../shared/_services";
import { UserService } from "../../shared/_services";
import { confirm } from "react-confirm-box";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
// import "./add-course.css";
const currentUser = localStorage.getItem("currentUser");
const user = JSON.parse(currentUser);
const token = user?.id;
const actionUrlEvent = `${Constants.BASE_URL}/api/Containers/event/upload?access_token=${token}`;

const AddEvent = () => {
  const [value] = useState("1");
  const [loading, setLoading] = useState(false);
  const [isUploading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [topics, setTopics] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [timeValue, setTimeValue] = useState(dayjs())

  const Navigate = useNavigate();
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const initialValues = {
    courseTitle: "",
    topics: [],
    thumbnail: null,
    price: "",
    description: "",
    language: "",
    validity: "",
    video: null,
    media: [{ title: "", type: "", content: "" }],
  };

  //get language
  useEffect(() => {
    console.log(isSubmitting, topics, languages)
    UserService.getLanguages(enqueueSnackbar).then((response) => {
      setLoading();

      if (!response.error) {
        setLanguages(response);

        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //get topics
  useEffect(() => {
    UserService.getTopics(enqueueSnackbar).then((response) => {
      setLoading();

      if (!response.error) {
        setTopics(response);

        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //Upload Thumbnail
  const uploadThumbnail = async (thumbnail) => {
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    const response = await fetch(actionUrlEvent, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }
    return data?.result?.files?.thumbnail[0]?.name; // Assuming your server returns the URL of the uploaded thumbnail
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    if (isUploading) {
      let variant = "success";
      enqueueSnackbar("File is uploading. Please wait!", { variant });
    }

    const result = await confirm("Do you want to add new Course?", options);

    if (result) {
      let data = {
        title: values.courseTitle,
        picture: values.thumbnailUrl,
        description: values.description,
        topics: values.topicsArray,
        languageId: values.language,
        price: values.price,
        validity: values.validity,
        media: values.media,
      };
      UserService.addCourse(data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Course added successfully!", { variant });
          setIsSubmitting(false);
          Navigate("/event"); 
        }
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Title is required!"),
    picture: Yup.string().required("Picture is required!"),
    description: Yup.string().required("Description is required!"),
    topics: Yup.array()
      .of(Yup.string().required("Topic ID is required!"))
      .min(1, "Select at least one topic!"),
    language: Yup.string().required("Language is required!"),
  });


  return (
    <Box sx={{}}>
      <Paper sx={{ mb: 2, background: "none", boxShadow: "none" }}>
        <Toolbar sx={{ background: "#fff" }}>
          <Typography
            sx={{ flex: "1 1 20%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Add Event
          </Typography>
          <Link to="/event">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <TabContext value={value}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ errors, values, setFieldValue, touched, isSubmitting }) => (
                  <Grid
                    sx={{
                      margin: "auto",
                      width: "100%",
                      flexGrow: 1,
                    }}
                    style={{ height: "auto" }}
                  >
                    <Form
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        "& > :not(style)": {},
                      }}
                    >
                      <Box sx={{ background: "#fff", padding: "20px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <div style={{ position: "relative" }}>
                              <div
                                style={{
                                  width: "100%",
                                  height: "160px",
                                  borderRadius: "8px",
                                  border: "none!important",
                                  marginTop: "20px",
                                  backgroundColor: "lightgray",
                                }}
                              >
                                {thumbnailUrl && <img style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "8px",
                                  border: "none!important",
                                  objectFit: "contain",
                                }}
                                  src={thumbnailUrl && thumbnailUrl}
                                  alt=""
                                />}
                              </div>

                              <Field name="picture">
                                {({ field, form }) => (
                                  <>
                                    <div style={{ position: "relative" }}>
                                      <UploadButton
                                        onChange={(event) => {
                                          // Immediately upload the thumbnail and set the URL
                                          const thumbnail =
                                            event.currentTarget.files[0];
                                          form.setFieldValue(
                                            "thumbnail",
                                            thumbnail
                                          );

                                          uploadThumbnail(thumbnail)
                                            .then((thumbnailUrl) => {
                                              form.setFieldValue(
                                                "thumbnailUrl",
                                                thumbnailUrl
                                              );
                                              form.setFieldError(
                                                "thumbnail",
                                                ""
                                              );

                                              setThumbnailUrl(
                                                Constants.BASE_URL +
                                                "/event/" +
                                                thumbnailUrl
                                              );
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error uploading thumbnail:",
                                                error
                                              );
                                            });
                                        }}
                                      >
                                        Upload Thumbnail
                                      </UploadButton>
                                      {form.touched.thumbnail &&
                                        form.errors.thumbnail ? (
                                        <div
                                          className="error"
                                          style={{
                                            textAlign: "left",
                                            position: "absolute",
                                            top: "100%",
                                          }}
                                        >
                                          {form.errors.thumbnail}
                                        </div>
                                      ) : null}
                                    </div>
                                  </>
                                )}
                              </Field>
                            </div>
                          </Grid>

                          <Grid item xs={8}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <Field name="name">
                                {({ field }) => (
                                  <TextField
                                    label="Name"
                                    fullWidth
                                    size="small"
                                    rows={1}
                                    multiline
                                    display="flex"
                                    {...field}
                                    style={{ marginTop: "20px" }}
                                    error={
                                      errors.name && touched.name
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.name && touched.name
                                        ? `${errors.name}`
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <div style={{ width: "95%", marginTop: "21px", marginRight: "5px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                  <DatePicker
                                    label="Date"
                                    slotProps={{ textField: { size: 'small' } }}
                                  />
                                </LocalizationProvider>
                              </div>
                              <div style={{ width: "95%", marginTop: "21px", marginRight: "5px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                    label="Time"
                                    value={timeValue}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newValue) => setTimeValue(newValue)}
                                  />
                                </LocalizationProvider>
                              </div>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                "& > :not(style)": {},
                              }}
                              style={{ alignSelf: "center" }}
                            >
                              <Field name="location">
                                {({ field }) => (
                                  <TextField
                                    label="Location"
                                    fullWidth
                                    size="small"
                                    type="text"
                                    display="flex"
                                    {...field}
                                    style={{ marginTop: "20px" }}
                                    error={
                                      errors.location && touched.location
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors.location && touched.location
                                        ? `${errors.location}`
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link to="/event">
                          <Button
                            style={{
                              width: 100,

                              marginTop: "25px",
                              marginBottom: "25px",
                            }}
                            variant="outlined"
                            size="medium"
                            type="submit"
                            className="back-btn-text"
                          >
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          style={{
                            width: 200,
                            marginTop: "25px",
                            marginBottom: "25px",
                          }}
                          variant="contained"
                          size="medium"
                          type="submit"
                          disabled={isSubmitting}
                          className="submit-btn-text"
                        >
                          Save Event
                        </Button>
                      </Box>
                    </Form>
                  </Grid>
                )}
              </Formik>
            </>
          )}
        </TabContext>
      </Paper>
    </Box>
  );
};

export default AddEvent;
