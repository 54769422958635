import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../shared/_services";
import { useSnackbar } from "notistack";
import { confirm } from "react-confirm-box";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Spinner from "../spinner-loader/spinner-loader";
import DeleteIcon from "@mui/icons-material/Delete";
import HealthAndWellnessTableHead from "./health-wellness-table-head/health.wellness-table-head";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Constants } from "../../shared/_helpers";

import "globalthis/auto";
import "core-js/actual";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (orderBy === "video") {
    a = a["title"]["title"].toLowerCase();
    b = b["title"]["title"].toLowerCase();
    return a.localeCompare(b) * 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const UserTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteTost, setDeleteTost] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData("paginationChange");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDeleteTost(false);
  };

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  //get all data
  const fetchData = (param) => {
    let searchData;
    if (param === "cancel") searchData = "";
    else searchData = search;
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      search: searchData,
    };
    UserService.getAllPrograms(data, enqueueSnackbar)
      .then((response) => {
        if (!response.error) {
          setPrograms(response.programs[0].data);

          if (response.programs[0].metadata.length > 0) {
            setTotal(response.programs[0].metadata[0].total);
          } else {
            setTotal(0);
          }
          setLoading(false);
        } else if (response.error.statusCode === 400) {
          let variant = "error";
          enqueueSnackbar("Something went wrong", { variant });
        }
      })
      .catch((error) => console.log(error));
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  const deleteProgram = async (userId, enqueueSnackbar) => {
    const result = await confirm(
      "Do you want to delete this Health & Wellness?",
      options
    );
    if (result) {
      UserService.deleteProgram(userId, enqueueSnackbar).then((response) => {
        if (!response.error) {
          fetchData("paginationChange");
          setDeleteTost(true);
          return;
        } else if (response.error.statusCode === 400) {
          let variant = "error";
          enqueueSnackbar("Something went wrong", { variant });
        }
      });
    }
  };
  // SEARCH CODES
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("cancel");
    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = programs.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setIsSearchShown(false);
    } else {
      setIsSearchShown(true);
    }
    setSearch(e.target.value);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Health And Wellness
          </Typography>

          <Typography sx={{ flex: "1 1 5%", mx: "auto" }} component="div">
            <Box
              component="form"
              sx={{ "& > :not(style)": {} }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  marginRight: "30px",
                  display: "flex",
                  alignItems: "center",
                  width: 250,
                  float: "right",
                }}
              >
                <InputBase
                  value={search}
                  onInput={onInputChange}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
          <Typography component="div">
            <Stack sx={{ flex: "1 1 30%" }} spacing={2} direction="row">
              <Link to="/health-and-wellness/add-video">
                <Button variant="contained" className="submit-btn-text">
                  ADD
                </Button>
              </Link>
            </Stack>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <TableContainer style={{ overflowX: "auto" }}>
          {loading ? (
            <Spinner />
          ) : (
            <Table
              sx={{
                minWidth: 750,
              }}
              style={{ tableLayout: "fixed" }}
              aria-labelledby="tableTitle"
            >
              <HealthAndWellnessTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={programs.length}
                key={programs._id}
              />

              {programs.length === 0 ? (
                <TableBody>
                  <TableRow style={{ textAlign: "center" }}>
                    <TableCell colSpan={3}>
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        Data not found
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                stableSort(programs, getComparator(order, orderBy)).map(
                  (program, index) => {
                    const isItemSelected = isSelected(program._id);
                    // const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableBody>
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={program._id}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <img
                              src={`${Constants.BASE_URL}/program/${program.thumbnail}`}
                              alt="thumbnail"
                              style={{
                                width: "20%",
                                height: "50px",
                                borderRadius: "8px",
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ width: "50px" }}>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${Constants.BASE_URL}/program/${program.video}`}
                            >
                              {toTitleCase(program.title) || "N/A"}
                            </a>
                          </TableCell>

                          <TableCell>
                            <Link
                              as={Link}
                              onClick={() => deleteProgram(program._id)}
                            >
                              <DeleteIcon style={{ color: "#ff0000" }} />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  }
                )
              )}
            </Table>
          )}
        </TableContainer>
        <TablePagination
          className="p"
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar open={deleteTost} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Health & Wellness deleted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default UserTable;
