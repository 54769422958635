import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../shared/_services";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Spinner from "../spinner-loader/spinner-loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EventTableHead from "./Event-table-head/EventTableHead";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "globalthis/auto";
import "core-js/actual";
import moment from "moment";
import { Constants } from "../../shared/_helpers";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    if (orderBy === "event") {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        return a.localeCompare(b) * 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const Event = () => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [selected, setSelected] = useState([]);
    const [events, setEvents] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteTost, setDeleteTost] = useState(false);
    const [isSearchShown, setIsSearchShown] = useState(false);
    const [loading, setLoading] = useState(true);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [currentUID, setCurrentUID] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const open = Boolean(anchorEl2);

    const handleDropDownClick = (e, eventId) => {
        setCurrentUID(eventId);
        return setAnchorEl2(e.currentTarget);
    };

    const handleDropDownClose = () => {
        setAnchorEl2(null);
    };
    const style = {
        color: "#000",
        cursor: "pointer",
    };

    const titleStyle = {
        marginTop: "6px",
        color: "#000",
    };

    useEffect(() => {
        fetchData("paginationChange");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const deleteToast = () => {
        setDeleteTost(true);
    };

    const handleClose = ( reason) => {
        if (reason === "clickaway") {
            return;
        }
        setDeleteTost(false);
    };

    const options = {
        labels: {
            confirmable: "Yes",
            cancellable: "No",
        },
    };

    const fetchData = (param) => {
        let data = {
            page: page + 1,
            limit: rowsPerPage
        };
        UserService.getAllEvents(data, enqueueSnackbar).then((response) => {
            if (!response.error) {
                console.log(response);
                setEvents(response?.events[0]?.data);

                if (response?.events[0]?.metadata.length > 0) {
                    setTotal(response?.events[0]?.metadata[0].total);
                } else {
                    setTotal(0);
                }
                setLoading(false);
            } else if (response.error.statusCode === 400) {
                let variant = "error";
                enqueueSnackbar("Something went wrong!", { variant });
            }
        });
        if (param !== "paginationChange") {
            setPage(0);
        }
    };
    //delete therapist
    const onDeleteCourse = async (id, enqueueSnackbar) => {
        const result = await confirm("Do you want to Delete this Course?", options);
        if (result) {
            UserService.deleteCourse(id, enqueueSnackbar).then((response) => {
                deleteToast(true);
                fetchData("paginationChange");
            });
        }
    };
    // SEARCH CODES
    const handleSearch = (event) => {
        fetchData();
        event.preventDefault();
    };

    const handleCancel = (event) => {
        setSearch("");
        fetchData("cancel");
        event.preventDefault();
        setIsSearchShown(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = events.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const onInputChange = (e) => {
        if (e.target.value === "") {
            setIsSearchShown(false);
        } else {
            setIsSearchShown(true);
        }
        setSearch(e.target.value);
    };

    function toTitleCase(str) {
        if (str) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <Toolbar className="table_name">
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className="header-text"
                    >
                        Events
                    </Typography>

                    <Typography sx={{ flex: "1 1 5%" }} component="div">
                        <Box
                            component="form"
                            sx={{
                                "& > :not(style)": { m: 1 },
                                float: "right",
                                display: "flex",
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Paper
                                component="div"
                                sx={{
                                    p: "2px 4px",
                                    display: "flex",
                                    width: 250,
                                    alignItems: "center",
                                }}
                            >
                                <InputBase
                                    value={search}
                                    onInput={onInputChange}
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "search" }}
                                />
                                <>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton
                                        type="submit"
                                        sx={{ p: "10px" }}
                                        aria-label="search"
                                        onClick={handleSearch}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </>
                                {isSearchShown && (
                                    <>
                                        <Divider
                                            sx={{ height: 28, m: 0.5 }}
                                            orientation="vertical"
                                        />
                                        <IconButton
                                            color="primary"
                                            sx={{ p: "10px" }}
                                            onClick={handleCancel}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </>
                                )}
                            </Paper>
                        </Box>
                    </Typography>
                    <Typography component="div" style={{ marginLeft: "20px" }}>
                        <Stack sx={{ flex: "1 1 30%" }} spacing={2} direction="row">
                            <Link to="/event/add-event">
                                <Button variant="contained" className="submit-btn-text">
                                    ADD
                                </Button>
                            </Link>
                        </Stack>
                    </Typography>
                </Toolbar>
                <Divider orientation="horizontal" />
                <TableContainer>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                            <EventTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={events.length}
                                key={events._id}
                            />

                            {events.length === 0 ? (
                                <TableBody>
                                    <TableRow style={{ textAlign: "center" }}>
                                        <TableCell colSpan={5}>
                                            <div style={{ textAlign: "center", fontWeight: "600" }}>
                                                Data not found
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                stableSort(events, getComparator(order, orderBy)).map(
                                    (eventData, index) => {
                                        const isItemSelected = isSelected(eventData._id);

                                        return (
                                            <TableBody>
                                                <TableRow
                                                    hover
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={eventData._id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell
                                                        component="td"
                                                        id={eventData._id}
                                                        scope="row"
                                                        padding="none"
                                                        className="wrap-name"
                                                        style={{
                                                            paddingLeft: "25px",
                                                            maxWidth: "100px",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        <img style={{ height: "20%", width: "70%", objectFit: "contain" }} src={`${Constants.BASE_URL}/event/${eventData.picture}`} alt="img" />

                                                    </TableCell>
                                                    <TableCell>
                                                        {eventData?.name === "" ||
                                                            eventData?.name === "undefined"
                                                            ? "N/A"
                                                            : toTitleCase(eventData?.name)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {eventData?.date === "" ||
                                                            eventData?.date === "undefined"
                                                            ? "N/A"
                                                            : moment(eventData.date).format('L')}
                                                    </TableCell>

                                                    <TableCell>
                                                        {eventData?.time ? moment(eventData.time, "HH:mm").format('hh:mm A') : "N/A"}
                                                    </TableCell>

                                                    <TableCell>
                                                        {eventData?.location && eventData.location.length > 0
                                                            ? eventData.location
                                                                .slice(0, 2)
                                                                .map((data, index) => (
                                                                    <div key={index}>
                                                                        <span>{toTitleCase(data)}</span>
                                                                        {index < 1 && index !== eventData.location.length - 1 && <span>, </span>}
                                                                    </div>
                                                                ))
                                                            : "N/A"}
                                                        {eventData?.location && eventData.location.length > 2 && (
                                                            <span>, ...</span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{ verticalAlign: "middle" }}
                                                    >
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={open ? "long-menu" : undefined}
                                                            aria-expanded={open ? "true" : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) =>
                                                                handleDropDownClick(e,eventData._id)
                                                            }
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <StyledMenu
                                                            id="demo-customized-menu"
                                                            MenuListProps={{
                                                                "aria-labelledby": "demo-customized-button",
                                                            }}
                                                            anchorEl={eventData._id === currentUID && anchorEl2}
                                                            open={eventData._id === currentUID && open}
                                                            onClose={handleDropDownClose}
                                                        >
                                                            <Divider sx={{ my: 0.5 }} />
                                                            <Link as={Link} to={`/event/${eventData._id}/edit`}>
                                                                <MenuItem
                                                                    onClick={handleDropDownClose}
                                                                    disableRipple
                                                                >
                                                                    <EditIcon style={style} />
                                                                    <h5 style={titleStyle}>Edit</h5>
                                                                </MenuItem>
                                                            </Link>
                                                            <Divider sx={{ my: 0.5 }} />
                                                            <div onClick={(e) => onDeleteCourse(eventData._id)}>
                                                                <MenuItem
                                                                    onClick={handleDropDownClose}
                                                                    disableRipple
                                                                >
                                                                    <DeleteIcon style={{ color: "red" }} />
                                                                    <h5 style={titleStyle}>Delete</h5>
                                                                </MenuItem>
                                                            </div>
                                                        </StyledMenu>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        );
                                    }
                                )
                            )}
                        </Table>
                    )}
                </TableContainer>
                <TablePagination
                    className="p"
                    component="div"
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Rows per page"
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Snackbar open={deleteTost} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    Event cancelled successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default Event;
