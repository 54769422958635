import * as React from "react";
import "core-js/actual";
import "globalthis/auto";
import { useState, useEffect } from "react";
import { UserService } from "../../../../shared/_services";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
// import Spinner from "../../../spinner-loader/spinner-loader";
import AppointmentUserTableHead from "../../TableHead/appointment-user-table-head/appointment-user-table-head";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./appointment.css";
import { Constants } from "../../../../shared/_helpers";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (orderBy === "company") {
    a = a["company"]["name"].toLowerCase();
    b = b["company"]["name"].toLowerCase();
    return a.localeCompare(b) * 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Appointment = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSearchShown, setIsSearchShown] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [nodeTypeFilter, setNodeTypeFilter] = useState({});
  const { userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetchData("paginationChange");
    return () => {
      setAppointments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchData = (searchInput, param) => {
    let searchData;
    if (searchInput === "cancel") searchData = "";
    else searchData = search;
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      search: searchData,
      userId: userId,
    };
    // Logic for checking appointment status
    if (param) {
      if (Object.keys(param)?.length === 2) {
        if (!param["past"].isChecked && param["upcoming"].isChecked) {
          data = { ...data, appointmentStatus: "upcoming" };
        } else if (!param["upcoming"].isChecked && param["past"].isChecked) {
          data = { ...data, appointmentStatus: "past" };
        } else {
          delete data.appointmentStatus;
        }
      } else {
        Object.keys(param).forEach((item) => {
          if (param[item].isChecked)
            data = { ...data, appointmentStatus: param[item].value };
        });
      }
    }

    UserService.getAllAppointments(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setAppointments(response.appointments[0].data);
        if (response.appointments[0].metadata.length > 0) {
          setTotal(response.appointments[0].metadata[0].total);
        } else {
          setTotal(0);
        }
        // setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went worng", { variant });
      }
    });
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  // SEARCH CODES
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("cancel");
    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = appointments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setIsSearchShown(false);
    } else {
      setIsSearchShown(true);
    }
    setSearch(e.target.value);
  };
  const [valueList, setValueList] = useState([
    { name: "Upcoming", value: "upcoming", isChecked: false },
    { name: "Past", value: "past", isChecked: false },
  ]);

  const handleChangeFilter = (e) => {
    const { checked, value } = e.target;
    let tempUser = valueList.map((item) => {
      if (item.value === value) {
        item = { ...item, isChecked: checked };
        nodeTypeFilter[item.value] = item;
        setNodeTypeFilter(nodeTypeFilter);
        return item;
      }
      return item;
    });
    setValueList(tempUser);
  };

  const applyFilter = () => {
    fetchData(search, nodeTypeFilter);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 30%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ fontWeight: 700 }}
          >
            Appointments
          </Typography>
          <Typography
            sx={{ flex: "1 1", zIndex: 1 }}
            component="div"
          ></Typography>
          <Typography component="div">
            <PopupState variant="popper" popupId="demo-popup-popper">
              {(popupState) => (
                <div>
                  <IconButton variant="contained" {...bindToggle(popupState)}>
                    <FilterAltIcon
                      style={{ color: "#243864", cursor: "pointer" }}
                    />
                  </IconButton>
                  <Popper {...bindPopper(popupState)} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Typography sx={{ p: 2 }} component={"div"}>
                            {valueList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  style={{ padding: "0px" }}
                                >
                                  <Checkbox
                                    checked={item?.isChecked || false}
                                    value={item.value}
                                    onChange={handleChangeFilter}
                                  />{" "}
                                  {item.name}{" "}
                                </MenuItem>
                              );
                            })}
                            <div style={{ display: "grid" }}>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={applyFilter}
                              >
                                Apply
                              </Button>
                            </div>
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </div>
              )}
            </PopupState>
          </Typography>
          <Typography sx={{ flex: "1 1 5%" }} component="div">
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1 } }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 250,
                }}
              >
                <InputBase
                  value={search}
                  onInput={onInputChange}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <TableContainer>
          {/* {loading ? (
            <Spinner />
          ) : ( */}
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <AppointmentUserTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={appointments.length > 0 ? appointments.length : 0}
              key={appointments._id}
            />
            <TableBody>
              {appointments &&
                stableSort(appointments, getComparator(order, orderBy)).map(
                  (user, index) => {
                    const isItemSelected = isSelected(user._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={user._id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="wrap-name"
                          style={{
                            paddingLeft: "25px",
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              height: "5%",
                              width: "30%",
                              objectFit: "contain",
                            }}
                            src={
                              user.profilePic
                                ? `${Constants.BASE_URL}/user/${user.profilePic}`
                                : `${Constants.BASE_URL}/user/default.png`
                            }
                            alt=""
                          />
                        </TableCell>
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="wrap-name"
                          style={{
                            paddingLeft: "25px",
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {`${user.user.firstName} ${user.user.lastName}`}
                        </TableCell>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            paddingLeft: 26,
                            textTransform: "capitalize",
                          }}
                        >
                          {user.duration}
                        </TableCell>
                        {/* <TableCell>
                            {moment(user.createdAt).format("MM-DD-YYYY")}
                          </TableCell> */}
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            paddingLeft: 26,
                            textTransform: "capitalize",
                          }}
                        >
                          {moment(user.date).format("L")}
                        </TableCell>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            paddingLeft: 26,
                            textTransform: "capitalize",
                          }}
                        >
                          {moment(user.date).format("LT")}
                        </TableCell>
                        <TableCell
                          style={{
                            verticalAlign: "top",
                            paddingLeft: 26,
                            textTransform: "capitalize",
                          }}
                        >
                          {user.status ? "Upcoming" : "Past"}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
          {/* )} */}
        </TableContainer>
        <TablePagination
          className="p"
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
export default Appointment;
