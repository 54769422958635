import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import Spinner from "../spinner-loader/spinner-loader";
import { confirm } from "react-confirm-box";
import { Formik, Form, Field } from "formik";
import { Button, Typography, Box, Paper, Toolbar } from "@mui/material";
import * as Yup from "yup";
import { UserService } from "../../shared/_services";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AboutUs({ aboutUsContent, onSave }) {
  const [loading, setLoading] = useState(true);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [id, setId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  const initialValues = {
    description: "",
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().trim().required("Description is required!"),
  });

  const handleSubmit = async (values) => {
    const result = await confirm("Do you want to update about us?", options);
    if (result) {
      let data = {
        description: descriptionContent
          ? descriptionContent
          : values.description,
        title: "about",
        id: id,
      };

      UserService.updateAbout(id, data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("About Us details updated successfully!", {
            variant,
          });
        }
      });
    }
  };

  useEffect(() => {
    UserService.getAboutUs(enqueueSnackbar).then((response) => {
      setLoading();

      if (!response.error && response[0]) {
        const { description } = response[0];
        initialValues.description = response[0].description;
        setDescriptionContent(response[0].description);
        setDescriptionValue(response[0].description);
        setId(response[0].id);

        initialValues.description = description;
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading ? (
    <Spinner />
  ) : (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            About Us
          </Typography>
        </Toolbar>
        {descriptionValue.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontWeight: "600",
              paddingBottom: "20px",
            }}
          >
            Data not found
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              values,
              touched,
              setValues,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form style={{ padding: "0 20px" }}>
                <Field name="description">
                  {({ field, form }) => (
                    <>
                      <CKEditor
                        id="editor"
                        editor={ClassicEditor}
                        data={descriptionValue}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          setDescriptionContent(editor.getData());
                        }}
                      />
                      {form.touched.description && form.errors.description ? (
                        <div
                          className="error red"
                          style={{ textAlign: "left" }}
                        >
                          {form.errors.description}
                        </div>
                      ) : null}
                    </>
                  )}
                </Field>

                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn-text"
                  disabled={isSubmitting}
                  style={{
                    width: 100,
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Paper>
    </Box>
  );
}

export default AboutUs;
