import * as React from "react";
import { useState, useEffect } from "react";

import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { UserService } from "../../../../shared/_services";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";

import Spinner from "../../../spinner-loader/spinner-loader";
import CoursesUserTableHead from "../../TableHead/courses-user-table-head/courses-user-table-head";

import { Constants } from "../../../../shared/_helpers";
import "globalthis/auto";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./courses.css";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const initialValue = {
  from: "",
  to: "",
};

const Courses = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateRange] = useState(initialValue);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useParams();

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.to]);

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    fetchData(rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.to]);

  const fetchData = (param) => {
    let searchData;
    if (param === "cancel") searchData = "";
    else searchData = search;
    let data = {
      userId: userId,
      searchQuery: searchData,
    };
    setLoading(false);
    UserService.getCourses(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setCourses(response.course_enrollment[0].data);
        if (response.course_enrollment[0].metadata.length > 0) {
          setTotal(response.course_enrollment[0].metadata[0].total);
        } else {
          setTotal(0);
        }
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went worng", { variant });
      }
    });
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  // SEARCH CODES
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("cancel");
    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = courses.map((n) => n.courseName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onInputChnage = (e) => {
    if (e.target.value === "") {
      setIsSearchShown(false);
    } else {
      setIsSearchShown(true);
    }
    setSearch(e.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 30%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ fontWeight: 700 }}
          >
            Courses
          </Typography>
          <Typography sx={{ flex: "1 1", zIndex: 1 }} component="div">
            <Box
              component="form"
              sx={{ "& > :not(style)": {} }}
              noValidate
              autoComplete="off"
            ></Box>
          </Typography>

          <Typography sx={{ flex: "1 1 5%" }} component="div">
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1 } }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 250,
                }}
              >
                <InputBase
                  value={search}
                  onInput={onInputChnage}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <TableContainer>
          {loading ? (
            <Spinner />
          ) : (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <CoursesUserTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={courses.length}
                key={courses._id}
              />
              <TableBody>
                {courses &&
                  stableSort(courses, getComparator(order, orderBy)).map(
                    (course, index) => {
                      const isItemSelected = isSelected(course._id);

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={course._id}
                          selected={isItemSelected}
                        >
                          <TableCell style={{ paddingLeft: 22 }}>
                            <img
                              style={{
                                height: "5%",
                                width: "13%",
                                objectFit: "contain",
                              }}
                              src={
                                course?.course.picture
                                  ? `${Constants.BASE_URL}/course/${course?.course.picture}`
                                  : `${Constants.BASE_URL}/course/default.png`
                              }
                              alt=""
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              paddingLeft: 22,
                            }}
                          >
                            {course.course?.title}
                          </TableCell>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              paddingLeft: 22,
                            }}
                          >
                            {course.course?.description}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 22 }}>
                            {moment(course.start_date).format("L")}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 22 }}>
                            {moment(course.end_date).format("L")}
                          </TableCell>
                          <TableCell style={{ paddingLeft: 22 }}>
                            {course?.validity}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          className="p"
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
export default Courses;
