// import React from "react";

// const Dashboard = () => {
//   return (
//     <>
//       {/* <h2>dashboard</h2> */}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "77vh",
//         }}
//       >
//         <h1>Coming soon</h1>
//       </div>
//     </>
//   );
// };
// export default Dashboard;

import React, { useState, useEffect } from "react";
import { UserService } from "../../shared/_services";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./dashboard.css";
const Dashboard = () => {
  const [therapistCount, setTherapistCount] = useState("");
  const [userCount, setUserCount] = useState("");
  const [coursesCount, setCoursesCount] = useState("");
  const [userAppointmentCount, setUserAppointmentCount] = useState("");
  const [therapistAppointmentCount, setTherapistAppointmentCount] =
    useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    UserService.getUserCount(enqueueSnackbar).then((response) => {
      console.log(response);
      if (!response.error) {
        setUserCount(response.count);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    UserService.getTherapistCount(enqueueSnackbar).then((response) => {
      console.log(response);
      if (!response.error) {
        setTherapistCount(response.count);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    UserService.getCoursesCount(enqueueSnackbar).then((response) => {
      console.log(response);
      if (!response.error) {
        setCoursesCount(response.count);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    UserService.getUserAppointmentCount(enqueueSnackbar).then((response) => {
      console.log(response);
      if (!response.error) {
        setUserAppointmentCount(response.count);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    UserService.getTherapistAppointmentCount(enqueueSnackbar).then(
      (response) => {
        console.log(response);
        if (!response.error) {
          setTherapistAppointmentCount(response.count);
        } else if (response.error.statusCode === 400) {
          let variant = "error";
          enqueueSnackbar("Something went wrong", { variant });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ minWidth: 275 }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paper sx={{ display: "flex", flexDirection: "column" }}>
              <div className="cpanel cpanel-red">
                <div className="icon-part">
                  <i
                    className="fa fa-stethoscope"
                    style={{ width: "53px" }}
                    aria-hidden="true"
                  ></i>
                  <br />
                  <small>Total Registered Therapists</small>
                  <p> {therapistCount}</p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ display: "flex", flexDirection: "column" }}>
              <div className="cpanel cpanel-green">
                <div className="icon-part">
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <br />
                  <small>Total Registered General Users</small>
                  <p> {userCount}</p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ display: "flex", flexDirection: "column" }}>
              <div className="cpanel cpanel-blue">
                <div className="icon-part">
                  <i className="fa fa-book" aria-hidden="true"></i>

                  <br />
                  <small>Total Active Courses</small>
                  <p> {coursesCount}</p>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: "20px",
          }}
          spacing={3}
        >
          <Grid item xs={3}>
            <Paper>
              <div className="cpanel cpanel-brown">
                <div className="icon-part">
                  <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                  <br />
                  <small>Total Therapists Appointments (Upcoming)</small>
                  <p>{therapistAppointmentCount} </p>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper sx={{ display: "flex", flexDirection: "column" }}>
              <div className="cpanel cpanel-orange">
                <div className="icon-part">
                  <i className="fa fa-calendar-check-o" aria-hidden="true"></i>

                  <br />
                  <small>Total General Users Appointments (Upcoming)</small>
                  <p> {userAppointmentCount}</p>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};
export default Dashboard;
