import Button from "@mui/material/Button";

function UploadVideoButton(props) {
  const { onChange, children } = props;

  const handleButtonClick = () => {
    // Click the hidden file input
    document.getElementById("video-input").click();
  };

  return (
    <>
      <Button variant="contained" onClick={handleButtonClick}>
        {children}
      </Button>
      <input
        id="video-input"
        type="file"
        accept="video/mov, video/mp4"
        style={{ display: "none" }}
        onChange={onChange}
      />
    </>
  );
}

export default UploadVideoButton;
