import "./App.css";
import React, { useState } from "react";
// import Navbar from "./layout/navigation/navigation";
// import Sidebar from "./layout/sidebar/sidebar";
// import Main from "./layout/main/main";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import User from "./components/user/user";
import Therapist from "./components/therapist/therapist";
import Course from "./components/course/course";
import HeahthAndWelness from "./components/health-and-wellness/health-and-wellness";
import Appointment from "./components/appointment/appointment.js";
import Banner from "./components/banner/banner";
import Availability from "./components/availability/availability";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
import Login from "./components/login/login";
import ForgotPassword from "./components/login/forgot-password";
import ResetPassword from "./components/login/reset-password";
import ViewUser from "./components/user/view-user/view-user";
import AddVideo from "./components/health-and-wellness/add-video/add-video";
import ChangePassword from "./layout/change-password/change-password";
import EditProfile from "./layout/edit-profile/edit-profile";
import ViewTherapist from "./components/therapist/view-therapist/view-therapist";
import EditTherapist from "./components/therapist/edit-therapist/edit-therapist";
import EditCourse from "./components/course/edit-course/edit-course";
import ViewCourse from "./components/course/view-course/view-course";
import AddCourse from "./components/course/add-course/add-course";
import EditBanner from "./components/banner/edit-banner/edit-banner";
import AddBanner from "./components/banner/add-banner/add-banner";
import UserContext from "./context/userContext";
import Other from "./components/other/other";
import AboutUs from "./components/about-us/about-us";
import NotFound from "./components/not-found/not-found";
import PrivateRoute from "./routes/private-routes";
import UserLayout from "./layout/user-layout/user-layout";
import AccountSetting from "./components/account-setting/account-setting";
import AddBankDetails from "./components/account-setting/bank-details/add-bank-details/addBankDetails";
// import Notificatons from "./components/Notification/notification";
import Notification from "./components/Notification/Notification";
import Event from "./components/Event/Event";
import AddEvent from "./components/Event/AddEvent";
import Meeting from "./components/meeting/Meeting.jsx";
function App() {
  const [user, setUser] = useState(localStorage.getItem("currentUser"));

  const handleEditProfile = (data) => {
    // Retrieve the object from local storage
    const item = localStorage.getItem("currentUser");

    // Parse the string data into a JavaScript object
    const parsedItem = JSON.parse(item);

    // Modify the specific field in the object
    parsedItem.user.profilePic = data.profilePic;
    parsedItem.user.firstName = data.firstName;
    parsedItem.user.lastName = data.lastName;

    // Convert the updated object back to a string
    const updatedItem = JSON.stringify(parsedItem);

    // Save the updated object back to local storage
    localStorage.setItem("currentUser", updatedItem);
    setUser(updatedItem);
  };

  return (
    <div className="App">
      <UserContext.Provider value={JSON.parse(user)}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/change-password"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/edit-profile"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <EditProfile handleEditProfile={handleEditProfile} />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/about-us"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AboutUs />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/user"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <User />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/user/:userId"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <ViewUser />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/therapist"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Therapist />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/therapist/:id/view"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <ViewTherapist />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/therapist/:id/edit"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <EditTherapist />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/course"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Course />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/course/add-course"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AddCourse />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/course/:id/view"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <ViewCourse />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/course/:id/edit"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <EditCourse />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/health-and-wellness"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <HeahthAndWelness />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/health-and-wellness/add-video"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AddVideo />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/appointment"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Appointment />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/event"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Event />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/event/add-event"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AddEvent />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/banner"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Banner />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/banner/add-banner"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AddBanner />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/banner/:id/edit"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <EditBanner />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/availability"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Availability />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <PrivacyPolicy />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="public/privacy-policy"
            element={
              
                    <PrivacyPolicy />
                 
            }
          />
          <Route
            exact
            path="/transaction"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AccountSetting />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/add-bank-details"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <AddBankDetails />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/other"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Other />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <PrivateRoute>
                    <Notification />
                  </PrivateRoute>
                </UserLayout>
              )
            }
          />
          <Route
            exact
            path="/login"
            element={user !== null ? <Navigate to="/" replace /> : <Login />}
          />
          <Route
            exact
            path="/forgot-password"
            element={
              user !== null ? <Navigate to="/" replace /> : <ForgotPassword />
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              user !== null ? <Navigate to="/" replace /> : <ResetPassword />
            }
          />
          <Route
            exact
            path="/meeting/:data"
            element={
              user === null ? (
                <Navigate to="/login" replace />
              ) : (
                <UserLayout>
                  <Meeting />
                </UserLayout>
              )
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

// function App() {
//   const [isOpen, setIsOpen] = useState(true);

//   const toggleSidebar = (isOpen) => {
//     setIsOpen(!isOpen);
//   };

//   const [user, setUser] = useState(localStorage.getItem("currentUser"));

//   const navigate = useNavigate();
//   const pathname = window.location.pathname;
//   useEffect(() => {
//     if (pathname !== "/reset-password") {
//       !JSON.parse(user) && navigate("/login");
//     }
//   }, []);

//   const handleEditProfile = (data) => {
//     // Retrieve the object from local storage
//     const item = localStorage.getItem("currentUser");

//     // Parse the string data into a JavaScript object
//     const parsedItem = JSON.parse(item);

//     // Modify the specific field in the object
//     parsedItem.user.profilePic = data.profilePic;
//     parsedItem.user.firstName = data.firstName;
//     parsedItem.user.lastName = data.lastName;

//     // Convert the updated object back to a string
//     const updatedItem = JSON.stringify(parsedItem);

//     // Save the updated object back to local storage
//     localStorage.setItem("currentUser", updatedItem);
//     setUser(updatedItem);
//   };

//   return (
//     <div className="App">
//       {JSON.parse(user) ? (
//         <>
//           <UserContext.Provider value={JSON.parse(user)}>
//             <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} />

//             <div className="wrapper">
//               <Sidebar isOpen={isOpen} />

//               <Main>
//                 <Routes>
//                   {/* <Route element={<ProtectedRoute />}> */}
//                   <Route exact path="/" element={<Dashboard />} />
//                   {/* </Route> */}
//                   <Route
//                     exact
//                     path="/change-password"
//                     element={<ChangePassword />}
//                   />
//                   <Route
//                     exact
//                     path="/edit-profile"
//                     element={
//                       <EditProfile handleEditProfile={handleEditProfile} />
//                     }
//                   />
//                   <Route exact path="/about-us" element={<AboutUs />} />
//                   <Route exact path="/user" element={<User />} />
//                   <Route
//                     exact
//                     path="/view-user/:userId"
//                     element={<ViewUser />}
//                   />
//                   <Route exact path="/therapist" element={<Therapist />} />
//                   <Route
//                     exact
//                     path="/view-therapist/:id"
//                     element={<ViewTherapist />}
//                   />
//                   <Route
//                     exact
//                     path="/edit-therapist/:id"
//                     element={<EditTherapist />}
//                   />
//                   <Route exact path="/course" element={<Course />} />
//                   <Route exact path="/add-course" element={<AddCourse />} />
//                   <Route
//                     exact
//                     path="/view-course/:id"
//                     element={<ViewCourse />}
//                   />
//                   <Route
//                     exact
//                     path="/edit-course/:id"
//                     element={<EditCourse />}
//                   />
//                   <Route
//                     exact
//                     path="/health-and-wellness"
//                     element={<HeahthAndWelness />}
//                   />
//                   <Route exact path="/add-video" element={<AddVideo />} />
//                   <Route exact path="/appointment" element={<Appointment />} />
//                   <Route exact path="/banner" element={<Banner />} />
//                   <Route exact path="/add-banner" element={<AddBanner />} />
//                   <Route
//                     exact
//                     path="/edit-banner/:id"
//                     element={<EditBanner />}
//                   />
//                   <Route
//                     exact
//                     path="/availability"
//                     element={<Availability />}
//                   />
//                   <Route
//                     exact
//                     path="/account-setting"
//                     element={<AccountSetting />}
//                   />
//                   <Route exact path="/other" element={<Other />} />
//                 </Routes>
//               </Main>
//             </div>
//           </UserContext.Provider>
//         </>
//       ) : (
//         <Routes>
//           <Route exact path="/login" element={<Login />} />
//           <Route exact path="/forgot-password" element={<ForgotPassword />} />
//           <Route exact path="/reset-password" element={<ResetPassword />} />
//         </Routes>
//       )}
//     </div>
//   );
// }

export default App;
