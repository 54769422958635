import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import UploadButton from "./uploadbutton";
import UploadVideoButton from "./video-upload-btn";

import { useState } from "react";

import { Constants } from "../../shared/_helpers";

function Other() {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  // const [progress, setProgress] = useState(0);

  const initialValues = {
    title: "",
    thumbnail: null,
    video: null,
  };

  const currentUser = localStorage.getItem("currentUser");
  const user = JSON.parse(currentUser);
  const token = user?.id;

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    thumbnail: Yup.mixed().required("Thumbnail is required"),
    video: Yup.mixed().required("Video is required"),
  });

  const uploadThumbnail = async (thumbnail) => {
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);

    const response = await fetch(
      `${Constants.BASE_URL}/api/Containers/program/upload?access_token=${token}`,
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }

    return data?.result?.files?.thumbnail[0]?.name; // Assuming your server returns the URL of the uploaded thumbnail
  };

  const uploadVideo = async (video) => {
    console.log(video);
    // Implement your thumbnail upload logic here
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("video", video);

    const response = axios.post(
      `${Constants.BASE_URL}/api/Containers/program/upload?access_token=${token}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          // const percentage = Math.round(
          //   (progressEvent.loaded * 100) / progressEvent.total
          // );
          // setProgress(percentage);
        },
      }
    );

    const data = await response;

    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }

    return data?.result?.files?.video[0]?.name; // Assuming your server returns the URL of the uploaded video
  };

  const handleSubmit = async ({ title, thumbnailUrl, videoUrl }) => {
    // Implement your save data logic here
    // For example, using the fetch API
    const response = await fetch(Constants.BASE_URL + `/api/programs`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title, thumbnail: thumbnailUrl, video: videoUrl }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Save failed");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values, handleSubmit, isSubmitting }) => (
        <Form>
          <Field name="title" label="Title" as={TextField} fullWidth />
          <ErrorMessage name="title" />
          <br></br>
          <br></br>
          <img src={thumbnailUrl} alt="thumbnail" />
          <Field name="thumbnail">
            {({ field, form }) => (
              <>
                <UploadButton
                  onChange={(event) => {
                    // Immediately upload the thumbnail and set the URL
                    const thumbnail = event.currentTarget.files[0];
                    form.setFieldValue("thumbnail", thumbnail);

                    uploadThumbnail(thumbnail)
                      .then((thumbnailUrl) => {
                        form.setFieldValue("thumbnailUrl", thumbnailUrl);
                        form.setFieldError("thumbnail", "");

                        setThumbnailUrl(
                          Constants.BASE_URL + "/program/" + thumbnailUrl
                        );
                      })
                      .catch((error) => {
                        console.error("Error uploading thumbnail:", error);
                      });
                  }}
                >
                  Upload Thumbnail
                </UploadButton>
                {form.touched.thumbnail && form.errors.thumbnail ? (
                  <div className="error">{form.errors.thumbnail}</div>
                ) : null}
              </>
            )}
          </Field>

          <Field name="video">
            {({ field, form }) => (
              <>
                <UploadVideoButton
                  onChange={(event) => {
                    // Immediately upload the thumbnail and set the URL
                    const video = event.currentTarget.files[0];
                    form.setFieldTouched("video", true, false);
                    form.setFieldValue("video", video);

                    uploadVideo(video)
                      .then((videoUrl) => {
                        form.setFieldValue("videoUrl", videoUrl);
                        form.setFieldError("video", "");
                      })
                      .catch((error) => {
                        console.error("Error uploading video:", error);
                      });
                  }}
                  onBlur={() => form.setFieldTouched("video")}
                >
                  Upload Video
                </UploadVideoButton>
                <br />
                {/* <div>{progress}% Uploaded</div> */}
                {form.touched.video && form.errors.video ? (
                  <div className="error">{form.errors.video}</div>
                ) : null}
              </>
            )}
          </Field>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default Other;
