import { BehaviorSubject } from "rxjs";
import { HandleResponse, Constants, AuthHeader } from "../_helpers";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(Constants.BASE_URL + `/api/users/login-admin`, requestOptions)
    .then(HandleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (user?.error?.statusCode === 400) {
        return user;
      } else {
        localStorage.setItem("currentUser", JSON.stringify(user));
        window.location.replace("/");
        currentUserSubject.next(user);
        return user;
      }
    });
}

function resetPassword(newPassword, token) {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ newPassword }),
  };
  return fetch(
    Constants.BASE_URL + `/api/users/reset-password?access_token=${token}`,
    requestOptions
  )
    .then(HandleResponse)

    .then((user) => {
      return user;
    });
}

function changePassword(oldPassword, newPassword) {
  const requestOptions = {
    method: "POST",
    headers: AuthHeader(),
    body: JSON.stringify({ oldPassword, newPassword }),
  };

  return fetch(
    Constants.BASE_URL + `/api/users/change-password`,
    requestOptions
  )
    .then(HandleResponse)
    .then((user) => {
      return user;
    });
}

function requestForgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  };

  return fetch(
    Constants.BASE_URL + `/api/users/forgot-password-admin`,
    requestOptions
  ).then(HandleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  window.location.replace("/login");
}

export const AuthenticationService = {
  login,
  logout,
  changePassword,
  requestForgotPassword,
  resetPassword,
  // getUserDetails,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
