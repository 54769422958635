import React, { useState } from "react";
import { UserService } from "../../../shared/_services";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { confirm } from "react-confirm-box";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";

import UploadButton from "./uploadbutton";

import "globalthis/auto";
import "core-js/actual";
import { Constants } from "../../../shared/_helpers";
import moment from "moment";

// import { set } from "core-js/core/dict";S
const currentUser = localStorage.getItem("currentUser");
const user = JSON.parse(currentUser);
const token = user?.id;
const actionUrlBanner = `${Constants.BASE_URL}/api/Containers/banner/upload?access_token=${token}`;

const AddBanner = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const Navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  //initialvalues
  const initialValues = {
    name: "",
    thumbnail: "",
    startDate: "",
    endDate: "",
  };

  //Upload Thumbnail
  const uploadThumbnail = async (thumbnail) => {
    // For example, using the fetch API
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);

    const response = await fetch(actionUrlBanner, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Upload failed");
    }

    return data?.result?.files?.thumbnail[0]?.name; // Assuming your server returns the URL of the uploaded thumbnail
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to the beginning of the day
  //validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required!")
      .max(20, "Name must not exceed 20 characters!"),
    thumbnail: Yup.mixed().required("Banner is required!"),
    startDate: Yup.date()
      .min(today, "Start date should not be in the past!")
      .required("Start date is required!")
      .test("valid-year", "Invalid year format!", (value) => {
        if (value) {
          const year = new Date(value).getFullYear();
          return /^\d{4}$/.test(year);
        }
        return true;
      }),
    endDate: Yup.date()
      .required("End date is required!")
      .typeError("End date is invalid!")
      .min(Yup.ref("startDate"), "End date must be after start date!")
      .test("valid-year", "Invalid year format!", (value) => {
        if (value) {
          const year = new Date(value).getFullYear();
          return /^\d{4}$/.test(year);
        }
        return true;
      }),
  });

  // Program data update button
  const onUpdateSubmit = async (values) => {
    if (isUploading) {
      let variant = "warning";
      enqueueSnackbar("File is uploading. Please wait!", { variant });
      // options = false;
    }

    const result = await confirm("Do you want to add new Banner?", options);

    if (result) {
      let data = {
        name: values.name,
        thumbnail: values.thumbnailUrl,
        startDate: values.startDate,
        endDate: values.endDate,
      };

      UserService.addBanner(data, enqueueSnackbar).then((response) => {
        if (!response.error) {
          let variant = "success";
          enqueueSnackbar("Banner added successfully!", { variant });
          Navigate("/banner");
        }
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            id="tablename"
            component="div"
            className="header-text"
          >
            Add Banner
          </Typography>
        </Toolbar>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onUpdateSubmit}
        >
          {({ errors, values, touched }) => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                }}
                style={{ alignSelf: "center" }}
              ></Box>
              <Box>
                <Field name="thumbnail">
                  {({ field, form }) => (
                    <>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "200px",
                          border: "1px solid gray",
                          width: "76%",
                          marginLeft: "20px",
                          borderRadius: "4px",
                        }}
                      >
                        <Grid
                          item
                          md={6}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "80%",
                              justifyContent: "center",
                              margin: "auto",
                              height: "100px",
                            }}
                          >
                            <img
                              src={thumbnailUrl}
                              width="80"
                              alt="img"
                              style={{ borderRadius: "8px" }}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <UploadButton
                            onChange={(event) => {
                              // Immediately upload the thumbnail and set the URL
                              const thumbnail = event.currentTarget.files[0];
                              setIsUploading(true);
                              form.setFieldValue("thumbnail", thumbnail);

                              uploadThumbnail(thumbnail)
                                .then((thumbnailUrl) => {
                                  form.setFieldValue(
                                    "thumbnailUrl",
                                    thumbnailUrl
                                  );
                                  form.setFieldError("thumbnail", "");

                                  setThumbnailUrl(
                                    Constants.BASE_URL +
                                      "/banner/" +
                                      thumbnailUrl
                                  );
                                  setIsUploading(false);
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error uploading thumbnail:",
                                    error
                                  );
                                });
                            }}
                          >
                            Upload Banner
                          </UploadButton>
                        </Grid>
                      </Grid>
                      {form.touched.thumbnail && form.errors.thumbnail ? (
                        <div
                          className="error"
                          style={{ textAlign: "left", marginLeft: "20px" }}
                        >
                          {form.errors.thumbnail}
                        </div>
                      ) : null}
                    </>
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                  // margin: "0 auto",
                }}
              >
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      label="Name"
                      fullWidth
                      size="small"
                      display="flex"
                      {...(field ?? "")}
                      style={{ margin: "20px" }}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? `${errors.name}` : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  width: "80%",
                  // margin: "0 auto",
                }}
                style={{ alignSelf: "center" }}
              >
                <Field name="startDate">
                  {({ field }) => (
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ min: moment().format("YYYY-MM-DD") }}
                      label="Start date"
                      variant="outlined"
                      {...(field ?? "")}
                      size="small"
                      style={{
                        margin: "20px",
                        width: "50%",
                      }}
                      error={
                        errors.startDate && touched.startDate ? true : false
                      }
                      helperText={
                        errors.startDate && touched.startDate
                          ? `${errors.startDate}`
                          : ""
                      }
                    />
                  )}
                </Field>
                <Field name="endDate">
                  {({ field }) => (
                    <TextField
                      type="date"
                      label="End date"
                      variant="outlined"
                      inputProps={{ min: moment().format("YYYY-MM-DD") }}
                      {...(field ?? "")}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{
                        margin: "20px",
                        width: "50%",
                      }}
                      error={errors.endDate && touched.endDate ? true : false}
                      helperText={
                        errors.endDate && touched.endDate
                          ? `${errors.endDate}`
                          : ""
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                }}
                style={{ alignSelf: "center" }}
              ></Box>
              <Button
                style={{
                  width: 100,
                  marginLeft: "25px",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
                variant="contained"
                size="medium"
                type="submit"
                className="submit-btn-text"
              >
                Submit
              </Button>
              <Link to="/banner">
                <Button
                  style={{
                    width: 100,
                    marginLeft: "25px",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                  variant="outlined"
                  size="medium"
                  type="submit"
                  className="back-btn-text"
                >
                  cancel
                </Button>
              </Link>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};
export default AddBanner;
