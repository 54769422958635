import React from "react";
import { visuallyHidden } from "@mui/utils";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const TherapistTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "Name",
      headerKey: "firstName",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      headerKey: "email",
    },
    {
      id: "e164Number",
      numeric: false,
      disablePadding: false,
      label: "Phone No.",
      headerKey: "e164Number",
    },

    {
      id: "experience",
      numeric: false,
      disablePadding: false,
      label: "Experience (Years)",
      headerKey: "experience",
    },
    // {
    //   id: "consultation_fee",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Consultation Fee ($)",
    //   headerKey: "consultation_fee",
    // },
    {
      id: "location",
      numeric: false,
      disablePadding: false,
      label: "Location",
      headerKey: "location",
    },
    {
      id: "language",
      numeric: false,
      disablePadding: false,
      label: "Language",
      headerKey: "language",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Registration Date",
      headerKey: "createdAt",
    },
    {
      id: "lastActive",
      numeric: false,
      disablePadding: false,
      label: "Last Active",
      headerKey: "lastActive",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headerKey: "status",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Action",
      headerKey: "action",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold",
              paddingLeft: "15px",
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.headerKey}
              direction={orderBy === headCell.headerKey ? order : "asc"}
              className={
                headCell.id === "e164Number" || headCell.id === "action"
                  ? "hide-sort-icon"
                  : ""
              }
              // className={headCell.id === "e164Number" ? "hide-sort-icon" : ""}
              onClick={createSortHandler(headCell.headerKey)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TherapistTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
export default TherapistTableHead;
