import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { AuthenticationService } from "../../shared/_services";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import "./change-password.css";
const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    /* eslint-disable-next-line */
    oldPassword: Yup.string()
      .required("Current password is required!")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
    /* eslint-disable-next-line */
    newPassword: Yup.string()
      .required("New password is required!")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
    /* eslint-disable-next-line */
    confirmPassword: Yup.string()
      .required("Confirm password is required!")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
  });
  const onChangePassword = (e) => {
    if (e.oldPassword === e.newPassword) {
      let variant = "error";
      enqueueSnackbar("Old password and new password can not be same.", {
        variant,
      });
      return;
    } else if (e.newPassword === e.confirmPassword) {
      setIsOpenBtn(true);
      AuthenticationService.changePassword(e.oldPassword, e.newPassword).then(
        (user) => {
          if (user.error) {
            let variant = "error";
            enqueueSnackbar(user.error.message, { variant });
            setIsOpenBtn(false);
            return;
          }
          let variant = "success";
          enqueueSnackbar("Password changed successfully!", { variant });
          setIsOpenBtn(false);
        }
      );
    } else {
      let variant = "error";
      enqueueSnackbar("New password and Confirm password are not same!", {
        variant,
      });
      setIsOpenBtn(false);
      return;
    }
  };

  const onBackClick = () => {
    navigate("/");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 20%", textAlign: "initial" }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Change Password
          </Typography>
          <Button
            variant="outlined"
            onClick={onBackClick}
            className="back-btn-text"
          >
            Back
          </Button>
        </Toolbar>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            onChangePassword(values);
            resetForm();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Box
                sx={{
                  "& > :not(style)": {},
                  padding: "10px 0",
                }}
                className="change-password-form"
              >
                <Field name="oldPassword">
                  {({ field }) => (
                    <TextField
                      className="mar-20"
                      inputProps={{
                        autoComplete: "oldPassword",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      label="Current password"
                      fullWidth
                      size="small"
                      type="password"
                      display="flex"
                      {...field}
                      error={
                        errors.oldPassword && touched.oldPassword ? true : false
                      }
                      helperText={
                        errors.oldPassword && touched.oldPassword
                          ? `${errors.oldPassword}`
                          : ""
                      }
                    />
                  )}
                </Field>
                <Field name="newPassword">
                  {({ field }) => (
                    <TextField
                      className="mar-20"
                      inputProps={{
                        autoComplete: "newPassword",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      label="New password"
                      type="password"
                      size="small"
                      fullWidth
                      display="flex"
                      {...field}
                      error={
                        errors.newPassword && touched.newPassword ? true : false
                      }
                      helperText={
                        errors.newPassword && touched.newPassword
                          ? `${errors.newPassword}`
                          : ""
                      }
                    />
                  )}
                </Field>

                <Field name="confirmPassword">
                  {({ field }) => (
                    <TextField
                      className="mar-20"
                      inputProps={{
                        autoComplete: "confirmPassword",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                      label="Confirm new password"
                      fullWidth
                      size="small"
                      display="flex"
                      type="password"
                      {...field}
                      error={
                        errors.confirmPassword && touched.confirmPassword
                          ? true
                          : false
                      }
                      helperText={
                        errors.confirmPassword && touched.confirmPassword
                          ? `${errors.confirmPassword}`
                          : ""
                      }
                    />
                  )}
                </Field>
                <Button
                  className="mar-20 submit-btn-text"
                  variant="contained"
                  type="submit"
                  disabled={isOpenBtn}
                >
                  Change password
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ChangePassword;
