import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../../shared/_services";
import { useSnackbar } from "notistack";
import { Link, useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";
import Spinner from "../../spinner-loader/spinner-loader.js";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Constants } from "../../../shared/_helpers";
import "./view-therapist.css";
import "globalthis/auto";
import "core-js/actual";

const ViewTherapist = () => {
  const [profilephoto, setProfilephoto] = useState();
  const [user, setUser] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const style = {
    fontSize: 14,
    fontWeight: "700",
  };
  const fontStyle = {
    fontSize: 14,
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    UserService.getTherapistDetails(id, enqueueSnackbar).then((response) => {
      setUser(response.user[0]);

      setProfilephoto(
        response.user[0].picture
          ? `${Constants.BASE_URL}/user/${response.user[0].picture}`
          : `${Constants.BASE_URL}/user/${"default.png"}`
      );
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // SEARCH CODES

  function toTitleCase(str) {
    if (str) {
      str = str.toString();
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Therapist Profile
          </Typography>
          <Link to="/therapist">
            <Button variant="outlined" className="back-btn-text">
              Back
            </Button>
          </Link>
        </Toolbar>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

        <TableContainer>
          {loading ? (
            <Spinner />
          ) : (
            <Grid container style={{ marginRight: "20px" }}>
              <Grid xs={12} md={5} lg={5} style={{ margin: "20px" }}>
                <Card>
                  <CardContent style={{ paddingBottom: "0px" }}>
                    <div className="view-img">
                      <img
                        src={profilephoto}
                        alt="profilepic"
                        style={{
                          objectFit: "contain",
                          textAlign: "center",
                          height: "100px",
                          width: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      height={10}
                      component="div"
                      align="center"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        height: "auto",
                        fontWeight: "bold",
                      }}
                    ></Typography>
                    <Table ria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Name
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {toTitleCase(
                              user?.lastName === undefined ||
                                user?.lastName === ""
                                ? user.firstName + ""
                                : user.firstName + " " + user.lastName
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Email
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.email || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Phone No.
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.mobile?.e164Number || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Location
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.location.city === undefined ||
                            user?.location.city === " "
                              ? "N/A"
                              : toTitleCase(user?.location.city) +
                                ", " +
                                toTitleCase(user?.location.country)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Bio
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            <Typography variant="body2" component="div">
                              {user?.bio === undefined || user?.bio === ""
                                ? "N/A"
                                : user?.bio}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Status
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                flexWrap: "wrap",
                                "& > :not(style)": {
                                  width: 60,
                                  height: 20,
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {user.status === 1 ? (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "green",
                                      padding: "6px 15px",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Active
                                  </span>
                                ) : user.status === 3 ? (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "orange",
                                      padding: "6px 15px",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Pending
                                  </span>
                                ) : (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "red",
                                      padding: "6px ",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Suspended
                                  </span>
                                )}
                              </div>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
              {/* <Grid lg={1}></Grid> */}
              <Grid xs={12} md={6} lg={6} style={{ margin: "20px" }}>
                <Card>
                  <CardContent style={{ paddingBottom: "0px" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      height={10}
                      component="div"
                      align="center"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        height: "auto",
                        fontWeight: "bold",
                      }}
                    ></Typography>
                    <Table ria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            License Type
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.license.type === undefined ||
                            user?.license.type === " "
                              ? "N/A"
                              : user?.license.type}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            License Category
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.license.category === undefined ||
                            user?.license.category === " "
                              ? "N/A"
                              : user?.license.category}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            License Number
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.license?.number === undefined ||
                            user?.license?.number.trim() === ""
                              ? "N/A"
                              : user?.license?.number}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" style={style}>
                            Provide Services
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.services && user?.services.length > 0
                              ? user.services
                                  .map((service) => service.name)
                                  .join(", ")
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Experience(Years)
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.experience === undefined ||
                            user?.experience === null
                              ? "N/A"
                              : user?.experience}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Consultation Fee($)
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.consultation_fee === undefined ||
                            user?.consultation_fee === null ? (
                              "N/A"
                            ) : (
                              <>
                                <span>1 Hour</span>:{" "}
                                <strong>{user?.consultation_fee.full}</strong>
                                &nbsp;&nbsp;&nbsp;
                                <span>1/2 Hour</span>:{" "}
                                <strong>{user?.consultation_fee.half}</strong>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Registration Date
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {moment(user.createdAt).format("MM-DD-YYYY")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Last Active On
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.lastActive
                              ? moment(user?.lastActive).format(
                                  "MM-DD-YYYY HH:mm"
                                )
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Education
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user.education.length === 0 ? (
                              "N/A"
                            ) : (
                              <ol class="lists">
                                {user.education.map((educationItem, index) => (
                                  <li
                                    key={index}
                                  >{` ${educationItem.degree} from ${educationItem.college}`}</li>
                                ))}
                              </ol>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Language
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.languages?.length === 0 ? (
                              "N/A"
                            ) : (
                              <ol class="lists">
                                {user?.languages.map((language, index) => (
                                  <li key={index}>{` ${language?.name}`}</li>
                                ))}
                              </ol>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Professional Membership
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            {user?.professional_membership?.length === 0 ? (
                              "N/A"
                            ) : (
                              <ol class="lists">
                                {user?.professional_membership.map(
                                  (membership, index) => (
                                    <li
                                      key={index}
                                    >{`${membership?.membership}`}</li>
                                  )
                                )}
                              </ol>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={style}>
                            Certification And Degree
                          </TableCell>
                          <TableCell align="left" style={fontStyle}>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {user?.certifications.map(
                                (certification, index) => (
                                  <div key={index}>
                                    <img
                                      style={{ width: "50%" }}
                                      src={`${Constants.BASE_URL}/certificate/${certification.certificate}`}
                                      alt={certification?.certificate}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default ViewTherapist;
