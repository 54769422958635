import * as React from "react";
import { useState, useEffect } from "react";
import { UserService } from "../../shared/_services";
import { useSnackbar } from "notistack";

import { Link } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import Spinner from "../spinner-loader/spinner-loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TherapistTableHead from "./table-head/therapist-table-head/therapist-table-head";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "globalthis/auto";
import "core-js/actual";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function resolveStatus(ele) {
  if (ele.status === 1) return "Active";
  else if (ele.status === 3) return "Pending";
  else return "Suspended";
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === "status") {
    if (resolveStatus(a) > resolveStatus(b)) return -1;
    else return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (orderBy === "name") {
    a = a.firstName.toLowerCase();
    b = b.firstName.toLowerCase();
    return a.localeCompare(b) * 1;
  }

  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]); //-1

    return order !== 0 ? order : null;
    // return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const initialValue = {
  from: "",
  to: "",
};

const Therapist = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [therapists, setTherapist] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [unBlocktost, setunBlocktost] = useState(false);
  const [deleteTost, setDeleteTost] = useState(false);
  const [blocktost, setBlocktost] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [currentUID, setCurrentUID] = useState("");
  const [dateRange] = useState(initialValue);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl2);

  const handleDropDownClick = (event, userId) => {
    setCurrentUID(userId);
    return setAnchorEl2(event.currentTarget);
  };

  const handleDropDownClose = () => {
    setAnchorEl2(null);
  };
  const style = {
    color: "#000",
    cursor: "pointer",
  };

  const titleStyle = {
    marginTop: "6px",
    color: "#000",
  };

  useEffect(() => {
    fetchData("paginationChange");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const unblockToast = () => {
    setunBlocktost(true);
  };

  const blockToast = () => {
    setBlocktost(true);
  };

  const deleteToast = () => {
    setDeleteTost(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setBlocktost(false);
    setunBlocktost(false);
    setDeleteTost(false);
  };

  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };
  //delete therapist
  const onDeleteTherapist = async (userId, enqueueSnackbar) => {
    const result = await confirm(
      "Do you want to Delete this therapist?",
      options
    );
    if (result) {
      UserService.deleteTherapist(userId, enqueueSnackbar).then((response) => {
        fetchData("paginationChange");
        deleteToast(true);
      });
    }
  };
  //block therapist
  const block = async (userId, page, rowsPerPage) => {
    const result = await confirm(
      "Do you want to Suspend this therapist?",
      options
    );
    if (result) {
      UserService.blockUser(userId, page, rowsPerPage).then((res) => {
        if (res.status === true) {
          fetchData("paginationChange");
          unblockToast(true);
          return;
        }
      });
    }
  };
  //unblock therapist
  const unblock = async (userId, page, rowsPerPage) => {
    const result = await confirm(
      "Do you want to Activate this therapist?",
      options
    );
    if (result) {
      UserService.unBlockUser(userId, page, rowsPerPage, enqueueSnackbar).then(
        (res) => {
          fetchData("paginationChange");
          blockToast(true);
          return;
        }
      );
    }
  };

  const fetchData = (param) => {
    let searchData;
    if (param === "cancel") searchData = "";
    else searchData = search;
    let data = {
      page: page + 1,
      limit: rowsPerPage,
      search: searchData,
      start: dateRange.from,
      end: dateRange.to,
    };
    UserService.getAllTherapist(data, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setTherapist(response.users[0].data);

        if (response.users[0].metadata.length > 0) {
          setTotal(response.users[0].metadata[0].total);
        } else {
          setTotal(0);
        }
        setLoading(false);
      } else if (response.error.statusCode === 400) {
        let variant = "error";
        enqueueSnackbar("Something went wrong", { variant });
      }
    });
    if (param !== "paginationChange") {
      setPage(0);
    }
  };

  // SEARCH CODES
  const handleSearch = (event) => {
    fetchData();
    event.preventDefault();
  };

  const handleCancel = (event) => {
    setSearch("");
    fetchData("cancel");
    event.preventDefault();
    setIsSearchShown(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = therapists.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setIsSearchShown(false);
    } else {
      setIsSearchShown(true);
    }
    setSearch(e.target.value);
  };
  function toTitleCase(str) {
    str = str.toString();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar className="table_name">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            className="header-text"
          >
            Therapists
          </Typography>

          <Typography sx={{ flex: "1 1 5%" }} component="div">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
                float: "right",
                display: "flex",
              }}
              noValidate
              autoComplete="off"
            >
              <Paper
                component="div"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  width: 250,
                  alignItems: "center",
                }}
              >
                <InputBase
                  value={search}
                  onInput={onInputChange}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
                {isSearchShown && (
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      onClick={handleCancel}
                    >
                      <ClearIcon />
                    </IconButton>
                  </>
                )}
              </Paper>
            </Box>
          </Typography>
        </Toolbar>
        <Divider orientation="horizontal" />
        <TableContainer>
          {loading ? (
            <Spinner />
          ) : (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TherapistTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={therapists.length}
                key={therapists._id}
              />

              {therapists.length === 0 ? (
                <TableBody>
                  <TableRow style={{ textAlign: "center" }}>
                    <TableCell colSpan={7}>
                      <div style={{ textAlign: "center", fontWeight: "600" }}>
                        Data not found
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                stableSort(therapists, getComparator(order, orderBy)).map(
                  (therapist, index) => {
                    const isItemSelected = isSelected(therapist._id);

                    return (
                      <TableBody>
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={therapist._id}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <div style={{ display: "flex", minWidth: "200px" }}>
                              {toTitleCase(
                                therapist?.lastName === undefined ||
                                  therapist?.lastName === ""
                                  ? therapist.firstName + " "
                                  : therapist.firstName +
                                      " " +
                                      therapist.lastName
                              )}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div style={{ display: "flex" }}>
                              {therapist.email}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div style={{ display: "flex" }}>
                              {therapist?.mobile?.e164Number === undefined
                                ? "N/A"
                                : therapist?.mobile?.e164Number}
                            </div>
                          </TableCell>

                          <TableCell>
                            {therapist?.experience === undefined ||
                            therapist?.experience === null
                              ? "N/A"
                              : therapist?.experience}
                          </TableCell>
                          {/* <TableCell>
                            {therapist?.consultation_fee === undefined ||
                            therapist?.consultation_fee === null
                              ? "N/A"
                              : therapist?.consultation_fee.full}
                          </TableCell> */}
                          <TableCell>
                            {therapist?.location.city === undefined ||
                            therapist?.location.city === " "
                              ? "N/A"
                              : therapist?.location.city +
                                ", " +
                                therapist?.location.country}
                          </TableCell>
                          <TableCell>
                            {therapist?.languages?.length === 0
                              ? "N/A"
                              : therapist?.languages?.map((language, index) => (
                                  <span key={index} style={{ width: "100px" }}>
                                    {" "}
                                    {index < 1
                                      ? language?.name
                                      : index === 1
                                      ? `... `
                                      : ""}
                                    {index !== therapist.languages.length - 1 &&
                                    index < 2
                                      ? " "
                                      : ""}
                                  </span>
                                ))}
                          </TableCell>
                          <TableCell>
                            {moment(therapist?.createdAt).format("MM-DD-YYYY")}
                          </TableCell>
                          <TableCell>
                            {therapist?.lastActive
                              ? moment(therapist?.lastActive).format(
                                  "MM-DD-YYYY HH:mm"
                                )
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                flexWrap: "wrap",
                                "& > :not(style)": {
                                  width: 80,
                                  height: 20,
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {therapist.status === 1 ? (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "green",
                                      padding: "6px 15px",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Active
                                  </span>
                                ) : therapist.status === 3 ? (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "orange",
                                      padding: "6px 15px",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Pending
                                  </span>
                                ) : (
                                  <span
                                    className="user_status_icon"
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      background: "red",
                                      padding: "6px ",
                                      display: "inline-block",
                                      width: "90px",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    Suspended
                                  </span>
                                )}
                              </div>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ verticalAlign: "middle" }}
                          >
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleDropDownClick(event, therapist._id)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={
                                therapist._id === currentUID && anchorEl2
                              }
                              open={therapist._id === currentUID && open}
                              onClose={handleDropDownClose}
                            >
                              <Link
                                as={Link}
                                to={`/therapist/${therapist?._id}/view`}
                              >
                                <MenuItem
                                  onClick={handleDropDownClose}
                                  disableRipple
                                >
                                  <VisibilityIcon style={style} />
                                  <h5 style={titleStyle}>View </h5>
                                </MenuItem>
                              </Link>
                              <Divider sx={{ my: 0.5 }} />
                              <Link
                                as={Link}
                                to={`/therapist/${therapist._id}/edit`}
                              >
                                <MenuItem
                                  onClick={handleDropDownClose}
                                  disableRipple
                                >
                                  <EditIcon style={style} />
                                  <h5 style={titleStyle}>Edit</h5>
                                </MenuItem>
                              </Link>

                              <Divider sx={{ my: 0.5 }} />
                              {therapist.status === 1 ? (
                                <div onClick={(e) => block(therapist._id)}>
                                  <MenuItem
                                    onClick={handleDropDownClose}
                                    disableRipple
                                  >
                                    <BlockIcon style={{ color: "red" }} />
                                    <h5 style={titleStyle}> Suspend</h5>
                                  </MenuItem>
                                </div>
                              ) : therapist.status === 3 ? (
                                <div onClick={(e) => unblock(therapist._id)}>
                                  <MenuItem
                                    onClick={handleDropDownClose}
                                    disableRipple
                                  >
                                    <LockOpenIcon style={{ color: "green" }} />
                                    <h5 style={titleStyle}>Active</h5>
                                  </MenuItem>
                                </div>
                              ) : (
                                <div onClick={(e) => unblock(therapist._id)}>
                                  <MenuItem
                                    onClick={handleDropDownClose}
                                    disableRipple
                                  >
                                    <LockOpenIcon style={{ color: "green" }} />
                                    <h5 style={titleStyle}>Active</h5>
                                  </MenuItem>
                                </div>
                              )}
                              <Divider sx={{ my: 0.5 }} />
                              <div
                                onClick={(e) =>
                                  onDeleteTherapist(therapist._id)
                                }
                              >
                                <MenuItem
                                  onClick={handleDropDownClose}
                                  disableRipple
                                  style={{ color: "red" }}
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                  <h5 style={titleStyle}>Delete</h5>
                                </MenuItem>
                              </div>
                            </StyledMenu>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  }
                )
              )}
            </Table>
          )}
        </TableContainer>
        <TablePagination
          className="p"
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={unBlocktost}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Therapist suspended successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={blocktost} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Therapist activated successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={deleteTost} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Therapist deleted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default Therapist;
