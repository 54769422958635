import React from "react";
import TransactionTable from "./transaction/transaction";
const AccountSetting = () => {
  return (
    <div>
      <TransactionTable />
      {/* Activate when bank details needed*/}
      {/* <BankDetails /> */}
    </div>
  );
};

export default AccountSetting;
